import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { USER_CABINET_URLS, RETAILER_URLS } from '@/constants/urls';

import { useGlobalContext } from '@/contexts/GlobalContext';

import useLauncherSetup from '@/utils/setupLauncher';
import DimensionToggle from '@/utils/dimensionToggle';
import { useIs3D } from '@/utils/Is3DContext';

const RouteWatcher: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const { is3D, setIs3D } = useIs3D();
  const [{ launcher3dRenderer, launcher3d }, { setLauncher3dRenderer }] =
    useGlobalContext();

  useLauncherSetup();

  useEffect(() => {
    const path = location.pathname;

    const loadMiniMap = () => {
      if (!document.getElementById('mini-map-script')) {
        const scriptMiniMap = document.createElement('script');
        scriptMiniMap.id = 'mini-map-script';
        scriptMiniMap.type = 'module';
        scriptMiniMap.src = '/modules/mini-map/index.js';
        document.body.appendChild(scriptMiniMap);
      }
    };

    const loadPavilionBuilder = () => {
      if (!document.getElementById('pavilion-builder-script')) {
        const mainScript = document.createElement('script');
        mainScript.id = 'pavilion-builder-script';
        mainScript.type = 'module';
        mainScript.src = '/modules/pavilion-builder/index.js';
        document.body.appendChild(mainScript);
      }
    };

    const handleProfileRoute = () => {
      // Деактивация метода
      if (launcher3d && launcher3dRenderer) {
        launcher3d.Renderer.stop();
        setLauncher3dRenderer(false);
      }
      loadMiniMap();
      loadPavilionBuilder();
    };

    const handleApplicationRoute = () => {
      if (is3D) {
        setIs3D(false);
        DimensionToggle({ is3D: true });
      }

      // Восстановление рендера
      if (launcher3d && !launcher3dRenderer) {
        launcher3d.Renderer.render();
        setLauncher3dRenderer(true);
      }
      loadMiniMap();
      if (!document.getElementById('main-script')) {
        const scriptMain = document.createElement('script');
        scriptMain.id = 'main-script';
        scriptMain.type = 'module';
        scriptMain.src = '/main.js';
        document.body.appendChild(scriptMain);
      }
    };

    // Проверяем, относится ли текущий путь к Акаунту
    const isAccountUrl = [
      ...Object.values(USER_CABINET_URLS),
      ...Object.values(RETAILER_URLS),
    ].some((route) => path.startsWith(route.replace(/:.*$/, '')));

    if (isAccountUrl) {
      handleProfileRoute();
    } else {
      handleApplicationRoute();
    }
  }, [location]);

  return <>{children}</>;
};

export default RouteWatcher;
