import { Field as FinalFormField } from 'react-final-form';
import Select from 'react-select';

import type { SelectFieldProps } from '@/types/forms';

const FormSelectField = ({
  showErrorInline = true,
  isDisabled = false,
  onChangeCallback,
  name,
  fieldProps,
  ...props
}: SelectFieldProps) => {
  return (
    <FinalFormField
      {...fieldProps}
      name={name}
      render={({ input, meta }) => {
        const handleChange = (selectedOption: any) => {
          onChangeCallback && onChangeCallback(selectedOption.id);
          input.onChange(selectedOption);
        };

        const classes = {
          options: (base: string[]) => ({
            ...base,
            borderRadius: 8,
            padding: 0,
          }),
          control: (base: string[]) => ({
            ...base,
            border: 0,
            height: 41,
            boxShadow: 'none',
            background: '#e5e5e5',
            borderRadius: 8,
          }),
          option: (base: string[], state: any) => ({
            ...base,
            border: 0,
            background:
              state.isFocused || state.isSelected
                ? 'linear-gradient(180deg, rgba(75, 75, 75, 0.08), rgba(75, 75, 75, 0.06))'
                : 'linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2))',
            height: 38,
            color: state.isSelected ? '#000000' : '#333333',
            boxShadow: '1px 1px 1px rgba(75, 75, 75, 0.09)',
            // "&:selected": {
            //   color: "#000000",
            //   backgroundColor: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2))',
            // },
            '&:hover': {
              color: '#000000',
              backgroundColor:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2))',
            },
          }),
        };

        return (
          <>
            <Select
              className={`react-select-wrapper ${meta.error || meta.submitError ? 'input-wrapper__error' : ''}`}
              classNamePrefix="react-select"
              {...input}
              {...props}
              styles={classes}
              noOptionsMessage={() => ''}
              renderValue={(value: string | null) => {
                return value || '';
              }}
              isDisabled={isDisabled}
              onChange={handleChange}
            />

            {showErrorInline &&
              (meta.error || meta.submitError) &&
              meta.touched && (
                <div className="error-message__form">
                  {meta.error || meta.submitError}
                </div>
              )}
          </>
        );
      }}
    />
  );
};

export default FormSelectField;
