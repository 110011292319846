import React from 'react';

import arrowIcon from '@/assets/icons/catalogue/arrow_right.svg';
import { CategoriesListItem } from '../types';

type CatalogueSubCategoryItemProps = Pick<
  CategoriesListItem,
  'title' | 'isSelected' | 'onClick'
>;

export const CatalogueSubCategoryItem: React.FC<
  CatalogueSubCategoryItemProps
> = ({ title, isSelected, onClick }) => {
  return (
    <div
      className={`catalogue-sub-category-item ${
        isSelected ? 'list-item-selected' : 'list-item'
      }`}
      onClick={onClick}
    >
      <div className="catalogue-sub-category-item__left">
        <span className="catalogue-category-item__title">{title}</span>
      </div>
      <div className="catalogue-sub-category-item__arrow list-item-icon">
        <img src={arrowIcon} alt="" />
      </div>
    </div>
  );
};
