import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import Modal from '@/layouts/Modal/Modal';

import FilterTabs from '@/components/Account/HeaderFilter/FilterTabs';
import HeaderFilter from '@/components/Account/HeaderFilter/HeaderFilter';
import HeaderFilterSearch from '@/components/Account/HeaderFilter/HeaderFilterSearch';
import Button from '@/components/ui/Button/Button';
import AccountSidebar from '@/components/Account/AccountSidebar/AccountSidebar';
import ClustersListCards from '@/features/AccountRetailer/components/ClustersListCards';

import useClustersManagement from '@/features/AccountRetailer/hooks/useClustersManagement';

import { accountMenuItems } from '@/data/constants';
import { ShowNameAction } from '@/features/AccountRetailer/constants';

import ClusterForm from '@/features/AccountRetailer/components/ClusterForm';

import '../retailer-products-management.sass';

import Plus from '@/assets/icons/plus.svg';
import Checkmark from '@/assets/icons/checkmark--outline__black.svg';

const AccountRetailerClustersManagement: React.FC = () => {
  const navigate = useNavigate();
  const { clusterId } = useParams();

  const [
    {
      onSubmitLoading,
      isLoading,
      showNameActionComponent,
      showUploadFile,
      showContextMenu3D,
    },
    {
      notification,
      selectCategory,
      selectCategory2,
      fileInputRef,
      initialValues,
      clusters,
      categoriesOptions,
      subCategoriesData2,
      subCategoriesData3,
      characteristicFields,
      productModelRef,
    },
    {
      resetForm,
      setProductModalFile,
      setNotification,
      setSelectCategory,
      setSelectCategory2,
      setShowUploadFile,
      setShowNameActionComponent,
      setShowContextMenu3D,
      handleFileInputClick,
      handleFileChange,
      handleDrop,
      handleDragOver,
      addCharacteristicField,
      removeCharacteristicField,
      onSubmit,
      handleDeleteItem,
    },
  ] = useClustersManagement(Number(clusterId));
  return (
    <div className="container account-container">
      <AccountSidebar menuItems={accountMenuItems} />

      {notification && (
        <>
          <Modal
            theme="secondary"
            onClose={() => {
              setNotification(null);
            }}
            overlay
          >
            {notification.type === 'error' && (
              <div
                className={cn(
                  'notification-window notification-window__white error',
                )}
              >
                <span className={cn('error-img__custom')}></span>
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
              </div>
            )}

            {notification.type === 'success' && (
              <div
                className={cn(
                  'notification-window notification-window__white error',
                )}
              >
                <img src={Checkmark} alt="Success" />
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
                <Button
                  onClick={() => {
                    navigate('/account/clusters');
                    setNotification(null);
                    setShowNameActionComponent(ShowNameAction.View);
                  }}
                  size="m"
                  secondary
                >
                  Хорошо
                </Button>
              </div>
            )}

            {notification.type === 'remove' && (
              <>
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
                <div className={cn('group-buttons__modal')}>
                  <Button
                    onClick={() => {
                      navigate('/account/clusters');
                      setNotification(null);
                      setShowNameActionComponent(ShowNameAction.View);
                      handleDeleteItem(Number(clusterId));
                      resetForm();
                    }}
                    size="m"
                    secondary
                  >
                    Да
                  </Button>
                  <Button
                    onClick={() => setNotification(null)}
                    size="m"
                    primary
                  >
                    Нет
                  </Button>
                </div>
              </>
            )}

            {notification.type === 'other' && (
              <>
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
                <div className={cn('group-buttons__modal')}>
                  <Button
                    onClick={() => {
                      navigate('/account/clusters');
                      setNotification(null);
                      setShowNameActionComponent(ShowNameAction.View);
                      resetForm();
                    }}
                    size="m"
                    secondary
                  >
                    Да
                  </Button>
                  <Button
                    onClick={() => setNotification(null)}
                    size="m"
                    primary
                  >
                    Нет
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </>
      )}

      <div
        className={cn('account-container__card-list account-content', {
          'product-3d-manager-container':
            ShowNameAction.Edit === showNameActionComponent ||
            ShowNameAction.New === showNameActionComponent,
        })}
      >
        {showNameActionComponent === ShowNameAction.View && (
          <>
            <HeaderFilter>
              <div className="header-filter__content">
                <FilterTabs
                  list={['Мои кластеры товаров', 'Библиотека моделей']}
                />
                <div className="btns-group">
                  <Button
                    size="s"
                    primary
                    icon={Plus}
                    onClick={() => setShowNameActionComponent('new')}
                  >
                    Добавить кластер
                  </Button>
                  <Button
                    size="s"
                    secondary
                    border
                    onClick={() => setShowUploadFile(true)}
                  >
                    Загрузить файл
                  </Button>
                </div>
              </div>
              <HeaderFilterSearch />
            </HeaderFilter>
            <ClustersListCards
              items={clusters}
              fileInputRef={fileInputRef}
              showUploadFile={showUploadFile}
              setShowUploadFile={setShowUploadFile}
              handleFileInputClick={handleFileInputClick}
              handleFileChange={handleFileChange}
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              isLoading={isLoading}
            />
          </>
        )}
        {(showNameActionComponent === ShowNameAction.New ||
          showNameActionComponent === ShowNameAction.Edit) && (
          <>
            <HeaderFilter>
              <div className="header-filter__content">
                {showNameActionComponent === ShowNameAction.New ? (
                  <>
                    <FilterTabs list={['Товарный кластер']} />
                    <div className="btns-group">
                      <Button
                        size="s"
                        secondary
                        border
                        onClick={() =>
                          setNotification({
                            messages: 'Вы точно хотите покинуть страницу?',
                            type: 'other',
                          })
                        }
                      >
                        Назад
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <FilterTabs
                      list={[`${initialValues.group_product.name}`]}
                    />
                    <div className="btns-group">
                      <Button
                        size="s"
                        secondary
                        border
                        onClick={() =>
                          setNotification({
                            messages:
                              'Вы действительно хотите удалить кластер?',
                            type: 'remove',
                          })
                        }
                      >
                        Удалить
                      </Button>
                      <Button
                        size="s"
                        secondary
                        border
                        onClick={() =>
                          setNotification({
                            messages: 'Вы точно хотите покинуть страницу?',
                            type: 'other',
                          })
                        }
                      >
                        Назад
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </HeaderFilter>

            <div className={cn('cluster-product-management')}>
              <ClusterForm
                onSubmitLoading={onSubmitLoading}
                setProductModalFile={setProductModalFile}
                subCategoriesData2={subCategoriesData2}
                subCategoriesData3={subCategoriesData3}
                setSelectCategory={setSelectCategory}
                setSelectCategory2={setSelectCategory2}
                selectCategory={selectCategory}
                selectCategory2={selectCategory2}
                isLoadingUpdate={isLoading}
                initialValues={initialValues}
                categoriesOptions={categoriesOptions}
                characteristicFields={characteristicFields}
                addCharacteristicField={addCharacteristicField}
                removeCharacteristicField={removeCharacteristicField}
                showContextMenu3D={showContextMenu3D}
                setShowContextMenu3D={setShowContextMenu3D}
                onSubmit={onSubmit}
                productModelRef={productModelRef}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountRetailerClustersManagement;
