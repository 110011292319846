import React from 'react';

import { accountMenuItems } from '@/data/constants';

import AccountSidebar from '@/components/Account/AccountSidebar/AccountSidebar';
import AccountMain from '@/features/AccountRetailer/Statistic';

import '@/components/Account/Products/products.sass';

const AccountStatistics: React.FC = () => {
  return (
    <div className="container account-container">
      <AccountSidebar menuItems={accountMenuItems} />

      <div className="account-content">
        <AccountMain />
      </div>
    </div>
  );
};

export default AccountStatistics;
