import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartStates } from '../types';

const initialState: CartStates = {
  cart_products: null,
  count_total: 0,
  delivery_price: '',
  price_total: '',
  price_with_delivery_total: '',
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setGlobalCartStates: (state, action: PayloadAction<CartStates>) => {
      state.cart_products = action.payload.cart_products;
      state.count_total = action.payload.count_total;
      state.delivery_price = action.payload.delivery_price;
      state.price_total = action.payload.price_total;
      state.price_with_delivery_total =
        action.payload.price_with_delivery_total;
    },
    clearCartState: (state) => {
      // Сбрасываем состояние корзины к начальному значению
      state.cart_products = initialState.cart_products;
      state.count_total = initialState.count_total;
      state.delivery_price = initialState.delivery_price;
      state.price_total = initialState.price_total;
      state.price_with_delivery_total = initialState.price_with_delivery_total;
    },
  },
});

export const { setGlobalCartStates, clearCartState } = cartSlice.actions;
export default cartSlice.reducer;
