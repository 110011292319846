import React from 'react';
import cn from 'classnames';

import { ProductDetailsPropertyProps } from '@/features/ProductCard/types/useProduct';

const ProductDetailsProperty: React.FC<ProductDetailsPropertyProps> = ({
  nameCharacteristic,
  productCharacteristics,
}) => {
  return (
    <div className={cn('product-details__property')}>
      <div className={cn('product-details__property-header')}>
        <p>{nameCharacteristic}</p>
        {/*<p className={cn('product-details__property-guide')}>Размерная сетка</p>*/}
      </div>
      <div className={cn('product-details__property-list')}>
        {productCharacteristics?.map((item) => (
          <div
            key={item.name}
            className={cn('product-details__property-item', {
              'product-details__property-item--active': item.active,
            })}
          >
            {item.name}{' '}
            <span className={cn('product-details__property-quantity')}>
              {item.product_id}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDetailsProperty;
