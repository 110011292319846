import { asNonNullable } from '@/utils/types';
import { filter } from 'lodash-es';
import { useMemo, useState } from 'react';
import {
  useGetProfilleQuery,
  useOnDeleteDocumentMutation,
  useOnUpdateProfileMutation,
} from '../services/profileApi';
import { ProfileDocument, ProfileFormData } from '../types';
import { UseProfile } from '../types/useProfile';
import { serverFormatter } from '../utils';

const useProfile = (): UseProfile => {
  const {
    isLoading,
    data,
    refetch: refetchProfileData,
  } = useGetProfilleQuery();

  const [filesToUpload, setFilesToUpdate] = useState<File[]>([]);
  const [documents, setDocuments] = useState<ProfileDocument[]>([]);
  const [isShowSuccessMessage, setIsShowSuccessMessage] =
    useState<boolean>(false);
  const [confirmationRemoveDocument, setConfirmationRemoveDocument] = useState<
    number | null
  >(null);

  const [onUpdateProfile, { isLoading: isLoadingUpdate }] =
    useOnUpdateProfileMutation();
  const [onDeleteDocument] = useOnDeleteDocumentMutation();

  const profileData = asNonNullable(data?.data);
  const profile_id = profileData?.details?.id;
  const initialValues = { ...profileData?.details, documents_attributes: [] };

  const counterpartyOptions = profileData?.type_of_counterparty;
  const organizationOptions = profileData?.type_of_organization;

  useMemo(() => {
    if (profileData) {
      setDocuments(profileData?.documents);
    }
  }, [profileData]);

  const onSubmit = async (data: ProfileFormData) => {
    try {
      const { documents } = await onUpdateProfile(
        serverFormatter({ ...data, documents_attributes: filesToUpload }),
      ).unwrap();
      setDocuments(documents);
      setFilesToUpdate([]);
      setIsShowSuccessMessage(true);
    } catch (error) {
      console.log(error);
    }
    refetchProfileData();
  };

  const onChangeFiles = (filesData: File[]) => {
    setFilesToUpdate(filesData);
  };

  const onRemoveDocument = (id: number) => async () => {
    try {
      await onDeleteDocument({
        profile_id: asNonNullable(profile_id),
        document_id: id,
      }).unwrap();
      const activeDocuments = filter(
        documents,
        (document) => document.id !== id,
      );
      setDocuments(activeDocuments);
      setConfirmationRemoveDocument(null);
    } catch (error) {
      console.log(error);
    }
  };

  return [
    {
      isLoading,
      isLoadingUpdate,
      isShowSuccessMessage,
      confirmationRemoveDocument,
    },
    {
      initialValues,
      counterpartyOptions,
      organizationOptions,
      documents,
      filesToUpload,
    },
    {
      onSubmit,
      onChangeFiles,
      onRemoveDocument,
      setIsShowSuccessMessage,
      setConfirmationRemoveDocument,
    },
  ];
};

export default useProfile;
