import { Link } from 'react-router-dom';
import cn from 'classnames';

import './user_cabinet_menu.sass';

type UserCabinetMenuProps = {
  active: string;
};
const UserCabinetMenu = ({ active }: UserCabinetMenuProps) => {
  const userCabinetUrls = [
    { label: 'Главная', url: '/user/', key: 'main' },
    { label: 'Мои заказы', url: '/user/orders', key: 'orders' },
    { label: 'Мои адреса', url: '#', key: 'address' },
    { label: 'Избранное', url: '#', key: 'favorites' },
    { label: 'Мои бонусы', url: '#', key: 'bonus' },
    { label: 'Помощь с заказом', url: '#', key: 'help' },
    { label: 'Мои чаты', url: '#', key: 'chats' },
  ];

  return (
    <div className="user-cabinet">
      <div className="user-cabinet__menu">
        {userCabinetUrls.map((link) => (
          <Link
            to={link.url}
            key={link.key}
            className={cn(
              'user-cabinet__menu__item',
              link.key === active ? 'active' : '',
            )}
          >
            {link.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default UserCabinetMenu;
