import React, { useEffect } from 'react';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { useGlobalContext } from '@/contexts/GlobalContext';

import ProductCardSmall from '@/features/ProductCardSmall';

import './searchResultPage.sass';
import useSearchResults from './hooks/useSearchResults';
import ProductsRecommendations from '../ProductsRecommendations';
import Spinner from '@/components/Spinner/Spinner';
import FaceDissatisfied from '@/assets/icons/face--dissatisfied.svg';

const ProductSearchResults: React.FC = () => {
  const [{ launcher3d, myPavilion }] = useGlobalContext();
  const [{ isLoading }, { products, count }] = useSearchResults();

  useEffect(() => {
    if (myPavilion && products && products?.length > 0) {
      myPavilion.clear();
      products?.map((product, index) => {
        if (index < 20 && product.product_3d_alias) {
          myPavilion.addProduct(
            index,
            product.product_3d_alias.trim(),
            product,
          );
        }
      });
    }

    if (launcher3d?.controller && products?.length === 1) {
      launcher3d.controller.teleportTo(products[0].pavilion_id);
    }
    if (launcher3d?.controller && products && products?.length > 1) {
      launcher3d.controller.teleportTo('50AA08A0-DA55-11EE-BF95-7D3DAC5501E7');
    }
  }, [products, launcher3d, myPavilion]);

  return (
    <div id="search-page" className="favorites">
      <div className="search__list-container">
        <div className={cn('block-title mb-2')}>Результаты поиска </div>
        {isLoading ? (
          <Spinner />
        ) : count === 0 ? (
          <div className="empty-list-container">
            <img src={FaceDissatisfied} alt="Face Dissatisfied" />
            <p className="empty-list__text">Ничего не найдено</p>
          </div>
        ) : (
          <Scrollbars>
            <div className="products-list-container">
              {products?.map((product) => (
                <ProductCardSmall
                  key={`${product.name}${product.id}`}
                  product={product}
                />
              ))}
            </div>
          </Scrollbars>
        )}
      </div>

      <ProductsRecommendations />
    </div>
  );
};

export default ProductSearchResults;
