import { apiSlice } from '@/services/apiSlice';
import { ChangePasswordPayload } from '../types/changePassword';
import {
  GuestInitResponse,
  LogInUserPayload,
  LoginUserResponseData,
} from '../types/login';
import {
  PasswordRecoveryPayload,
  PasswordRecoveryResponseData,
} from '../types/passwordRecovery';
import {
  RegisterUserPayload,
  RegisterUserResponseData,
} from '../types/register';
import {
  VerificationPayload,
  VerificationResponseData,
} from '../types/verification';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    onGuestInit: builder.query<GuestInitResponse, void>({
      query: () => ({
        url: '/users/init_guest',
        method: 'GET',
      }),
    }),

    onLogin: builder.mutation<LoginUserResponseData, LogInUserPayload>({
      query: (credentials) => ({
        url: '/users/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    onRegister: builder.mutation<RegisterUserResponseData, RegisterUserPayload>(
      {
        query: (userData) => ({
          url: '/users/registrations',
          method: 'POST',
          body: userData,
        }),
      },
    ),

    onLogout: builder.mutation<void, void>({
      query: () => ({
        url: '/users/logout',
        method: 'DELETE',
      }),
    }),

    onVerify: builder.mutation<VerificationResponseData, VerificationPayload>({
      query: ({ url, body }) => ({
        url: `/users/${url}`,
        method: 'POST',
        body: body,
      }),
    }),

    onPasswordRecovery: builder.mutation<
      PasswordRecoveryResponseData,
      PasswordRecoveryPayload | ChangePasswordPayload
    >({
      query: (userData) => ({
        url: '/users/password-recovery',
        method: 'POST',
        body: userData,
      }),
    }),
  }),
});

export const {
  useOnGuestInitQuery,
  useOnLoginMutation,
  useOnRegisterMutation,
  useOnLogoutMutation,
  useOnVerifyMutation,
  useOnPasswordRecoveryMutation,
} = authApiSlice;
