import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';
import cn from 'classnames';

import Spinner from '@/components/Spinner/Spinner';

import { RootState } from '@/store/store';

import useCart from '@/features/Cart/hooks/useCart';

import CartItem from '@/features/Cart/components/CartItem/CartItem';

import './cart-content.sass';

import FaceDissatisfied from '@/assets/icons/face--dissatisfied.svg';

const CartContent: React.FC = () => {
  const [
    { isLoading },
    { data },
    { onDecrease, onIncrease, onRemove, catrRefetch },
  ] = useCart();
  const globalCartData = useSelector((state: RootState) => state.cart);

  return (
    <div className="cart-content">
      <div className="block-title mb-2">Корзина</div>
      <div className="cart-items">
        {isLoading ? (
          <Spinner />
        ) : globalCartData.count_total > 0 ? (
          map(data, (cartItem) => (
            <CartItem
              key={cartItem.id}
              onDecrease={onDecrease}
              onIncrease={onIncrease}
              cartItem={cartItem}
              onRemove={onRemove}
              catrRefetch={catrRefetch}
            />
          ))
        ) : (
          <div className={cn('empty-list-container')}>
            <img src={FaceDissatisfied} alt="Face Dissatisfied" />
            <p className={cn('empty-list__text')}>
              В вашей корзине ничего не найдено
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartContent;
