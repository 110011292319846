import { get } from 'lodash-es';
import { useState } from 'react';
import { useOnPasswordRecoveryMutation } from '../services/authApi';
import { ErrorsMessage } from '@/types/forms';
import {
  PasswordRecoveryFormData,
  PASSWORD_RECOVERY_STEPS,
} from '../types/passwordRecovery';

import { UsePasswordRecovery } from '../types/usePasswordRecovery';

const usePasswordRecovery = (): UsePasswordRecovery => {
  const [onPasswordRecovery, { isLoading }] = useOnPasswordRecoveryMutation();
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [currentStep, setCurrentStep] = useState<PASSWORD_RECOVERY_STEPS>(
    PASSWORD_RECOVERY_STEPS.SEND_PHONE,
  );

  const initialValues = {
    phone: '',
    password: '',
    password_confirmation: '',
    step: currentStep,
    verification_code: '',
  };

  const onChangeStep = (type: PASSWORD_RECOVERY_STEPS) => () => {
    setCurrentStep(type);
  };

  const onSubmit = async (data: PasswordRecoveryFormData) => {
    try {
      const response = await onPasswordRecovery({ user: data }).unwrap();
      setPhoneNumber(data.phone);
      if (response) {
        setCurrentStep(PASSWORD_RECOVERY_STEPS.VERIFY_CODE);
      }
    } catch (error) {
      const errors = get(error, 'data.errors') as unknown as ErrorsMessage;
      return errors;
    }
  };

  return [
    {
      isLoading,
    },
    {
      phoneNumber,
      currentStep,
      initialValues,
    },
    {
      onSubmit,
      onChangeStep,
    },
  ];
};
export default usePasswordRecovery;
