import CartContent from '@/features/Cart/components/CartContent/CartContent';
import CartSidebar from '@/features/Cart/components/CartSidebar/CartSidebar';

const Cart: React.FC = () => {
  return (
    <div className="container cart">
      <CartContent />
      <CartSidebar />
    </div>
  );
};
export default Cart;
