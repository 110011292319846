import React from 'react';
import cn from 'classnames';

import { accountMenuItems } from '@/data/constants';

import PavilionMap from '@/features/PavilionMap';

import FilterTabs from '@/components/Account/HeaderFilter/FilterTabs';
import AccountSidebar from '@/components/Account/AccountSidebar/AccountSidebar';

import '@/components/Account/Products/products.sass';

const Pavilions: React.FC = () => {
  return (
    <div className="container account-container">
      <AccountSidebar menuItems={accountMenuItems} />

      <div className={cn('account-content')}>
        <FilterTabs list={['Карта павильонов']} />
        <PavilionMap typeAction="edit" />
      </div>
    </div>
  );
};

export default Pavilions;
