import { useParams } from 'react-router-dom';
import { useGetProductsQuery } from '../services/productsApi';
import { ProductsQueryParams } from '../types';
import { UseCatalogModal } from '../types/useCatalogPage';

const useProducts = (): UseCatalogModal => {
  const params = useParams();

  const id = params.id as string;

  const queryParams: ProductsQueryParams = {
    page: 1,
    cat_id: id,
    per_page: 50,
  };

  const { data, isLoading } = useGetProductsQuery({ ...queryParams });

  const products = data?.data;
  const count = products?.length || 0;

  return [
    {
      isLoading,
    },
    {
      count,
      products,
    },
  ];
};
export default useProducts;
