import { BaseSyntheticEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetCategoriesQuery } from '@/features/CatalogueModal/services/categoriesApi';

import { asNonNullable } from '@/utils/types';
import { replaceUrlParam } from '@/utils/urls';

import { APPLICATION_URLS } from '@/constants/urls';

import { Pavilion } from '@/features/PavilionMap/types';
import { Category } from '@/types/entities/category';
import {
  UseCatalogModal,
  UseCatalogModalProps,
} from '@/features/CatalogueModal/types/useCatalogModal';
import { CategoryLevel } from '../types';

const { catalog } = APPLICATION_URLS;

const useCatalogModal = ({
  setShowCatalogue,
}: UseCatalogModalProps): UseCatalogModal => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetCategoriesQuery();
  const [selectedMainCategory, setSelectedMainCategory] =
    useState<Category | null>(null);
  const [selectedSecondaryCategory, setSelectedSecondaryCategory] =
    useState<Category | null>(null);
  const [selectPavilionCategories, setSelectPavilionCategories] = useState<
    string[]
  >([]);

  const categories = asNonNullable(data?.data?.categories);
  const pavilions: Pavilion[] = data?.data?.pavilions ?? [];

  const mainCategories = useMemo(() => {
    const firstCategory = categories?.[0] || null;
    if (firstCategory && !selectedMainCategory) {
      setSelectedMainCategory(firstCategory);
    }
    return categories;
  }, [categories, selectedMainCategory]);

  const secondCategories = useMemo(() => {
    return selectedMainCategory?.subcats || [];
  }, [selectedMainCategory]);

  const [typeCatalogueModal, setTypeCatalogueModal] = useState<
    'categories' | 'map'
  >('categories');

  const onChangeMainCategory = (category: Category) => {
    setSelectedMainCategory(category);
  };

  const onChangeSecondaryCategory = (category: Category) => {
    setSelectedSecondaryCategory(category);
  };

  const onClickCategory =
    (category: Category) => (event: BaseSyntheticEvent) => {
      event.preventDefault();
      const url = replaceUrlParam(catalog, String(category.id));
      navigate(url);
      setShowCatalogue((show) => !show);
    };

  const onEnterCategory =
    (category: Category, level: CategoryLevel) =>
    (event: BaseSyntheticEvent) => {
      event.preventDefault();
      if (level === 'MAIN_CATEGORY') {
        if (!selectedMainCategory || selectedMainCategory?.id !== category.id) {
          onChangeMainCategory(category);
        }
      } else if (level === 'SECOND_CATEGORY') {
        if (
          !selectedSecondaryCategory ||
          selectedSecondaryCategory?.id !== category.id
        ) {
          onChangeSecondaryCategory(category);
        }
      }

      const categoryIds = [
        category.id,
        ...(category.subcats?.map((subcat) => subcat.id) ?? []),
      ];

      const relatedPavilionsUuids = pavilions
        .filter((pavilion) =>
          pavilion.cat_ids?.some((catId) => categoryIds.includes(catId)),
        )
        .map((pavilion) => pavilion.pavilionID);
      setSelectPavilionCategories(relatedPavilionsUuids);
      return false;
    };

  const onLeaveCategory = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    setSelectPavilionCategories([]);
  };

  return [
    {
      isLoading,
    },
    {
      mainCategories,
      secondCategories,
      selectedMainCategory,
      selectedSecondaryCategory,
      pavilions,
      typeCatalogueModal,
      selectPavilionCategories,
    },
    {
      onClickCategory,
      onEnterCategory,
      onLeaveCategory,
      setTypeCatalogueModal,
    },
  ];
};
export { useCatalogModal };
