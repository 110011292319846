import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { useGlobalContext } from '@/contexts/GlobalContext';

import { useOnGuestInitQuery } from '@/features/Auth/services/authApi';

// Использование хуков RTK Query на верхнем уровне
const useGuestInit = () => {
  const [{ accessToken }, { setAccessToken }] = useGlobalContext();
  const {
    data: guestData,
    isError,
    refetch: refetchGuestData,
  } = useOnGuestInitQuery(undefined, {
    skip: !!accessToken,
  });

  useEffect(() => {
    // Обработка ответа от useOnGuestInitQuery
    if (
      !accessToken &&
      guestData &&
      'data' in guestData &&
      'token' in guestData.data
    ) {
      Cookies.set('accessToken', guestData.data.token);
      setAccessToken(guestData.data.token);
    }

    // Попытка повторного запроса при ошибке и отсутствии accessToken
    if (isError && !accessToken) {
      refetchGuestData();
    }
  }, [accessToken, guestData, isError, setAccessToken, refetchGuestData]);
};

export default useGuestInit;
