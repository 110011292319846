import React from 'react';

type IconProps = {
  src: string;
  alt: string;
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const Icon: React.FC<IconProps> = (props) => (
  <React.Fragment>
    <img {...props} />
  </React.Fragment>
);

export default Icon;
