type Size = {
  width: number;
  height: number;
};

type SizeCallback = (size: Size) => void;

const observeElementSize = (
  element: Element,
  callback: SizeCallback,
): (() => void) => {
  if (typeof ResizeObserver !== 'function') {
    console.error('ResizeObserver is not supported in your browser.');
    return () => {};
  }

  // Создаем экземпляр ResizeObserver
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const { width, height } = entry.contentRect;
      // Вызываем callback с новыми размерами
      callback({ width, height });
    }
  });

  // Начинаем наблюдение за элементом
  resizeObserver.observe(element);

  // Возвращаем функцию для остановки наблюдения
  return () => resizeObserver.unobserve(element);
};

// Использование
// const element = document.getElementById('myElement');
// if (element) {
//   const stopObserving = observeElementSize(element, size => {
//     console.log(`New size: width ${size.width}px, height ${size.height}px`);
//   });
//
//   // Позже, если вам нужно остановить наблюдение
//   // stopObserving();
// }

export default observeElementSize;
