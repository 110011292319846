declare global {
  interface Window {
    Viewer3D: any;
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import cn from 'classnames';

import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { ProductViewProps } from '@/features/ProductCard/types/useProduct';

import { useGlobalContext } from '@/contexts/GlobalContext';

import TypeSLide3D from '@/assets/icons/3D-MPR.svg';
import TypeSlideImage from '@/assets/icons/image.svg';
import TypeSlideVideo from '@/assets/icons/video.svg';

const ProductView: React.FC<ProductViewProps> = ({
  productViewData,
  handleBack,
}) => {
  const [{ is3dSceneReady, viewer3d, launcher3d }] = useGlobalContext();

  const productViewer3dRef = useRef<HTMLDivElement>(null);
  const [typeView, setTypeView] = useState<string>('image3d');
  const [activeData, setActiveData] = useState(
    productViewData?.images?.[0]?.in_show,
  );

  // Обработчик клика, который обновляет активное изображение
  const handleImageClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    type: string,
    image?: string,
  ) => {
    if (!e.currentTarget.classList.contains('slide-active')) {
      setTypeView(type);
      image && setActiveData(`${image}`);
      document
        .querySelectorAll('.slide__in-show')
        .forEach((item) => item.classList.remove('slide-active'));
      e.currentTarget.classList.add('slide-active');
    }
  };

  useEffect(() => {
    if (productViewer3dRef.current && viewer3d) {
      const domElement = viewer3d.getDomElement();
      domElement.id = 'product-3d-viewer';
      productViewer3dRef.current.appendChild(domElement);
    }
  }, [productViewer3dRef.current, viewer3d]);

  useEffect(() => {
    const viewer = document.querySelector(
      '#product-3d-viewer',
    ) as HTMLElement | null;
    if (viewer) {
      if (typeView !== 'image3d') {
        viewer.style.display = 'none';
      } else {
        viewer.style.display = '';
      }
    }
  }, [typeView]);

  useEffect(() => {
    if (
      is3dSceneReady &&
      viewer3d &&
      productViewData.alias_model_3d &&
      launcher3d
    ) {
      viewer3d.update(800, 800);
      viewer3d.clear().show(productViewData.alias_model_3d);
      viewer3d.render();
    }
    return () => {
      viewer3d && viewer3d.clear();
    };
  }, [is3dSceneReady, productViewData.alias_model_3d, launcher3d]);

  return (
    <div className={cn('product-view')}>
      <div className={cn('product-view__in-show')}>
        <div
          className={cn('product-view__img-wrapper')}
          ref={productViewer3dRef}
        >
          {productViewData.discount && (
            <span className={cn('product-discount')}>
              {productViewData.discount}
            </span>
          )}
          {typeView === 'image' && <img src={activeData} alt="Image" />}
          {typeView === 'video' && <img src={activeData} alt="Video" />}
        </div>

        <Swiper>
          <SwiperSlide>
            <div
              className={cn(
                'swiper-slide__wrapper swiper-slide__type-container',
              )}
            >
              <img
                key="slider-type--image3d"
                src={TypeSLide3D}
                alt="3D"
                className={cn('slider__slide-type slider__icon --3d')}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              onClick={(e) => handleImageClick(e, 'image3d')}
              className={cn(
                'swiper-slide__wrapper slide__in-show slide-active',
              )}
            >
              <span>3D</span>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className={cn(
                'swiper-slide__wrapper swiper-slide__type-container',
              )}
            >
              <img
                key="slider-type--video"
                src={TypeSlideImage}
                alt="Image"
                className={cn('slider__slide-type slider__icon --image')}
              />
            </div>
          </SwiperSlide>

          {productViewData?.images &&
            productViewData.images.length > 0 &&
            productViewData.images.map((item) => (
              <SwiperSlide key={item.name}>
                <div
                  onClick={(e) => handleImageClick(e, 'image', item.in_show)}
                  className={cn('swiper-slide__wrapper slide__in-show')}
                >
                  <img src={item.in_preview} alt={item.name} />
                </div>
              </SwiperSlide>
            ))}

          <SwiperSlide>
            <div
              className={cn(
                'swiper-slide__wrapper swiper-slide__type-container',
              )}
            >
              <img
                key="slider-type--image"
                src={TypeSlideVideo}
                alt="Video"
                className={cn('slider__slide-type slider__icon --video')}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div onClick={() => handleBack()} className="btn__app">
        Назад
      </div>
    </div>
  );
};

const MemoizedProductView = React.memo(ProductView);

export default MemoizedProductView;
