import { apiSlice } from '@/services/apiSlice';
import { GetProductsResponseData, ProductsQueryParams } from '../types';

export const productSearchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSearchProducts: builder.query<
      GetProductsResponseData,
      ProductsQueryParams
    >({
      query: (arg) => {
        const { page, per_page, cat_id, has_search } = arg;
        return {
          url: '/products',
          params: { page, per_page, cat_id, has_search },
        };
      },
    }),
  }),
});

export const { useGetSearchProductsQuery } = productSearchApiSlice;
