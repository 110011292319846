const useStyles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 11,
    padding: 2,
  },
  button: {
    borderRadius: 11,
    width: '100%',
    fontWeight: 600,
    height: 30,
    transition: '1s all',
    justifyContent: 'center',
  },
  active: {
    background: 'rgba(255, 255, 255, 0.34)',
  },
});

export { useStyles };
