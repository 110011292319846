import { apiSlice } from '@/services/apiSlice';

import {
  PavilionMapResponse,
  PavilionUsersFormData,
  PavilionUsersResponse,
  PavilionUsersResponseError,
} from '@/features/PavilionMap/types/usePavilionMap';

export const pavilionMapApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPavilionMiniMap: builder.query<PavilionMapResponse, void>({
      query: () => ({
        url: '/pavilions/mini_map',
        method: 'GET',
      }),
    }),
    addPavilionUser: builder.mutation<
      PavilionUsersResponse | PavilionUsersResponseError,
      PavilionUsersFormData
    >({
      query: (body) => ({
        url: '/pavilion_users',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const { useGetPavilionMiniMapQuery, useAddPavilionUserMutation } =
  pavilionMapApiSlice;
