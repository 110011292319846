import { apiSlice } from '@/services/apiSlice';
import {
  GetPavilionUsersResponseData,
  PavilionData,
} from '@/features/PavilionBuilderLayout/types/usePavilionBuilderLayout';

export const pavilionUsersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPavilionUsers: builder.query<GetPavilionUsersResponseData, number>({
      query: (id) => `/pavilions/${id}/show_by_3d`,
    }),
    updatePavilionUsers: builder.mutation<
      GetPavilionUsersResponseData,
      PavilionData
    >({
      query: (requestData) => ({
        url: `/pavilions/${requestData?.id}`,
        method: 'PUT',
        body: requestData.formData,
      }),
    }),
  }),
});

export const { useGetPavilionUsersQuery, useUpdatePavilionUsersMutation } =
  pavilionUsersApiSlice;
