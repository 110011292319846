import React from 'react';

import UserCabinetMenu from '@/components/UserCabinet/UserCabinetMenu/UserCabinetMenu';
import AccountStandard from '@/pages/AccountStandard';

import '@/components/UserCabinet/user_cabinet.sass';

const UserCabinetMain: React.FC = () => {
  return (
    <div className="container account-container__user">
      <UserCabinetMenu active="main" />
      <AccountStandard />
    </div>
  );
};

export default UserCabinetMain;
