import { useRef, useState } from 'react';
import {
  useGetPavilionMiniMapQuery,
  useAddPavilionUserMutation,
} from '@/features/PavilionMap/services/usePavilionMapApi';

import { useGlobalContext } from '@/contexts/GlobalContext';
import { useIs3D } from '@/utils/Is3DContext';

import dimensionToggle from '@/utils/dimensionToggle';

import {
  PavilionData,
  PavilionUsersFormData,
  PopupState,
  UsePavilionsMap,
} from '@/features/PavilionMap/types/usePavilionMap';

const usePavilionMap = (): UsePavilionsMap => {
  const [{ launcher3d }] = useGlobalContext();
  const { is3D, setIs3D } = useIs3D();

  const [responsePavilionUser, setResponsePavilionUser] = useState<
    string[] | null
  >(null);
  const [miniMapController, setMiniMapController] = useState<any>(null);
  const [isMyPavilion, setIsMyPavilion] = useState<boolean>(false);
  const mapMallContainerRef = useRef<HTMLDivElement | null>(null);
  const mapMallRef = useRef<HTMLCanvasElement | null>(null);

  const [actionPavilionMap, setActionPavilionMap] = useState<'view' | 'edit'>(
    'view',
  );

  const [pavilionId] = useState<number>(0);
  const [pavilionData, setPavilionData] = useState<PavilionData | null>(null);

  const [popup, setPopup] = useState<PopupState>({
    visible: false,
    x: 0,
    y: 0,
  });
  const [mapMall, setMapMall] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState<
    'prompt' | 'success' | 'error' | null
  >(null);
  const [pavilionNumber, setPavilionNumber] = useState<number>(0);

  const { data: miniMapData, isLoading: isLoadingPavilionsMap } =
    useGetPavilionMiniMapQuery();
  const [addPavilionUser] = useAddPavilionUserMutation();

  const teleport = (setShowCatalogue: (showCatalog: boolean) => void) => () => {
    if (pavilionData && actionPavilionMap === 'view') {
      launcher3d.controller.teleportTo(pavilionData.pavilionID);
      setPavilionData(null);
      setShowCatalogue(false);
      setIs3D(!is3D);
      dimensionToggle({ is3D });
    }
  };

  const onSubmit = async (data: PavilionUsersFormData) => {
    try {
      const response = await addPavilionUser(data).unwrap();
      if (response.status === 'success') {
        setCurrentStep('success');
      } else {
        setCurrentStep('error');
        setResponsePavilionUser(response.data.errors.status);
      }
    } catch (error: any) {
      setCurrentStep('error');
      if ('data' in error && error.data.errors.status) {
        setResponsePavilionUser(error.data.errors.status);
      }
    }
  };

  // const oddBookedStep = (pos: number): void => {
  //   setCurrentStep(1);
  //   setPavilionNumber(pos);
  // };

  return [
    {
      isLoadingPavilionsMap,
    },
    {
      responsePavilionUser,
      miniMapController,
      isMyPavilion,
      mapMallContainerRef,
      mapMallRef,
      actionPavilionMap,
      pavilionId,
      pavilionData,
      popup,
      mapMall,
      miniMapData: miniMapData?.data.pavilions || [],
      currentStep,
      pavilionNumber,
    },
    {
      setMiniMapController,
      setIsMyPavilion,
      teleport,
      setActionPavilionMap,
      onSubmit,
      setCurrentStep,
      setPopup,
      setMapMall,
      setPavilionData,
      setPavilionNumber,
    },
  ];
};

export { usePavilionMap };
