import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useGlobalContext } from '@/contexts/GlobalContext';

import Logo from '@/layouts/Header/Logo';

import { MenuCategory } from '@/types/types';
import { PageTypeNames } from '@/types/pagesTypes';

import './footer.sass';

import arrow_down from '@/assets/icons/footer/list-icon.svg';

const menuItems: MenuCategory = {
  marketplace: [
    { title: 'О нас', href: '/about', alias: 'about' },
    { title: 'Контакты', href: '/contacts', alias: 'contacts' },
    {
      title: 'Реквизиты',
      href: '/requisites',
      alias: 'requisites',
    },
  ],
  retailer: [
    {
      title: 'Сотрудничество',
      href: '/cooperation-retailer',
      alias: 'cooperation',
    },
    {
      title: 'База знаний',
      href: '/knowledge-base-retailer',
      alias: 'knowledge-base',
    },
    {
      title: 'Вход в личный кабинет',
      href: '/login-retailer',
      alias: 'log-in',
    },
  ],
  politika: [
    {
      title: 'Условия использования',
      href: '/terms-of-use-politika',
      alias: 'terms-use',
    },
    {
      title: 'Обработка данных',
      href: '/data-processing-politika',
      alias: 'data-processing',
    },
    {
      title: 'Условия заказа и доставки',
      href: '/marketplace/terms-order-delivery',
      alias: 'order-and-delivery-terms-politika',
    },
  ],
};

const Footer: React.FC = () => {
  const [{ blockFooterActive, isFooterVisible }, { setIsFooterVisible }] =
    useGlobalContext();

  const pagesStaticTypeRefs = useRef<(HTMLDivElement | null)[]>([]);

  const pageTypeNames: PageTypeNames = {
    marketplace: 'Маркетплейс',
    retailer: 'Продавцам',
    politika: 'Политика',
  };

  // Поправить any
  const checkIfScrolledToEnd = (element: HTMLElement | null): boolean => {
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    const scrollTop = window.scrollY;
    const elementTop = rect.top + scrollTop;
    const totalHeight = elementTop + element.offsetHeight;

    return window.innerHeight + window.scrollY >= totalHeight;
  };

  useEffect(() => {
    if (!blockFooterActive) {
      const handleWheel = (e: WheelEvent) => {
        const contentElement = document.querySelector(
          '.content',
        ) as HTMLElement | null;

        if (e.currentTarget === contentElement && contentElement) {
          if (e.deltaY > 0 && checkIfScrolledToEnd(contentElement)) {
            setIsFooterVisible(true);
          } else if (e.deltaY < 0) {
            setIsFooterVisible(false);
          }
        }
      };

      const contentElement = document.querySelector(
        '.content',
      ) as HTMLElement | null;
      contentElement?.addEventListener('wheel', handleWheel);

      // Чистим события при размонтировании компонента
      return () => {
        contentElement?.removeEventListener('wheel', handleWheel);
      };
    }
  }, [blockFooterActive]);

  useEffect(() => {
    let touchStart: number | null = null;
    let touchEnd: number | null = null;

    const handleTouchStart = (e: TouchEvent): void => {
      if (!blockFooterActive) {
        touchStart = e.targetTouches[0].clientY;
      }
    };

    const handleTouchEnd = (e: TouchEvent): void => {
      if (!blockFooterActive) {
        touchEnd = e.changedTouches[0].clientY;

        if (touchStart !== null && touchEnd !== null) {
          if (touchStart - touchEnd > 10) {
            const contentElement = document.querySelector(
              '.content',
            ) as HTMLElement;
            if (checkIfScrolledToEnd(contentElement)) {
              setIsFooterVisible(true);
            }
          } else if (touchEnd - touchStart > 10) {
            setIsFooterVisible(false);
          }
        }
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [blockFooterActive]);

  useEffect(() => {
    const toggleFooterVisibility = () => {
      setIsFooterVisible(false);
      const contentHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;
      if (contentHeight <= viewportHeight) {
        setIsFooterVisible(false);
      } else {
        setIsFooterVisible(true);
      }
    };

    document.addEventListener('DOMContentLoaded', toggleFooterVisibility);
    window.addEventListener('resize', toggleFooterVisibility);

    return () => {
      window.removeEventListener('resize', toggleFooterVisibility);
    };
  }, []);

  const handleBlockToggle = (index: number) => {
    const pagesStatic = pagesStaticTypeRefs.current[index];
    if (pagesStatic?.classList.contains('active')) {
      pagesStatic?.classList.remove('active');
    } else {
      pagesStaticTypeRefs.current.forEach((item) =>
        item?.classList.remove('active'),
      );
      pagesStatic?.classList.add('active');
    }
    return;
  };

  const renderMenu = (menuItems: MenuCategory) => {
    return Object.keys(menuItems).map((key, index) => {
      const menuKey = key as keyof MenuCategory;
      const pageTitle = pageTypeNames[menuKey];
      return (
        <div
          ref={(el) => {
            if (pagesStaticTypeRefs.current) {
              pagesStaticTypeRefs.current[index] = el;
            }
          }}
          key={menuKey}
          className={cn('pages-static__type-wrapper type-wrapper')}
          onTouchStart={() => handleBlockToggle(index)}
        >
          <span className="type-wrapper__title">
            {pageTitle}
            <img src={arrow_down} alt="Arrow" />
          </span>
          <div className={cn('type-wrapper__items')}>
            {menuItems[menuKey].map((item) => (
              <Link key={item.alias} to={item.href}>
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <footer className={cn('footer', { 'footer-visible': isFooterVisible })}>
      <div className="footer-content container">
        <div className="backdrop-filter"></div>
        <div className={cn('footer-content__pages-static pages-static')}>
          {renderMenu(menuItems)}
        </div>

        <div className={cn('footer-content__contacts')}>
          <span className={cn('footer-content_contacts-title')}>
            Есть вопросы?
          </span>
          <a
            href="tel:79264108602"
            className={cn('footer-content_contacts-phone')}
          >
            Звоните 8 (926) 410-86-02
          </a>
          <span className={cn('footer-content__contacts-schedule')}>
            Режим работы с 6 до 24
          </span>
        </div>

        <div className={cn('footer-content__logo')}>
          <Logo />
          <span className={cn('footer-content__logo-date')}>
            {`${new Date().getFullYear()} “Unemag”`}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
