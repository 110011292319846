import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useGlobalContext } from '@/contexts/GlobalContext';

import truncateString from '@/utils/truncateString';

import { ResponseSearchData } from '@/features/ProductSearch/types/useProductSearch';

import ArrowLink from '@/assets/icons/arrow-down-right.svg';

type SearchResultsProps = {
  onBlurSearchForm: () => void;
  searchResultsData: ResponseSearchData;
};

const SearchResults: React.FC<SearchResultsProps> = ({
  onBlurSearchForm,
  searchResultsData,
}) => {
  const [{ myPavilion }] = useGlobalContext();

  return (
    <div className="search-results">
      <ul className="search-results__hints">
        {searchResultsData?.products.map((item, index) => (
          <Link
            to={`/products/${item.id}`}
            key={`${item.id}${index}`}
            onClick={onBlurSearchForm}
            className="search-results__hint"
          >
            <div className="search-results__hint-img">
              <img src={ArrowLink} alt="Arrow Link" />
            </div>
            <span className="search-results__hint-text">
              {truncateString(item.name, 67)}
            </span>
          </Link>
        ))}
        {searchResultsData?.categories.map((item, index) => (
          <Link
            to={`/shop/${item.id}`}
            key={`${item.id}${index}`}
            onClick={() => {
              onBlurSearchForm();
              myPavilion.clear();
            }}
            className="search-results__hint --category"
          >
            <div className="search-results__hint-img">
              <span className={cn('img-category')}></span>
            </div>

            <div className="search-results__group-categories">
              <span className="search-results__hint-text-main">
                {truncateString(item.name)}
              </span>
              <span className="search-results__hint-text-subtext">
                {item?.parent ? (
                  <>
                    {item.parent.name}
                    <span className={cn('dot-cat-delimetr')}></span>
                    {item.name}
                  </>
                ) : (
                  <>{item.name}</>
                )}
              </span>
            </div>
          </Link>
        ))}
        {searchResultsData?.pavilions.map((item, index) => (
          <Link
            to={`/shop/${item.id}`}
            key={`${item.id}${index}`}
            onClick={onBlurSearchForm}
            className="search-results__hint --pavilion"
          >
            <div className="search-results__hint-img">
              <img src={item.image} alt="Shop Icon" />
            </div>
            <div className={cn('search-results__hint-info')}>
              <span className="search-results__hint-info-sub-text">
                {item.name}
              </span>
            </div>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default SearchResults;
