import React from 'react';

import SearchContainer from './components/SearchContainer';
import SearchForm from './components/SearchForm';
import { useProductSearch } from '@/features/ProductSearch/hooks/useProductSearch';

import './product-search.sass';

const ProductSearch: React.FC = () => {
  const [
    {
      searchFormRef,
      searchContainerRef,
      searchText,
      showSearch,
      searchResultsData,
    },
    { setSearchText, setShowSearch },
  ] = useProductSearch();

  const onBlurSearchForm = () => {
    setShowSearch(false);
  };

  return (
    <>
      <SearchForm
        searchFormRef={searchFormRef}
        searchContainerRef={searchContainerRef}
        showSearch={showSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        setShowSearch={setShowSearch}
        onBlurSearchForm={onBlurSearchForm}
      />
      {showSearch && (
        <SearchContainer
          searchContainerRef={searchContainerRef}
          onBlurSearchForm={onBlurSearchForm}
          searchResultsData={searchResultsData}
        />
      )}
    </>
  );
};

export default ProductSearch;
