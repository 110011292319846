import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import logo from '@/assets/icons/logo/logo-white.svg';

const Logo: React.FC = () => {
  return (
    <Link to="/" className={cn('logo header__logo')}>
      <img src={logo} alt="UNEMAG - инновационный маркетплейс" />
    </Link>
  );
};

export default Logo;
