import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import CheckmarkOutline from '@/assets/icons/checkmark--outline.svg';
import Modal from '@/layouts/Modal/Modal';

import Verification from '../Verification/Verification';

import RegistrationForm from '../../forms/RegistrationForm';
import useRegister from '../../hooks/useRegister';
import { REGISTER_USER_TYPE, REGISTRATION_STEPS } from '../../types/register';
import { STEP_KIND } from '../../types';

type RegistrationModalProps = {
  onClickLogin: () => void;
  onCloseModal: () => void;
};

import '@/components/ui/Select/select.sass';
import '@/features/Auth/auth.sass';
import Spinner from '@/components/Spinner/Spinner';

const RegistrationUser: React.FC<RegistrationModalProps> = ({
  onClickLogin,
  onCloseModal,
}) => {
  const [
    { isLoading, isPageDataLoading },
    {
      currentStep,
      registerUserType,
      initialValues,
      registedUserPhone,
      canShowPolicy,
      policyData,
    },
    {
      onSubmit,
      onChangeRegisterUsertype,
      onChangeStep,
      onOpenPolicy,
      onClosePolicy,
    },
  ] = useRegister();

  return (
    <>
      <Modal
        onClose={
          currentStep === REGISTRATION_STEPS.STEP_2
            ? onChangeStep(STEP_KIND.BACK)
            : onCloseModal
        }
        headerTitle={`Регистрация (${registerUserType === REGISTER_USER_TYPE.CLIENT ? 'Клиента' : 'Партнера'})`}
        textBtnClose={
          currentStep === REGISTRATION_STEPS.STEP_2 ? `Назад` : null
        }
      >
        <div
          className={cn('auth-modal-content', {
            'registration-form__success':
              currentStep === REGISTRATION_STEPS.STEP_3,
          })}
        >
          {currentStep === REGISTRATION_STEPS.STEP_1 && (
            <>
              <RegistrationForm
                isLoading={isLoading}
                className="auth-modal-content__form form-primary"
                initialValues={initialValues}
                registerUserType={registerUserType}
                onSubmit={onSubmit}
                onOpenPolicy={onOpenPolicy}
              />
              <div className="auth-modal-content__submit">
                <button
                  className="auth-modal-content__submit-btn submit-btn btn-primary"
                  type="submit"
                  form="registration-form"
                >
                  Далее
                </button>
              </div>

              <p className="auth-modal-content__offer">
                Уже есть аккаунт?
                <span
                  className="auth-modal-content__login-link link"
                  onClick={onClickLogin}
                >
                  Войти
                </span>
              </p>
              <div className="auth-modal-content__separator">
                <span>или</span>
              </div>
              <div
                className="auth-modal-content__change-form link"
                onClick={() =>
                  onChangeRegisterUsertype(
                    registerUserType === REGISTER_USER_TYPE.CLIENT
                      ? REGISTER_USER_TYPE.RETAILER
                      : REGISTER_USER_TYPE.CLIENT,
                  )
                }
              >
                {`Зарегистрироваться как ${registerUserType === REGISTER_USER_TYPE.CLIENT ? 'партнёр' : 'покупатель'}`}
              </div>
            </>
          )}

          {currentStep === REGISTRATION_STEPS.STEP_2 && (
            <Verification
              next={onChangeStep(STEP_KIND.NEXT)}
              phone={registedUserPhone}
              verificationLink="verification"
            />
          )}
          {currentStep === REGISTRATION_STEPS.STEP_3 && (
            <div className="auth-modal-content__success">
              <img src={CheckmarkOutline} alt="Checkmark Outline" />
              <span className="auth-modal-content__success-message">
                Поздравляем! Вы успешно зарегистрированы
              </span>
              <Link
                className="auth-modal-content__shop-link btn-primary"
                onClick={onClickLogin}
                to="/"
              >
                Зайти в аккаунт
              </Link>
            </div>
          )}
        </div>
      </Modal>

      {canShowPolicy && (
        <Modal onClose={onClosePolicy} theme={'policy-modal'}>
          <div className="">
            {isPageDataLoading && <Spinner />}
            <div className="title-wrapper">
              <h1 className="title">{policyData?.h1}</h1>
            </div>

            {policyData?.description && (
              <div
                className={cn('static-page__content')}
                dangerouslySetInnerHTML={{ __html: policyData?.description }}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default RegistrationUser;
