import React from 'react';

import cn from 'classnames';

import { IssueProps } from '@/features/AccountRetailer/types/useStatistic';

import '@/features/AccountRetailer/components/StatisticComponents/Main/Issue/issue.sass';

const Issue: React.FC<IssueProps> = ({ title, description, status }) => {
  const classes = cn('issue');

  return (
    <div className={classes}>
      <p className="account-text account-text-s bold-text mb-1">{title}</p>
      <p className="grey-text account-text-s bold-text">{description}</p>

      {status && (
        <span
          className={cn('issue__status')}
          style={{ backgroundColor: status }}
        ></span>
      )}
    </div>
  );
};

export default Issue;
