import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Spinner from '@/components/Spinner/Spinner';

import { Is3DProvider } from '@/utils/Is3DContext';
import { ProductProvider } from '@/contexts/ProductContext';

import ProtectedRoute from '@/utils/ProtectedRoute';

import ProtectedApp from '@/features/ProtectedApp';

import {
  APPLICATION_ROUTES,
  AUTH_RETAILER_ROUTES,
  USER_CABINET_ROUTES,
} from './routes/routes';

import RouteWatcher from '@/routes/routeWatcher';

import CommonLayout from './layouts/CommonLayout/common_layout';

// Styles
import './App.css';

const Application: React.FC = () => {
  return (
    <ProtectedApp>
      <BrowserRouter>
        <Is3DProvider>
          <ProductProvider>
            <RouteWatcher>
              <CommonLayout>
                <Suspense fallback={<Spinner />}>
                  <Routes>
                    {APPLICATION_ROUTES.map((item) => (
                      <Route key={item.path} {...item} />
                    ))}

                    {USER_CABINET_ROUTES.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          <ProtectedRoute
                            allowedRoles={['client']}
                            element={route.element}
                          />
                        }
                      />
                    ))}

                    {AUTH_RETAILER_ROUTES.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          <ProtectedRoute
                            allowedRoles={['retailer']}
                            element={route.element}
                          />
                        }
                      />
                    ))}
                  </Routes>
                </Suspense>
              </CommonLayout>
            </RouteWatcher>
          </ProductProvider>
        </Is3DProvider>
      </BrowserRouter>
    </ProtectedApp>
  );
};

export default Application;
