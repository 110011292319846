import React from 'react';

import '@/features/AccountRetailer/components/StatisticComponents/Main/Review/review.sass';

import { ReviewProps } from '@/features/AccountRetailer/types/useStatistic';

import ratingIcon from '@/assets/icons/account/rating-icon.svg';

const Review: React.FC<ReviewProps> = ({ data }) => {
  return (
    <div className="review item mb-2">
      <div className="review-img">
        <img src={data.image} alt="Review" />
      </div>
      <div className="review-content">
        <div className="review-content__top">
          <div className="review-rating mb-1">
            <img src={ratingIcon} alt="Rating Icon" />
            <span>{data.stars}</span>
          </div>
          <span className="review-date">{data.created_at}</span>
        </div>
        <div className="review-text">{data.description}</div>
      </div>
    </div>
  );
};

export default Review;
