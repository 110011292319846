import UserCabinetMenu from '@/components/UserCabinet/UserCabinetMenu/UserCabinetMenu';
import UserOrders from '@/features/UserCabinet/Orders';

const UserCabinetOrders: React.FC = () => {
  return (
    <div className="user-cabinet">
      <UserCabinetMenu active="orders" />
      <UserOrders />
    </div>
  );
};

export default UserCabinetOrders;
