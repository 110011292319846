import { FormSpy } from 'react-final-form';

const FormError = () => (
  <FormSpy subscription={{ submitError: true }}>
    {({ submitError }) => {
      return (
        <div className="error-message__form">
          {submitError && (
            <div className="error-message__form">{submitError}</div>
          )}
        </div>
      );
    }}
  </FormSpy>
);

export default FormError;
