import { useEffect, useRef, useState } from 'react';
import { usePostProductSearchMutation } from '../services/productSearchSlice';
import {
  ResponseSearchData,
  UseProductSearch,
} from '../types/useProductSearch';

const useProductSearch = (): UseProductSearch => {
  const searchFormRef = useRef<HTMLDivElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState('');
  const [searchResultsData, setSearchResultsData] =
    useState<ResponseSearchData>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const [postProductSearch, { data, isLoading }] =
    usePostProductSearchMutation();

  useEffect(() => {
    if (searchText) {
      postProductSearch(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (data) {
      setSearchResultsData(data?.data);
    }
  }, [data]);

  return [
    {
      searchFormRef,
      searchContainerRef,
      searchText,
      showSearch,
      searchResultsData,
      isLoading,
    },
    {
      setSearchText,
      setShowSearch,
    },
  ];
};

export { useProductSearch };
