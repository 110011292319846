import { isArray } from 'lodash-es';
import { ProfileFormData, UpdateProfilePayload } from '../types';

const serverFormatter = ({
  id,
  type_of_organization,
  type_of_counterparty,
  ...props
}: ProfileFormData): UpdateProfilePayload => {
  return {
    id: Number(id),
    data: objectToFormData({
      ...props,
      type_of_organization: type_of_organization?.value,
      type_of_counterparty: type_of_counterparty?.value,
    }),
  };
};

const objectToFormData = (obj: ProfileFormData) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    if (isArray(value)) {
      value.forEach((file, index) => {
        formData.append(`profile[${key}][${index}][image]`, file);
      });
    } else {
      formData.append(`profile[${key}]`, value as any);
    }
  });
  return formData;
};

export { serverFormatter, objectToFormData };
