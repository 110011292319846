import { apiSlice } from '@/services/apiSlice';

import { GetGroupProductResponseData } from '@/features/GroupProduct/types';

export const groupProductApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupProducts: builder.query<GetGroupProductResponseData, void>({
      query: () => `/group_products`,
    }),
  }),
});

export const { useGetGroupProductsQuery } = groupProductApiSlice;
