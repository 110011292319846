import React, { BaseSyntheticEvent } from 'react';
import cn from 'classnames';

import SearchResult from '@/features/ProductSearch/components/SearchResult';
import ProductsRecommendations from '@/features/ProductsRecommendations';

import { ResponseSearchData } from '@/features/ProductSearch/types/useProductSearch';

type SearchFormProps = {
  searchContainerRef: React.RefObject<HTMLDivElement>;
  onBlurSearchForm: () => void;
  searchResultsData: ResponseSearchData;
};

const SearchContainer: React.FC<SearchFormProps> = ({
  searchContainerRef,
  onBlurSearchForm,
  searchResultsData,
}) => {
  const onClick = (event: BaseSyntheticEvent) => {
    if (!event.target.classList.contains('product-card-small__favorite')) {
      onBlurSearchForm();
    }
  };
  return (
    <>
      <div ref={searchContainerRef} className={cn('search-container')}>
        <div className={cn('backdrop-filter')}></div>
        <div className={cn('search-container__wrapper')}>
          <SearchResult
            onBlurSearchForm={onBlurSearchForm}
            searchResultsData={searchResultsData}
          />
          <div className={cn('search-recommendations')}>
            <ProductsRecommendations onProductClick={onClick} numberCards={3} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchContainer;
