import React from 'react';
import cn from 'classnames';
// import AdditionalFieldsForm from '@/components/Registration/AdditionalFieldsForm';

import { useAppDispatch } from '@/hooks';
import usePasswordRecovery from '../../hooks/usePasswordRecovery';

import { setCurrentAuthModal } from '@/features/Auth/states/authSlice';

import Modal from '@/layouts/Modal/Modal';

import ChangePassword from '../ChangePassword/ChangePassword';
import VerificationForm from '../Verification/Verification';
import PasswordRecoveryForm from '../../forms/PasswordRecoveryForm';

import { PASSWORD_RECOVERY_STEPS } from '../../types/passwordRecovery';
import { AuthFormModalType } from '@/features/Auth/types';

type PasswordRecoveryModalProps = {
  onClickLogin: () => void;
  onCloseModal: () => void;
};

import '@/components/ui/Select/select.sass';

import CheckmarkOutline from '@/assets/icons/checkmark--outline.svg';

const PasswordRecovery: React.FC<PasswordRecoveryModalProps> = ({
  onClickLogin,
  onCloseModal,
}) => {
  const [
    { isLoading },
    { phoneNumber, currentStep, initialValues },
    { onSubmit, onChangeStep },
  ] = usePasswordRecovery();

  const dispatch = useAppDispatch();

  return (
    <Modal
      onClose={
        currentStep === PASSWORD_RECOVERY_STEPS.SEND_PHONE
          ? () => dispatch(setCurrentAuthModal(AuthFormModalType.LOGIN))
          : currentStep === PASSWORD_RECOVERY_STEPS.VERIFY_CODE
            ? onChangeStep(PASSWORD_RECOVERY_STEPS.SEND_PHONE)
            : currentStep === PASSWORD_RECOVERY_STEPS.CHANGE_PASSWORD
              ? onChangeStep(PASSWORD_RECOVERY_STEPS.SUCCEESS)
              : onCloseModal
      }
      headerTitle="Восстановление пароля"
      textBtnClose="Назад"
    >
      <div className="password-recovery__form">
        {currentStep === PASSWORD_RECOVERY_STEPS.SEND_PHONE && (
          <>
            <PasswordRecoveryForm
              isLoading={isLoading}
              className={'registration__form form-primary'}
              initialValues={initialValues}
              onSubmit={onSubmit}
            />
            <div className="sub-text__2">
              Введите номер телефона, связанный с вашим аккаунтом. Мы отправим
              на него код для восстановления вашего пароля
            </div>
            <div>
              <button
                className="submit-btn btn-primary"
                type="submit"
                form="password-recovery-form"
              >
                Отправить код
              </button>
            </div>
          </>
        )}

        {currentStep === PASSWORD_RECOVERY_STEPS.VERIFY_CODE && (
          <VerificationForm
            next={onChangeStep(PASSWORD_RECOVERY_STEPS.CHANGE_PASSWORD)}
            phone={phoneNumber}
            verificationLink="password-recovery"
          />
        )}
        {currentStep === PASSWORD_RECOVERY_STEPS.CHANGE_PASSWORD && (
          <ChangePassword
            back={onChangeStep(PASSWORD_RECOVERY_STEPS.VERIFY_CODE)}
            next={onChangeStep(PASSWORD_RECOVERY_STEPS.SUCCEESS)}
            phone={phoneNumber}
          />
        )}
        {currentStep === PASSWORD_RECOVERY_STEPS.SUCCEESS && (
          <div className="password-recovery-success">
            <img src={CheckmarkOutline} alt="Checkmark Outline" />
            <span className={cn('sub-text')}>
              Поздравляем! Вы успешно сменили пароль
            </span>
            <div onClick={onClickLogin} className="submit-btn btn-primary">
              Закрыть
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PasswordRecovery;
