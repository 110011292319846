import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';

import FormError from '../components/FormError/ErrorMessage';
import { ChangePasswordFormProps } from '../types/changePassword';

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  className,
  initialValues,
  onSubmit,
  onGoback,
}) => {
  return (
    <FinalForm
      id="change-password-form"
      className={className}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <span onClick={onGoback} className="form-back">
        Назад
      </span>
      <div className="auth-modal-content__inputs-block">
        <FormTextField
          name="password"
          className="input"
          placeholder="Новый пароль"
          fieldProps={{ type: 'password' }}
        />
        <FormTextField
          name="password_confirmation"
          className="input"
          placeholder="Подтвердите пароль"
          fieldProps={{ type: 'password' }}
        />
        <FormError />
      </div>
    </FinalForm>
  );
};
export default ChangePasswordForm;
