import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { useProduct } from '@/contexts/ProductContext';

import ProductDetailsPanel from './components/ProductDetailsPanel';

// import ProductView from '@/features/ProductCard/components/ProductView';
import MemoizedProductView from '@/features/ProductCard/components/ProductView';
import ProductSelectedOptionsPanel from '@/features/ProductCard/components/ProductSelectedOptionsPanel';
import ProductReviewsPanel from '@/features/ProductCard/components/ProductReviewsPanel';
import ProductFeedbackPanel from '@/features/ProductCard/components/ProductFeedbackPanel';

import { PRODUCT_DETAILS_PANEL_MODE } from '@/features/ProductCard/constants';

import useProductCard from '@/features/ProductCard/hooks/useProductCard';

import './product-card.sass';

const ProductCard: React.FC = () => {
  const params = useParams<{ id?: string }>();
  const productId = params.id ? Number(params.id) : undefined;

  const { selectedProductKey, setSelectedProductKey } = useProduct();

  const [
    {
      showShareDropdown,
      isMoreInfoShown,
      isRating,
      productViewData,
      productDetails,
      productOptionSelect,
      productFeedback,
      productReviews,
    },
    {
      setShowShareDropdown,
      toggleMoreInfo,
      toggleFeedback,
      handleBack,
      productRefetch,
    },
  ] = useProductCard(Number(selectedProductKey || productId));

  return (
    <div className={cn('product-card-container container')}>
      <span
        className={cn('close-button')}
        onClick={() =>
          setSelectedProductKey ? setSelectedProductKey(null) : null
        }
      ></span>

      <MemoizedProductView
        productViewData={productViewData}
        handleBack={handleBack}
      />
      <div className="tools">
        <ProductDetailsPanel
          showShareDropdown={showShareDropdown}
          setShowShareDropdown={setShowShareDropdown}
          mode={PRODUCT_DETAILS_PANEL_MODE.VIEW}
          productDetails={productDetails}
          toggleMoreInfo={toggleMoreInfo}
          isMoreInfoShown={isMoreInfoShown}
          productShareUrl={`${window.location.origin}/products/${Number(selectedProductKey || productId)}`}
        />
        <ProductSelectedOptionsPanel
          product_id={Number(productDetails?.id)}
          productOptionSelect={productOptionSelect}
          productRefetch={productRefetch}
        />
        <ProductReviewsPanel productReviews={productReviews} />
      </div>
      <ProductFeedbackPanel
        productFeedback={productFeedback}
        isRating={isRating}
        toggleFeedback={toggleFeedback}
      />
    </div>
  );
};

export default ProductCard;
