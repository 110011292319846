import { apiSlice } from '@/services/apiSlice';
import {
  GetCategoriesResponseData,
  GetSubCategoriesResponseData,
} from '../types';

export const categoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<GetCategoriesResponseData, void>({
      query: () => '/categories',
    }),
    getSubCategories: builder.query<GetSubCategoriesResponseData, number>({
      query: (id) => `/categories/${id}/subcategories`,
    }),
    deleteGroupProduct: builder.mutation<void, number>({
      query: (id) => ({
        url: `/group_products/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  useDeleteGroupProductMutation,
} = categoriesApiSlice;
