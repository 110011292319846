import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutherizedUser, AuthFormModalType, AuthStates } from '../types';

const initialState: AuthStates = {
  token: undefined,
  user: undefined,
  currentAuthFormType: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentAuthModal: (
      state,
      action: PayloadAction<AuthFormModalType | undefined>,
    ) => {
      state.currentAuthFormType = action.payload;
    },
    userLoggedIn: (state, action: PayloadAction<Required<AutherizedUser>>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    userLoggedOut: (state) => {
      state.token = undefined;
      state.user = undefined;
    },
  },
});

export const { setCurrentAuthModal, userLoggedIn, userLoggedOut } =
  authSlice.actions;
export default authSlice.reducer;
