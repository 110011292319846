import { User } from '@/types/entities/user';
import { ErrorsMessage } from '@/types/forms';
import { BaseSyntheticEvent } from 'react';

export type RegisterUserPayload = {
  user: Omit<RegistrationFormData, 'politika' | 'oferta'> & {
    politika: string;
    oferta: string;
  };
};
export type UserRegistraionRequredTypes = {
  email?: string;
  password: string;
  phone: string;
};

export type RegistrationError = {
  data: ErrorsMessage;
  status: string;
};

export type RegisterUserResponseData = User | RegistrationError;

export enum REGISTER_USER_TYPE {
  CLIENT = 'client',
  RETAILER = 'retailer',
}

export type RegistrationFormData = {
  role_kind: REGISTER_USER_TYPE;
  full_name: string;
  phone: string;
  password: string;
  password_confirmation: string;
  politika: boolean;
  oferta: boolean;
};

export enum REGISTRATION_STEPS {
  STEP_1 = 0,
  STEP_2 = 1,
  STEP_3 = 2,
}

export type RegistrationFormProps = {
  isLoading: boolean;
  className: string;
  registerUserType: REGISTER_USER_TYPE;
  initialValues: RegistrationFormData;
  onSubmit: (data: RegistrationFormData) => Promise<any>;
  onOpenPolicy: (policyType: string) => (event: BaseSyntheticEvent) => void;
};
