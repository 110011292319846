import React, { startTransition } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import cn from 'classnames';

import { AccountMenuItemProps } from '@/types/menu';

const AccountMenuItem: React.FC<AccountMenuItemProps> = ({ item, arrow }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (() => {
    if (location.pathname === item.href) {
      return true;
    }

    if (location.pathname.startsWith(item.href)) {
      return location.pathname[item.href.length] === '/';
    }
    return false;
  })();

  const handleLinkClick = (page: string) => {
    startTransition(() => {
      navigate(page);
    });
  };

  return (
    <Link
      to={item.href}
      onClick={() => handleLinkClick(item.href)}
      className={cn('account-menu__item', {
        'is-active': isActive,
        'is-inactive': !item.isActive,
      })}
    >
      {item.icon}
      <span>{item.title}</span>
      <img src={arrow} alt="Arrow" />
    </Link>
  );
};

export default AccountMenuItem;
