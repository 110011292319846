// store.ts
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// Reducers
import { productsReducer } from './reducers/productsReducer';
import { orderReducer } from './reducers/orderReducer';

import { apiSlice } from '@/services/apiSlice';
import authSlice from '@/features/Auth/states/authSlice';
import cartSlice from '@/features/Cart/states/cartSlice';
import favoriteSlice from '@/features/ProductsFavorites/states/favoriteSlice';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  products: productsReducer,
  order: orderReducer,
  auth: authSlice,
  cart: cartSlice,
  favorite: favoriteSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
