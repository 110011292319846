import { apiSlice } from '@/services/apiSlice';
import {
  AddCartItemPayload,
  AddCartItemResponse,
  CartData,
  ChangeCartItemPayload,
  ChangeCartItemResponse,
  CreateOrderPayload,
  CreateOrderResponse,
  RemoveCartItemPayload,
  RemoveCartItemResponse,
} from '../types';

export const cartApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    onCreateOrder: builder.mutation<CreateOrderResponse, CreateOrderPayload>({
      query: (orderData) => ({
        url: '/orders',
        method: 'POST',
        body: orderData,
      }),
    }),
    onAddCartItem: builder.mutation<AddCartItemResponse, AddCartItemPayload>({
      query: (data) => ({
        url: '/cart_products',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Carts'],
    }),
    onRemoveCartItem: builder.mutation<
      RemoveCartItemResponse,
      RemoveCartItemPayload
    >({
      query: ({ id }) => ({
        url: `/cart_products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Carts'],
    }),
    onChangeCartItem: builder.mutation<
      ChangeCartItemResponse,
      ChangeCartItemPayload
    >({
      query: ({ id, data }) => ({
        url: `/cart_products/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Carts'],
    }),
    getCart: builder.query<CartData, void>({
      query: () => '/carts',
      providesTags: ['Carts'],
    }),
  }),
});

export const {
  useOnCreateOrderMutation,
  useOnAddCartItemMutation,
  useOnChangeCartItemMutation,
  useOnRemoveCartItemMutation,
  useGetCartQuery,
} = cartApiSlice;
