import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { useGlobalContext } from '@/contexts/GlobalContext';
import useAuthenticate from '@/hooks/useAuthenticate';
import useLogout from '@/features/Auth/hooks/useLogout';

import './profile-menu.sass';

const ProfileMenu: React.FC<{ showMenu: boolean; isMobile?: boolean }> = ({
  showMenu,
  isMobile,
}) => {
  const navigate = useNavigate();

  const [{ userRole }] = useGlobalContext();

  const [, { deleteAuthenticatedUser }] = useAuthenticate();

  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
    deleteAuthenticatedUser(() => {
      navigate('/');
    });
  };

  return (
    <div
      className={cn('profile-menu__wrapper', {
        show: showMenu,
        mobile: isMobile,
      })}
    >
      <div className={cn('profile-menu')}>
        <span className="backdrop-filter"></span>
        <a
          href={`/${userRole === 'client' ? 'user' : 'account'}`}
          className="profile-menu__item"
        >
          Личный кабинет
        </a>
        <span onClick={handleLogout} className="profile-menu__item">
          Выход
        </span>
      </div>
    </div>
  );
};

export default ProfileMenu;
