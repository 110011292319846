import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGlobalContext } from '@/contexts/GlobalContext';

import {
  useGetPavilionUsersQuery,
  useUpdatePavilionUsersMutation,
} from '@/features/PavilionBuilderLayout/services/pavilionBuilderLayoutSlice';

import { useGetGroupProductsQuery } from '@/features/GroupProduct/services/productSlice';

import {
  CurrentObject,
  DataLocalesKeyType,
  PavilionData,
  UsePavilionBuilderLayout,
} from '@/features/PavilionBuilderLayout/types/usePavilionBuilderLayout';

import {
  ExtendedExporterReturn,
  ManagementType,
} from '@/features/PavilionBuilderLayout/types';
import { ObjectItemPavilion } from '@/types/types';

// data
const dataLocalesObject: Record<DataLocalesKeyType, string> = {
  product: 'Продукт',
  shelving: 'Стеллаж',
};

const usePavilionUsers = (): UsePavilionBuilderLayout => {
  const [{ pavilionBuilder }] = useGlobalContext();

  const params = useParams<{ id?: string }>();
  const pavilionId = params.id ? Number(params.id) : undefined;

  // hooks
  const [isPavilionAvailable, setIsPavilionAvailable] =
    useState<boolean>(false);

  const [typeAddItem, setTypeAddItem] = useState<'Shelving' | 'Product'>(
    'Shelving',
  );

  // Показ сообщений, уведомлений
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>('');

  // Стеллажи
  const [shelves, setShelves] = useState<ObjectItemPavilion[] | null>(null);
  // Список объектов
  const [objectList, setObjectList] = useState<ObjectItemPavilion[] | null>(
    null,
  );

  // Выбранный объект на билдере
  const [currentObject, setCurrentObject] = useState<CurrentObject | null>(
    null,
  );

  // Выбранный элемент
  const [selectItemId, setSelectItemId] = useState<number | null>(null);
  // Выбранный стеллажг
  const [selectShelvingId, setSelectShelvingId] = useState<number | null>(null);
  // Выбранный продукт
  const [selectProductId, setSelectProductId] = useState<number | null>(null);
  // Тип управления
  const [typeManagement, setTypeManagement] = useState<ManagementType | null>(
    null,
  );
  // Полка, место в стеллаже
  const [selectPlace, setSelectPlace] = useState<number | null>(null);
  // hooks end

  // Fetch query
  const {
    isLoading: isLoadingPavilionData,
    data: pavilionData,
    refetch: refetchPavilionData,
  } = useGetPavilionUsersQuery(pavilionId || 0);
  const { data: groupProductData } = useGetGroupProductsQuery();

  // Mutation hook
  const [
    updatePavilionUsers,
    { isSuccess: pavilionUpdateSuccess, error: pavilionUpdateError },
  ] = useUpdatePavilionUsersMutation();

  const formattedPavilionData: PavilionData | null =
    pavilionData && pavilionData.data ? pavilionData.data.pavilion : null;

  // Methods
  const defaultView = useCallback(() => {
    if (pavilionBuilder) {
      pavilionBuilder.defaultView();
    }
  }, [pavilionBuilder]);

  const saveTemplatePavilion = (): void => {
    if (pavilionBuilder && pavilionBuilder.exporter && formattedPavilionData) {
      if (typeof pavilionData === 'object') {
        pavilionBuilder.exporter
          .getJSON(formattedPavilionData, true)
          .toFile('mapPavilion');
      }
    }
  };

  const updatePavilion = useCallback(
    (payload: PavilionData) => {
      console.log('payload');
      console.log(payload);
      const jsonBlob = new Blob([JSON.stringify([payload])], {
        type: 'application/json',
      });
      const jsonFile = new File([jsonBlob], 'fileTemplate.json', {
        type: 'application/json',
      });

      const formData = new FormData();
      formData.append('fileTemplate', jsonFile);

      updatePavilionUsers({
        id: payload.id,
        formData,
      })
        .unwrap()
        .then((response) => {
          if (response.data.messages) {
            setShowMessage(true);
            setNotificationMessage(response.data.messages.join('. '));
          }
        })
        .catch((error) => {
          console.error('Update failed', error);
        })
        .finally(() => refetchPavilionData());
    },
    [updatePavilionUsers],
  );

  const savePavilion = (): void => {
    if (
      pavilionBuilder &&
      pavilionBuilder.exporter &&
      formattedPavilionData !== null &&
      pavilionData
    ) {
      const pavilionDataModified = (
        pavilionBuilder.exporter.getJSON(
          formattedPavilionData,
          true,
        ) as ExtendedExporterReturn
      ).dataExport[0];

      pavilionDataModified.id = pavilionData.data.pavilion.id;
      updatePavilion(pavilionDataModified);
      refetchPavilionData();
      setTimeout(() => {
        setShowMessage(false);
        setNotificationMessage('');
      }, 3000);
    } else {
      console.error(`Павильон пустой, данных не обнаружено!`);
    }
  };

  // Methods end

  return [
    {
      isPavilionAvailable,
      isLoadingPavilionData,
      pavilionUpdateSuccess,
    },
    {
      groupProductData: groupProductData
        ? groupProductData.data.group_products
        : null,
      typeAddItem,
      showMessage,
      deleteConfirm,
      notificationMessage,
      shelves,
      objectList,
      currentObject,
      selectItemId,
      selectShelvingId,
      selectProductId,
      typeManagement,
      selectPlace,
      pavilionUpdateError,
    },
    {
      pavilionData: formattedPavilionData,
      dataLocalesObject,
    },
    {
      setIsPavilionAvailable,
      setTypeAddItem,
      defaultView,
      saveTemplatePavilion,
      savePavilion,
      setShowMessage,
      setDeleteConfirm,
      setNotificationMessage,
      setShelves,
      setObjectList,
      setCurrentObject,
      setSelectItemId,
      setSelectShelvingId,
      setSelectProductId,
      setTypeManagement,
      setSelectPlace,
    },
  ];
};

export default usePavilionUsers;
