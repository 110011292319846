import React from 'react';

import Button from '@/components/ui/Button/Button';
import Spinner from '@/components/Spinner/Spinner';

import { CommonStatsProps } from '@/features/AccountRetailer/types/useStatistic';

// import Wallet from '@/assets/icons/statistic/wallet.svg';
// import CashWithdrawal from '@/assets/icons/statistic/cash-withdrawal.svg';
import Money from '@/assets/icons/statistic/money.svg';

import './common-stats.sass';

const CommonStats: React.FC<CommonStatsProps> = ({
  ordersSum,
  isLoadingStatistic,
}) => {
  return (
    <div className="account__common-stats col-4">
      <div className="account__inner">
        <div className="mb-4">
          <div className="account-text account-text-l bold-text mb-1">
            {isLoadingStatistic && <Spinner />}
            {!isLoadingStatistic && `${ordersSum} руб.`}
          </div>
          {/*<p className="grey-text account-text-m bold-text">*/}
          {/*  Обновлено в 20:01*/}
          {/*</p>*/}
        </div>
        <div className="account__withdraw">
          {/*<div>*/}
          {/*  <p className="account-text account-text-m bold-text mb-1">*/}
          {/*    К выводу*/}
          {/*  </p>*/}
          {/*  <p className="account-text account-text-l bold-text">*/}
          {/*    656 338 руб.*/}
          {/*  </p>*/}
          {/*</div>*/}
          <div>
            <Button size="l" primary icon={Money}>
              Вывести
            </Button>
          </div>
        </div>
        <div className="account__finance mt-4">
          <p className="grey-text account-text-m bold-text">
            Финансовые отчёты
          </p>
          <div className="account__finance-table mt-3 pt-2">
            <div className="account__finance-table__row">
              <span className="account__finance-table__cell">
                Рейтинг доставки
              </span>
              <span className="account__finance-table__cell">99.67%</span>
            </div>
            <div className="account__finance-table__row">
              <span className="account__finance-table__cell">
                Заказов за неделю
              </span>
              <span className="account__finance-table__cell">-8%</span>
            </div>
            <div className="account__finance-table__row">
              <span className="account__finance-table__cell">
                Товаров продано
              </span>
              <span className="account__finance-table__cell">50 878</span>
            </div>
            <div className="account__finance-table__row">
              <span className="account__finance-table__cell">
                Отзывов покупателей
              </span>
              <span className="account__finance-table__cell">10 910</span>
            </div>
            <div className="account__finance-table__row">
              <span className="account__finance-table__cell">
                Вопросов покупателей
              </span>
              <span className="account__finance-table__cell">2 997</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonStats;
