import { queryStringToObject } from '@/utils/urls';
import { useLocation } from 'react-router-dom';
import { useGetSearchProductsQuery } from '../services/productSearchApi';

import { ProductsQueryParams } from '../types';

import { UseSearchResults } from '../types/useSearchResultsPage';

const useSearchResults = (): UseSearchResults => {
  const locationData = useLocation();

  const params = queryStringToObject(locationData.search);

  const searchText = params.search as string;

  const queryParams: ProductsQueryParams = {
    has_search: searchText,
  };

  const { data, isLoading } = useGetSearchProductsQuery(queryParams);

  const products = data?.data;
  const count = products?.length || 0;

  return [
    {
      isLoading,
    },
    {
      count,
      products,
    },
  ];
};
export default useSearchResults;
