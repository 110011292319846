import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { extractInitials } from '@/utils/profile';

import useAuthenticate from '@/hooks/useAuthenticate';

import ProductsRecommendations from '@/features/ProductsRecommendations';
import useLogout from '@/features/Auth/hooks/useLogout';

import Button from '@/components/ui/Button/Button';

import '@/components/AccountStandart/account-standart.sass';

import Hexagon from '@/assets/icons/catalogue/hexagon.svg';
import Coin from '@/assets/icons/coin.svg';

const AccountStandard: React.FC = () => {
  const navigate = useNavigate();

  const [, { getAuthUser, deleteAuthenticatedUser }] = useAuthenticate();
  const { logout } = useLogout();

  const user = getAuthUser();
  const handleLogout = () => {
    logout();
    deleteAuthenticatedUser(() => {
      navigate('/');
    });
  };

  return (
    <div className="account-container container">
      <div className="profile-container">
        <div className={cn('profile-main')}>
          <div className="profile-card page-block">
            <div className="avatar">
              {user?.full_name && extractInitials(user?.full_name)}
            </div>
            <div className="user-info__item">
              <div className="item">
                <p className="item-title">Имя</p>
                <p className="item-text">{user?.full_name}</p>
              </div>
              <div className="item">
                <p className="item-title">Дата рождения</p>
                <p className="item-text">-</p>
              </div>
            </div>
            <div className="user-info__item">
              <div className="item">
                <p className="item-title">Телефон</p>
                <p className="item-text">+{user?.phone}</p>
              </div>
              <div className="item">
                <p className="item-title">Почта</p>
                <p className="item-text">{user?.email}</p>
              </div>
            </div>
            <div className="user-info__item actions">
              <Button primary>Редактировать</Button>
              <Button onClick={handleLogout} secondary>
                Выйти
              </Button>
            </div>
          </div>

          <div className="points-card card page-block">
            <div className="card-title">Баллы</div>
            <div className="card-text">
              <img src={Coin} alt="Coin" />
              100
            </div>
          </div>
        </div>

        <div className="communications-card card page-block">
          <div className="card-title">
            Коммуникации <img src={Hexagon} alt="Hexagon" />
          </div>
          <span className={cn('card-text')}>
            Информация по заказу
            <span className={cn('card-text__subtext')}>Сегодня, 12:36</span>
          </span>
          <span className={cn('card-text')}>
            Сообщения от продавца
            <span className={cn('card-text__subtext')}>Вчера, 18:00</span>
          </span>
          <span className={cn('card-text')}>
            Новости от Capella
            <span className={cn('card-text__subtext')}>25.01.24</span>
          </span>
          <span className={cn('card-text')}>
            Новости от Capella
            <span className={cn('card-text__subtext')}>25.01.24</span>
          </span>
        </div>

        <div className="products">
          <ProductsRecommendations textTitle="Вам может понравиться" />
        </div>
      </div>
    </div>
  );
};

export default AccountStandard;
