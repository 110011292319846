const SLIDER_SETTINGS = {
  arrows: false,
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
};

export { SLIDER_SETTINGS };
