import React, { useState, useContext, useEffect, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

declare global {
  interface Window {
    Launcher3D: any;
    Loader3d: any;
    MiniMap: any;
  }
}

import {
  GlobalContextType,
  GlobalProviderProps,
  UserDataPayload,
} from '@/types/contexts';

const GlobalContext = React.createContext<GlobalContextType | undefined>(
  undefined,
);

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  // Local State
  const intervalLauncherInit = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );
  const intervalLoaderInit = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );
  const intervalPavilionBuilderInit = useRef<ReturnType<
    typeof setInterval
  > | null>(null);
  // const intervalMiniMapInit = useRef<ReturnType<typeof setInterval> | null>(
  //   null,
  // );
  // const intervalViewer3dInit = useRef<ReturnType<typeof setInterval> | null>(
  //   null,
  // );

  // Global States
  // User Data LocalStorage
  const userString = localStorage.getItem('user');
  const userToken = userString ? JSON.parse(userString).token : null;

  const [blockFooterActive, setBlockFooterActive] = useState<boolean>(false);
  const [loadModels, setLoadModels] = useState<boolean>(false);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const [launcher3d, setLauncher3d] = useState<any>(null);
  const [loader3d, setLoader3d] = useState<any>(null);
  const [pavilionBuilder, setPavilionBuilder] = useState<any>(null);

  const [modelsLoaded, setModelsLoaded] = useState<boolean>(false);
  const [is3dSceneReady, setIs3dSceneReady] = useState<boolean>(false);
  const [isFooterVisible, setIsFooterVisible] = useState<boolean>(false);
  const [isCreateCanvas, setIsCreateCanvas] = useState<boolean>(false);
  const [productViewer3d, setProductViewer3d] = useState<string | null>(null);
  const [viewer3d, setViewer3d] = useState<any>(null);
  const [myPavilion, setMyPavilion] = useState<any>(null);
  const [launcher3dRenderer, setLauncher3dRenderer] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | undefined>(
    Cookies.get('accessToken') || userToken,
  );

  const [userRole, setUserRole] = useState<
    'guest' | 'client' | 'retailer' | null
  >(accessToken ? jwtDecode<UserDataPayload>(accessToken).role : null);

  useEffect(() => {
    if (accessToken) {
      try {
        const decode = jwtDecode<UserDataPayload>(accessToken);
        decode ? setUserRole(decode.role) : null;
      } catch (err) {
        throw new Error(`Ошибка при декодировании JWT: ${err}`);
      }
    }
  }, [accessToken]);

  useEffect(() => {
    const checkLauncher3D = () => {
      if (window.Launcher3D) {
        setLauncher3d(window.Launcher3D);
        if (intervalLauncherInit.current) {
          clearInterval(intervalLauncherInit.current);
          intervalLauncherInit.current = null;
        }
      }
    };

    checkLauncher3D();
    if (!intervalLauncherInit.current) {
      intervalLauncherInit.current = setInterval(checkLauncher3D, 100);
    }
  }, []);

  // useEffect(() => {
  //   const checkMiniMap = () => {
  //     if (window.MiniMap) {
  //       setMiniMap(new window.MiniMap());
  //       if (intervalMiniMapInit.current) {
  //         clearInterval(intervalMiniMapInit.current);
  //         intervalMiniMapInit.current = null;
  //       }
  //     }
  //   };
  //
  //   checkMiniMap();
  //   if (!intervalMiniMapInit.current) {
  //     intervalMiniMapInit.current = setInterval(checkMiniMap, 100);
  //   }
  // }, []);

  useEffect(() => {
    const checkLoader3d = () => {
      if (window.Loader3d) {
        setLoader3d(window.Loader3d);
        if (intervalLoaderInit.current) {
          clearInterval(intervalLoaderInit.current);
          intervalLoaderInit.current = null;
        }
      }
    };

    checkLoader3d();
    if (!intervalLoaderInit.current) {
      intervalLoaderInit.current = setInterval(checkLoader3d, 100);
    }
  }, []);

  useEffect(() => {
    const checkPavilionBuilder = () => {
      if (window.PavilionBuilder) {
        setPavilionBuilder(new window.PavilionBuilder());

        if (intervalPavilionBuilderInit.current) {
          clearInterval(intervalPavilionBuilderInit.current);
          intervalPavilionBuilderInit.current = null;
        }
      }
    };

    checkPavilionBuilder();
    if (!intervalPavilionBuilderInit.current) {
      intervalPavilionBuilderInit.current = setInterval(
        checkPavilionBuilder,
        100,
      );
    }
  }, []);

  useEffect(() => {
    if (window.Viewer3D && !viewer3d) {
      // setViewer3d(new window.Viewer3D(null, 1000, 800).autoRotate(true));
      // setViewer3d(new window.Viewer3D(null, 800, 800).autoRotate(true));
      setViewer3d(new window.Viewer3D(null).autoRotate(true));
    }
  }, [window.Viewer3D]);

  // Удалить, если не понадобиться, 2 версия определения viewer, проверяет его каждые 100мс, как появляется, записывает
  // useEffect(() => {
  //   const checkViewer3D = () => {
  //     console.log('Checking for window.Viewer3D');
  //     if (window.Viewer3D && !viewer3d) {
  //       const viewer = new window.Viewer3D(null, 1000, 800).autoRotate(true);
  //       setViewer3d(viewer);
  //       if (intervalViewer3dInit.current) {
  //         clearInterval(intervalViewer3dInit.current);
  //         intervalViewer3dInit.current = null;
  //       }
  //     }
  //   };
  //
  //   checkViewer3D();
  //   if (!intervalViewer3dInit.current) {
  //     intervalViewer3dInit.current = setInterval(checkViewer3D, 1000); // Проверка каждую секунду
  //   }
  //
  //   // Очистка интервала при размонтировании компонента
  //   return () => {
  //     if (intervalViewer3dInit.current) {
  //       clearInterval(intervalViewer3dInit.current);
  //     }
  //   };
  // }, [viewer3d]);

  const value: GlobalContextType = [
    {
      blockFooterActive,
      userRole,
      modelsLoaded,
      is3dSceneReady,
      isFooterVisible,
      isCreateCanvas,
      productViewer3d,
      viewer3d,
      pavilionBuilder,
      accessToken,
      loader3d,
      launcher3d,
      myPavilion,
      launcher3dRenderer,
      loadModels,
      shouldRefetch,
    },
    {
      setBlockFooterActive,
      setUserRole,
      setModelsLoaded,
      setIs3dSceneReady,
      setIsFooterVisible,
      setIsCreateCanvas,
      setProductViewer3d,
      setViewer3d,
      setPavilionBuilder,
      setAccessToken,
      setLoader3d,
      setLauncher3d,
      setMyPavilion,
      setLauncher3dRenderer,
      setLoadModels,
      setShouldRefetch,
    },
  ];

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

function useGlobalContext() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
}

export { GlobalContext, GlobalProvider, useGlobalContext };
