import React from 'react';
import cn from 'classnames';

import './modal.sass';

type ModalProps = {
  children: string | React.ReactNode | React.ReactNode[];
  textBtnClose?: string | null;
  onClose?: () => void;
  theme?: string;
  headerTitle?: string;
  size?: string;
  backdropFilter?: boolean;
  overlay?: boolean;
  overlaySecondary?: boolean;
  classNames?: string;
};

const Modal: React.FC<ModalProps> = ({
  children,
  textBtnClose,
  onClose,
  theme,
  headerTitle,
  size,
  backdropFilter,
  overlay,
  overlaySecondary,
  classNames,
}) => {
  return (
    <div className={cn('modal-wrapper', classNames)}>
      {overlay && <span className={cn('overlay')}></span>}
      {overlaySecondary && <span className={cn('overlay-secondary')}></span>}
      <div className={cn('modal', theme, { [`size-${size}`]: size })}>
        {backdropFilter && <div className="backdrop-filter"></div>}
        {onClose && (
          <div className={cn('modal-header')}>
            <h2>{headerTitle}</h2>
            <div
              className={cn({
                'close-btn': !textBtnClose,
                'close-btn__text': textBtnClose,
              })}
              onClick={onClose}
            >
              {textBtnClose}
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
