import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';

import { useGlobalContext } from '@/contexts/GlobalContext';

import { UseAuthenticate } from '@/types/hooks/UseAuthenticate';

import {
  setCurrentAuthModal,
  userLoggedIn,
  userLoggedOut,
} from '@/features/Auth/states/authSlice';
import { RootState } from '@/store/store';

import { User } from '@/types/entities/user';

import { useAppDispatch } from '.';

const useAuthenticate = (): UseAuthenticate => {
  const [, { setAccessToken }] = useGlobalContext();
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const setAuthenticatedUser = (user: User) => {
    const name = 'accessToken';
    const value = user.token;
    const days = 90;
    let expires = '';
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();

    document.cookie = name + '=' + value + expires + '; path=/';
    const { full_name, phone, email, token } = user;

    localStorage.setItem(
      'user',
      JSON.stringify({ full_name, phone, email, token }),
    );
    if (token) {
      dispatch(userLoggedIn({ token, user }));
      dispatch(setCurrentAuthModal(undefined));
      setAccessToken(token);
    }
  };

  const getAccessToken = () => {
    const getCookie = (name: string): string | null => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
      return null;
    };
    return getCookie('accessToken');
  };

  const getAuthUser = () => {
    const localPersistData = localStorage.getItem('user');
    if (localPersistData) {
      const persistUser = JSON.parse(localPersistData);
      if (!user?.token)
        dispatch(userLoggedIn({ token: persistUser.token, user: persistUser }));
      return persistUser;
    }
    return user;
  };

  const isAuthenticated = () =>
    !isEmpty(getAccessToken()) && !!localStorage.getItem('user');

  const deleteAuthenticatedUser = (onLogoutSuccess: () => void) => {
    dispatch(userLoggedOut());
    onLogoutSuccess();
  };

  return [
    {
      isAuthenticated: isAuthenticated(),
    },
    {
      setAuthenticatedUser,
      getAuthUser,
      deleteAuthenticatedUser,
    },
  ];
};

export default useAuthenticate;
