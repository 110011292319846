import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useAppDispatch } from '@/hooks';

import { useToggleFavoriteStatusMutation } from '@/features/ProductCardSmall/services/productCardSmallSlice';
import { setGlobalFavoriteStates } from '@/features/ProductsFavorites/states/favoriteSlice';

import { UseProductCardSmall } from '../types/useProductCardSmall';

const useProductCardSmall = (): UseProductCardSmall => {
  const [isFavoriteHover, setIsFavoriteHover] = useState(false);
  const [toggleFavoriteStatus] = useToggleFavoriteStatusMutation();
  const dispatch = useAppDispatch();

  const { count_total: favoriteCount } = useSelector(
    (state: RootState) => state.favorite,
  );

  const toggleFavorite = async (
    event: React.MouseEvent<HTMLElement>,
    productId: number,
    isFavorite: boolean,
  ): Promise<void> => {
    event.preventDefault();
    try {
      await toggleFavoriteStatus({ productId }).unwrap();
      dispatch(
        setGlobalFavoriteStates({
          count_total: isFavorite ? favoriteCount - 1 : favoriteCount + 1,
        }),
      );
    } catch (error) {
      console.error('Failed to toggle favorite status:', error);
    }
  };

  return [
    {
      isFavoriteHover,
    },
    {
      setIsFavoriteHover,
      toggleFavorite,
    },
  ];
};

export { useProductCardSmall };
