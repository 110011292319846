import React from 'react';
import cn from 'classnames';

import ProductCardSmall from '@/features/ProductCardSmall';
import Spinner from '@/components/Spinner/Spinner';

import { useProductsRecommendations } from '@/features/ProductsRecommendations/hooks/useProductsRecommendations';
import { ProductRecommendationsProps } from '@/features/ProductsRecommendations/types';

import './products-recommendations.sass';

const ProductsRecommendations: React.FC<ProductRecommendationsProps> = ({
  onProductClick,
  numberCards,
  textTitle,
}) => {
  const { productsData, isLoading } = useProductsRecommendations();

  const displayedProducts = numberCards
    ? productsData.slice(0, numberCards)
    : productsData;

  return (
    <div className={cn('products-recommendations')}>
      <p className={cn('block-title')}>
        {textTitle ? textTitle : 'Рекомендации'}
      </p>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="products-list-container">
          {displayedProducts.map((product) => (
            <ProductCardSmall
              key={`${product.name}${product.id}`}
              product={product}
              onProductClick={onProductClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductsRecommendations;
