import { useRef, useState, ChangeEvent, DragEvent, useEffect } from 'react';
import { FormApi } from 'final-form';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { get } from 'lodash-es';

import { useGlobalContext } from '@/contexts/GlobalContext';

import {
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  useDeleteGroupProductMutation,
} from '@/features/CatalogueModal/services/categoriesApi';

import {
  useCreateClusterMutation,
  useGetClusterQuery,
  useUpdateClusterMutation,
  useCreateProductModelMutation,
} from '@/features/AccountRetailer/services/retailerClustersManagementApi';

import { useGetGroupProductsQuery } from '@/features/GroupProduct/services/productSlice';

import { ErrorsMessage } from '@/types/forms';

import {
  Cluster,
  ClusterFormData,
  CharacteristicKey,
  CharacteristicsFieldsState,
  UseClustersManagement,
} from '@/features/AccountRetailer/types/useClustersProductsManagement';

import { Category } from '@/types/entities/category';
import { Notification } from '@/features/AccountRetailer/types';
import { GroupProductsData } from '@/features/GroupProduct/types';

const useClustersManagement = (
  clusterId: number | null,
): UseClustersManagement => {
  const [, { setShouldRefetch }] = useGlobalContext();

  const productModelRef = useRef<HTMLInputElement>(null);

  const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false);
  const [productModalFile, setProductModalFile] = useState<File | null>(null);

  const [selectCategory, setSelectCategory] = useState<number | null>(null);
  const [selectCategory2, setSelectCategory2] = useState<number | null>(null);

  const [notification, setNotification] = useState<Notification | null>(null);

  const [createCluster] = useCreateClusterMutation();

  const [initialValues, setInitialValues] = useState<Cluster>({
    group_product: {
      name: null,
      alias_3d: null,
      name_color: null,
      name_size: null,
      category_1: null,
      category_2: null,
      category_3: null,
      group_product_colors_attributes: [],
      group_product_sizes_attributes: [],
    },
  });

  const [updateCluster] = useUpdateClusterMutation();
  const {
    isLoading,
    data: clustersData,
    refetch: refetchClustersData,
  } = useGetGroupProductsQuery();

  const {
    data: clusterData,
    isLoading: isLoadingCluster,
    refetch: refetchClusterData,
  } = useGetClusterQuery(clusterId ?? skipToken, {
    skip: clusterId == null,
  });
  const { isLoading: isLoadingCategories, data: categoriesData } =
    useGetCategoriesQuery();

  const { data: subCategoriesData2 } = useGetSubCategoriesQuery(
    selectCategory ?? skipToken,
    {
      skip: !selectCategory,
    },
  );

  const { data: subCategoriesData3 } = useGetSubCategoriesQuery(
    selectCategory2 ?? skipToken,
    {
      skip: !selectCategory2,
    },
  );

  const [createProductModel] = useCreateProductModelMutation();
  const [deleteGroupProduct] = useDeleteGroupProductMutation();

  const clusters: GroupProductsData[] =
    clustersData && clustersData.data?.group_products
      ? clustersData.data?.group_products
      : [];
  const categories: Category[] =
    categoriesData && categoriesData.data ? categoriesData.data.categories : [];

  const categoriesOptions = categories.map((category) => ({
    id: category.id,
    value: category.id,
    label: category.name,
  }));

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showUploadFile, setShowUploadFile] = useState<boolean>(false);
  const [showNameActionComponent, setShowNameActionComponent] = useState<
    'new' | 'edit' | 'view'
  >('view');
  const [showContextMenu3D, setShowContextMenu3D] = useState<boolean>(false);

  const [characteristicFields, setCharacteristicFields] =
    useState<CharacteristicsFieldsState>({
      characteristics_1: [],
      characteristics_2: [],
    });

  const [characteristicFieldCount, setCharacteristicFieldCount] = useState({
    characteristics_1: 0,
    characteristics_2: 0,
  });

  const addCharacteristicField = (characteristic: CharacteristicKey) => {
    setCharacteristicFields((prevFields) => ({
      ...prevFields,
      [characteristic]: [
        ...prevFields[characteristic],
        { id: characteristicFieldCount[characteristic], name: '' },
      ],
    }));

    setCharacteristicFieldCount((prevCount) => ({
      ...prevCount,
      [characteristic]: prevCount[characteristic] + 1,
    }));
  };

  const removeCharacteristicField = (
    characteristic: CharacteristicKey,
    fieldId: number,
  ) => {
    setCharacteristicFields((prevState) => ({
      ...prevState,
      [characteristic]: prevState[characteristic].filter(
        (field) => field.id !== fieldId,
      ),
    }));
  };

  const resetForm = () => {
    setInitialValues({
      group_product: {
        name: null,
        alias_3d: null,
        name_color: null,
        name_size: null,
        category_1: null,
        category_2: null,
        category_3: null,
        group_product_colors_attributes: [],
        group_product_sizes_attributes: [],
      },
    });
    setProductModalFile(null);
  };

  const onSubmit = async (data: ClusterFormData, form: FormApi) => {
    setOnSubmitLoading(true);
    try {
      const formData = new FormData();
      // Обертываем данные в 'group_product'
      formData.append('group_product[name]', data.group_product.name || '');
      formData.append(
        'group_product[name_color]',
        data.group_product.name_color || '',
      );
      formData.append(
        'group_product[name_size]',
        data.group_product.name_size || '',
      );

      data.group_product.category_3 &&
        formData.append(
          `group_product[category_ids][]`,
          String(data.group_product.category_3.id),
        );

      // Добавляем атрибуты цветов и размеров
      data.group_product.group_product_colors_attributes.forEach(
        (attribute, index) => {
          formData.append(
            `group_product[group_product_colors_attributes][${index}][name]`,
            attribute.name,
          );
          if (attribute.id) {
            formData.append(
              `group_product[group_product_colors_attributes][${index}][id]`,
              attribute.id.toString(),
            );
          }
        },
      );

      data.group_product.group_product_sizes_attributes.forEach(
        (attribute, index) => {
          formData.append(
            `group_product[group_product_sizes_attributes][${index}][name]`,
            attribute.name,
          );
          if (attribute.id) {
            formData.append(
              `group_product[group_product_sizes_attributes][${index}][id]`,
              attribute.id.toString(),
            );
          }
        },
      );

      if (clusterId) {
        // Обновление кластера
        await updateCluster({ id: clusterId, formData }).unwrap();

        const productModelFormData = new FormData();

        productModelFormData.append('group_product_id', String(clusterId));

        if (productModalFile) {
          productModelFormData.append('file', productModalFile);
          await createProductModel(productModelFormData).unwrap();
        }

        setNotification({
          messages: 'Кластер успешно обновлен!',
          type: 'success',
        });
      } else {
        // Создание нового кластера
        const responseCluster = await createCluster(formData).unwrap();

        const productModelFormData = new FormData();

        productModelFormData.append(
          'group_product_id',
          String(responseCluster.data.id),
        );

        if (productModalFile) {
          productModelFormData.append('file', productModalFile);
          await createProductModel(productModelFormData).unwrap();
        }

        setNotification({
          messages: 'Кластер успешно создан!',
          type: 'success',
        });
        form.reset();
      }
    } catch (error) {
      console.error(error);
      const errors = get(error, 'data.errors') as unknown as ErrorsMessage;
      return errors;
    }
    refetchClustersData();
    refetchClusterData();

    resetForm();
    setShouldRefetch(true);
    setOnSubmitLoading(false);
  };

  const handleDeleteItem = async (id: number) => {
    try {
      await deleteGroupProduct(id).unwrap();
      refetchClustersData();
    } catch (error) {
      setNotification({
        messages: 'Что-то пошло не так, повторите попытку позже!',
        type: 'error',
      });
    }
  };

  const onFileSelect = (file: File) => {
    // Обработать логику загрузки файла
    console.log(file);
  };

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onFileSelect(e.target.files[0]);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (clusterId) {
      setShowNameActionComponent('edit');
    }
  }, [clusterId, setShowNameActionComponent]);

  useEffect(() => {
    refetchClustersData();
  }, []);

  useEffect(() => {
    if (clusterData && clusterId) {
      const colorsAttributes = clusterData.data.group_product_colors.map(
        (color) => ({
          id: color.id,
          name: color.name,
        }),
      );

      const sizesAttributes = clusterData.data.group_product_sizes.map(
        (size) => ({
          id: size.id,
          name: size.name,
        }),
      );

      const category_1 = clusterData.data.categories.find(
        (item) =>
          item.id === Number(clusterData.data.parent_parent_category_id),
      );

      const category_2 = clusterData.data.subcategories.find(
        (item) => item.id === Number(clusterData.data.parent_category_id),
      );

      const category_3 = clusterData.data.subsubcategories.find(
        (item) => item.id === Number(clusterData.data.category_id),
      );

      category_2 && setSelectCategory(category_2.id);
      category_3 && setSelectCategory2(category_3.id);
      setInitialValues({
        group_product: {
          name: clusterData.data.name,
          alias_3d: clusterData.data.alias_3d,
          name_color: clusterData.data.name_color,
          name_size: clusterData.data.name_size,
          category_1: category_1
            ? {
                id: Number(category_1.id),
                value: Number(category_1.id),
                label: category_1.name,
              }
            : null,
          category_2: category_2
            ? {
                id: Number(category_2.id),
                value: Number(category_2.id),
                label: category_2.name,
              }
            : null,
          category_3: category_3
            ? {
                id: Number(category_3.id),
                value: Number(category_3.id),
                label: category_3.name,
              }
            : null,
          group_product_colors_attributes: colorsAttributes,
          group_product_sizes_attributes: sizesAttributes,
        },
        id: clusterData.data.id,
      });
      setCharacteristicFields({
        characteristics_1: colorsAttributes,
        characteristics_2: sizesAttributes,
      });
    }
  }, [
    clusterData,
    clusterId,
    selectCategory,
    subCategoriesData2,
    subCategoriesData3,
  ]);

  return [
    {
      onSubmitLoading,
      isLoading,
      isLoadingCategories,
      isLoadingCluster,
      showUploadFile,
      showContextMenu3D,
      showNameActionComponent,
    },
    {
      notification,
      selectCategory,
      selectCategory2,
      fileInputRef,
      initialValues,
      clusters,
      categoriesOptions,
      subCategoriesData2: subCategoriesData2
        ? subCategoriesData2.data.subcategories.map((subCategory) => ({
            ...subCategory,
            key: subCategory.id,
            value: subCategory.id,
            label: subCategory.name,
          }))
        : [],
      subCategoriesData3: subCategoriesData3
        ? subCategoriesData3.data.subcategories.map((subCategory) => ({
            ...subCategory,
            key: subCategory.id,
            value: subCategory.id,
            label: subCategory.name,
          }))
        : [],
      characteristicFields,
      productModelRef,
    },
    {
      resetForm,
      setOnSubmitLoading,
      setProductModalFile,
      setNotification,
      setSelectCategory,
      setSelectCategory2,
      setShowUploadFile,
      setShowNameActionComponent,
      setShowContextMenu3D,
      handleFileInputClick,
      handleFileChange,
      handleDrop,
      handleDragOver,
      addCharacteristicField,
      removeCharacteristicField,
      onSubmit,
      handleDeleteItem,
    },
  ];
};

export default useClustersManagement;
