import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { setCurrentAuthModal } from '@/features/Auth/states/authSlice';

import { RootState } from '@/store/store';

import { useAppDispatch } from '@/hooks';
import useAuthenticate from '@/hooks/useAuthenticate';

import CountItems from '@/components/CountItems/CountItems';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu';

import { AuthFormModalType } from '@/features/Auth/types';

import './header-nav.sass';

import userIcon from '@/assets/icons/header/user.svg';
import heartIcon from '@/assets/icons/product/favorite.svg';
import cartIcon from '@/assets/icons/header/cart.svg';

const HeaderNav: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const [{ isAuthenticated }, { getAuthUser }] = useAuthenticate();

  const user = getAuthUser();
  const dispatch = useAppDispatch();

  const { count_total: cartCountTotal } = useSelector(
    (state: RootState) => state.cart,
  );

  const { count_total: favoriteCount } = useSelector(
    (state: RootState) => state.favorite,
  );

  const onOpenLoginModal = () => {
    dispatch(setCurrentAuthModal(AuthFormModalType.LOGIN));
  };

  return (
    <div className={cn('header-nav')}>
      {isAuthenticated ? (
        <div
          className={cn('header-nav__element')}
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
        >
          <div className={'header-nav__text account-wrapper'}>
            <span className={cn('header-nav__text-icon')}>
              {user?.full_name[0]}
            </span>
            {user?.full_name}
          </div>

          <ProfileMenu showMenu={showMenu} />
        </div>
      ) : (
        <div
          className="header-nav__element is-login"
          onClick={onOpenLoginModal}
        >
          <img src={userIcon} alt="Пользователь" />
          <span className={'header-nav__text'}>Аккаунт</span>
        </div>
      )}
      <Link to="/favorites" className={'header-nav__element'}>
        <div className={cn('icon-wrapper')}>
          <img src={heartIcon} alt="Favorites" />
          {favoriteCount > 0 && <CountItems number={favoriteCount} />}
        </div>
        <span className={'header-nav__text'}>Избранное</span>
      </Link>
      <Link to="/cart" className={'header-nav__element'}>
        <div className={cn('icon-wrapper')}>
          <img src={cartIcon} alt="Cart" />
          {cartCountTotal > 0 && <CountItems number={cartCountTotal} />}
        </div>
        <span className={'header-nav__text'}>Корзина</span>
      </Link>
    </div>
  );
};

export default HeaderNav;
