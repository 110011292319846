import React from 'react';
import cn from 'classnames';
import { UploadFileProductProps } from '@/features/AccountRetailer/types/productsList';

const UploadFile: React.FC<UploadFileProductProps> = ({
  fileInputRef,
  handleFileInputClick,
  handleFileChange,
  handleDrop,
  handleDragOver,
}) => {
  return (
    <div className={cn('upload-file-modal')}>
      <p className={cn('upload-file-modal__text-description')}>
        Далеко-далеко за словесными горами в стране гласных и согласных живут
        рыбные тексты. Вдали от всех живут они в буквенных домах на берегу
        Семантика большого языкового океана. Маленький ручеек Даль журчит по
        всей стране и обеспечивает ее всеми необходимыми правилами.
      </p>

      <div
        className="upload-file-modal__input-add-file"
        onClick={handleFileInputClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <span className={cn('add-file')}></span>
        Перенесите файл сюда или кликните для выбора
      </div>
    </div>
  );
};

export default UploadFile;
