import Button from '@/components/ui/Button/Button';
import copyIcon from '@/assets/icons/copy.svg';

const OrderDetails = () => {
  return (
    <div className="user-cabinet__order">
      <div className="user-cabinet__order__details">
        <div className="mb-3">
          <div className="d-flex align-items-center gap-2">
            <div className="user-cabinet__order__details__title">
              Заказ № 51575437-0033
            </div>
            <Button size="" icon={copyIcon} className="copy-button"></Button>
          </div>

          <div className="text-title">от 26 декабря 2023</div>
        </div>

        <div className="user-cabinet__order__details__info">
          <div>
            <div className="mb-3">
              <div className="text-title">Адрес доставки</div>
              улица Пушкина-Колотушкина, дом 33/1, квартира 13
            </div>

            <div className="text-title">Получатель</div>
            <div>Райан Флетчер</div>
            <div>GosLing@mail.ru</div>
            <div>+7 918 954 53 08</div>
          </div>

          <div>
            <div className="mb-3">
              <div className="text-title">Способ оплаты</div>
              Картой онлайн MasterCard **3337
            </div>

            <div className="user-cabinet__order__details__info__price-block">
              <div className="user-cabinet__order__details__info__price-block__item">
                <div className="text-title">Сумма заказа без скидки</div>
                <div>13 796 ₽</div>
              </div>
              <div className="user-cabinet__order__details__info__price-block__item">
                <div className="text-title">Скидка</div>
                <div>2000 ₽</div>
              </div>
              <div className="user-cabinet__order__details__info__price-block__item">
                <div className="text-title">Доставка</div>
                <div>Бесплатно</div>
              </div>
              <div className="user-cabinet__order__details__info__price-block__item">
                <div className="text-title">Итоговая сумма</div>
                <div>11 796 ₽</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user-cabinet__order__statuses">
        <div className="order-status">Оплачено</div>
        <div className="d-flex flex-column gap-3 mt-5 align-items-end">
          <Button className="order-action-button" size={''}>
            Электронный чек
          </Button>
          <Button className="order-action-button" size={''}>
            Вернуть товары
          </Button>
          <Button className="order-action-button cancel" size={''}>
            Отменить заказ
          </Button>
        </div>
      </div>
    </div>
  );
};
export default OrderDetails;
