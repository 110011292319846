import { useGetProductsRecommendationsQuery } from '../services/productsRecommendationsApi';
import { Product } from '@/types/entities/product';
import { UseProductsRecommendations } from '@/features/ProductsRecommendations/types/useProductsRecommendations';

const useProductsRecommendations = (): UseProductsRecommendations => {
  const { data, isLoading } = useGetProductsRecommendationsQuery();
  const productsData: Product[] = data?.data.recommendations || [];

  return {
    productsData,
    isLoading,
  };
};

export { useProductsRecommendations };
