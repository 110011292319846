import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CategoriesList } from '../types';

import CatalogueItem from './CatalogueItem';

type CatalogueListProps = Omit<CategoriesList, 'parentCategory'>;
const CatalogueList: React.FC<CatalogueListProps> = ({
  categories,
  selectedCategory,
  onClick,
  onEnterCategory,
  onLeaveCategory,
}) => {
  return (
    <Scrollbars>
      <div className="catalogue-list">
        {categories?.map((category) => (
          <CatalogueItem
            isSelected={selectedCategory?.id === category.id}
            key={category.id}
            img={category.icon}
            title={category.name}
            text={category.description ? category.description : ''}
            onClick={onClick(category, 'MAIN_CATEGORY')}
            onMouseEnter={onEnterCategory(category, 'MAIN_CATEGORY')}
            onMouseLeave={onLeaveCategory}
          />
        ))}
      </div>
    </Scrollbars>
  );
};
export default CatalogueList;
