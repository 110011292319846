import { mustBePresent } from '@/validations';
import { FieldValidator } from 'final-form';
import { isUndefined } from 'lodash-es';

const composeValidations =
  (...validators: FieldValidator<any>[]) =>
  (...args: any[]) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    validators.reduce((error, validator) => error || validator(...args), null);

function createValidationFunction(
  required: boolean,
  validate: FieldValidator<any> | undefined,
): FieldValidator<any> | undefined {
  if (required && validate) {
    return composeValidations(mustBePresent, validate);
  }

  if (required && !validate) {
    return mustBePresent;
  }

  if (!required && validate) {
    return composeValidations((value: any, ...args) => {
      if (!isUndefined(value)) {
        return validate(value, ...args);
      }

      return undefined;
    });
  }

  return undefined;
}

export { composeValidations, createValidationFunction };
