import React from 'react';
import cn from 'classnames';

import {
  ShareDropdownMessenger,
  ShareDropdownProps,
} from '@/features/ProductCard/types';

import './share-dropdown.sass';

import Share from '@/assets/icons/product/share.svg';

const ShareDropdown: React.FC<ShareDropdownProps> = ({
  url,
  showShareDropdown,
  setShowShareDropdown,
}) => {
  const messengers: ShareDropdownMessenger[] = [
    {
      name: 'WhatsApp',
      link: `https://wa.me/?text=${encodeURIComponent(url)}`,
    },
    {
      name: 'Telegram',
      link: `https://t.me/share/url?url=${encodeURIComponent(url)}`,
    },
  ];

  const copyToClipboard = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.writeText(url);
        (e.target as HTMLButtonElement).innerText = 'Ссылка скопирована';

        setTimeout(() => {
          (e.target as HTMLButtonElement).innerText = 'Копировать ссылку';
        }, 2000);
      } catch (error) {
        console.error('Ошибка копирования:', error);
      }
    }
  };

  return (
    <div className={cn('share-dropdown')}>
      <span
        className={cn('share-dropdown__title')}
        onClick={() => setShowShareDropdown(true)}
      >
        Поделиться
        <img src={Share} alt="Поделиться" />
      </span>
      <div
        className={cn('share-dropdown__link-list-container', {
          show: showShareDropdown,
        })}
        onMouseLeave={() => setShowShareDropdown(false)}
      >
        <ul className={cn('share-dropdown__link-list')}>
          {messengers.map((messenger, index) => (
            <li key={index}>
              <a
                href={messenger.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {messenger.name}
              </a>
            </li>
          ))}
          <li>
            <span onClick={copyToClipboard}>Копировать ссылку</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ShareDropdown;
