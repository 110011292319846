import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useGlobalContext } from '@/contexts/GlobalContext';

import { UserRole } from '@/types/routeTypes';

interface ProtectedRouteProps {
  path?: string;
  element: React.ReactElement;
  allowedRoles: UserRole[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  allowedRoles,
}) => {
  const [{ userRole }] = useGlobalContext();
  const location = useLocation();

  const accessGranted = allowedRoles.includes(userRole);

  return !accessGranted ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    element
  );
};

export default ProtectedRoute;
