import React from 'react';

import arrowIcon from '@/assets/icons/catalogue/arrow_right.svg';
import { CategoriesListItem } from '../types';

type CatalogueItemProps = CategoriesListItem;

const CatalogueItem: React.FC<CatalogueItemProps> = ({
  img,
  title,
  text,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      className={`catalogue-item  ${
        isSelected ? 'list-item-selected' : 'list-item'
      }`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="catalogue-item__img">
        <img src={img} alt="Category Icon" />
      </div>
      <div className="catalogue-item__right">
        <div>
          <h4 className="catalogue-item__heading">{title}</h4>
          <p className="catalogue-item__textList">{text}</p>
        </div>
        <div className="catalogue-item__icon list-item-icon">
          <img src={arrowIcon} alt="Arrow" />
        </div>
      </div>
    </div>
  );
};
export default CatalogueItem;
