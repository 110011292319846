import React, { CSSProperties } from 'react';
import cn from 'classnames';

import Spinner from '@/components/Spinner/Spinner';

import '@/components/ui/Button/button.sass';

type ButtonProps = {
  children?: any;
  disabled?: boolean;
  icon?: string;
  load?: boolean;
  size?: string;
  darkBorder?: boolean;
  primary?: boolean;
  secondary?: boolean;
  grey?: boolean;
  white?: boolean;
  border?: boolean;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  form?: string;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const Button = ({
  children,
  icon,
  load,
  disabled = false,
  primary = false,
  secondary = false,
  grey = false,
  white,
  border = false,
  size,
  className = '',
  type,
  form,
  style = undefined,
  onClick,
}: ButtonProps) => {
  const classes = cn(className, 'theme-btn', size && `size-${size}`, {
    primary,
    secondary,
    grey,
    white,
    border,
  });

  return (
    <>
      <button
        onClick={onClick}
        className={classes}
        disabled={disabled}
        type={type}
        form={form}
        style={style}
      >
        {icon && <img src={icon} alt="Button icon" />}
        <span className={cn('button-children')}>
          {children}
          {load && <Spinner />}
        </span>
      </button>
    </>
  );
};

export default Button;
