import { apiSlice } from '@/services/apiSlice';
import { GetProductsResponseData, ProductsQueryParams } from '../types';

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<GetProductsResponseData, ProductsQueryParams>({
      query: (arg) => {
        const { page, per_page, cat_id } = arg;
        return {
          url: '/products',
          params: { page, per_page, cat_id },
        };
      },
    }),
  }),
});

export const { useGetProductsQuery } = productsApiSlice;
