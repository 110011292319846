import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';
import { phoneNumberMask } from '@/utils/numbers';

import { PasswordRecoveryFormProps } from '../types/passwordRecovery';

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({
  className,
  initialValues,
  onSubmit,
}) => {
  return (
    <FinalForm
      id="password-recovery-form"
      className={className}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <div className="auth-modal-content__inputs-block">
        <FormTextField
          name="phone"
          className="input"
          placeholder="Телефон"
          fieldProps={{ formatOnBlur: true, parse: phoneNumberMask }}
        />
      </div>
    </FinalForm>
  );
};
export default PasswordRecoveryForm;
