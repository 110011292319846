import { useMemo } from 'react';
import { Field as FinalFormField } from 'react-final-form';
import cn from 'classnames';
import type { TextFieldProps } from '@/types/forms';
import { createValidationFunction } from '@/utils/validations';

import MessageInfoPopup from '@/components/MessageInfoPopup/MessageInfoPopup';

import TrashIcon from '@/assets/icons/trash-icon.svg';

const FormTextField = <T,>({
  showErrorInline = true,
  required = false,
  isRemoveField,
  removeField,
  name,
  fieldProps,
  validate,
  messageWarning,
  messageInfo,
  bottomMessageInfo,
  ...props
}: TextFieldProps<T>) => {
  const validationFunction = useMemo(
    () => createValidationFunction(required, validate),
    [required, validate],
  );

  return (
    <FinalFormField
      {...fieldProps}
      name={name}
      validate={validationFunction}
      render={({ input, meta }) => {
        return (
          <div
            className={`input-wrapper ${(meta.error || meta.submitError) && meta.touched ? 'input-wrapper__error' : ''}`}
          >
            <input {...input} {...props} />
            {isRemoveField && (
              <img
                className={cn('input-wrapper__remove-field-icon')}
                src={TrashIcon}
                alt="Remove Field"
                onClick={removeField}
              />
            )}
            {messageInfo && <MessageInfoPopup message={messageInfo} />}
            {messageWarning && (
              <div className="message-warning">{messageWarning}</div>
            )}
            {showErrorInline &&
              (meta.error || meta.submitError) &&
              meta.touched && (
                <div className="error-message__form">
                  {meta.error || meta.submitError}
                </div>
              )}
            {bottomMessageInfo && (
              <span className={cn('input-message__warning')}>
                {bottomMessageInfo}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};
export default FormTextField;
