const phoneNumberMask = (number: string): string => {
  const inputValue = number;

  let formattedPhone = '';

  // Удалите все символы, кроме цифр
  let cleanedValue: string =
    inputValue !== '+' ? inputValue.replace(/\D/g, '') : '';

  if (cleanedValue.length > 11) {
    cleanedValue = cleanedValue.substring(0, 11);
  }

  for (let i = 0; i < cleanedValue.length; i++) {
    switch (i) {
      case 0:
        formattedPhone = '+7 (';
        break;
      case 1:
      case 2:
      case 3:
        formattedPhone += `${cleanedValue[i]}`;
        break;
      case 4:
        formattedPhone += `) ${cleanedValue[i]}`;
        break;
      case 5:
      case 6:
        formattedPhone += `${cleanedValue[i]}`;
        break;
      case 7:
      case 9:
        formattedPhone += `-${cleanedValue[i]}`;
        break;
      default:
        formattedPhone += `${cleanedValue[i]}`;
        break;
    }
  }
  return formattedPhone;
};

const roundToTwoDigists = (num: number) => {
  return Math.round(num * 100) / 100;
};
export { phoneNumberMask, roundToTwoDigists };
