import { User } from '@/types/entities/user';

export type AutherizedUser = {
  token?: string;
  user?: User;
};

export enum AuthFormModalType {
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
  REGISTRATION_VERIFICATION = 'REGISTRATION_VERIFICATION',
  RECOVERY_VERIFICATION = 'RECOVERY_VERIFICATION',
}

export type AuthStates = {
  currentAuthFormType?: AuthFormModalType;
} & AutherizedUser;

export enum STEP_KIND {
  NEXT = 'NEXT',
  BACK = 'BACK',
}
