import React from 'react';
import cn from 'classnames';

import Product3DModelManagement from '@/features/AccountRetailer/components/Product3DModelManagement';

import Button from '@/components/ui/Button/Button';

import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';
import FormSelectField from '@/components/Forms/select-field';

import { ClusterFormProps } from '@/features/AccountRetailer/types/useClustersProductsManagement';

const ClusterForm: React.FC<ClusterFormProps> = ({
  onSubmitLoading,
  setProductModalFile,
  subCategoriesData2,
  subCategoriesData3,
  setSelectCategory,
  setSelectCategory2,
  selectCategory,
  selectCategory2,
  isLoadingUpdate,
  characteristicFields,
  showContextMenu3D,
  initialValues,
  categoriesOptions,
  addCharacteristicField,
  removeCharacteristicField,
  setShowContextMenu3D,
  onSubmit,
  productModelRef,
}) => {
  const isDisabled = isLoadingUpdate;

  return (
    <FinalForm
      id="cluster-form"
      className={'form-secondary'}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <div className="account__inner mt-3">
        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="group_product[name]"
            className="input"
            placeholder="Введите название кластера"
          />
          <span className={cn('input-message__warning')}>
            Обязательно к заполнению
          </span>
        </div>
        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            showErrorInline={false}
            name="group_product[name_color]"
            className="input"
            placeholder="Введите характеристику 1"
            messageInfo={{
              title: 'Харктеристика',
              text: 'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            }}
          />
          <span
            className={cn('add-item')}
            onClick={() => addCharacteristicField('characteristics_1')}
          >
            + Добавить вариант
          </span>

          <div className={cn('sub-item-container')}>
            {characteristicFields.characteristics_1.map((field, index) => (
              <FormTextField
                key={field.id}
                disabled={isDisabled}
                required={false}
                name={`group_product.group_product_colors_attributes[${index}].name`}
                className="input "
                isRemoveField
                removeField={() =>
                  removeCharacteristicField('characteristics_1', index)
                }
              />
            ))}
          </div>

          <span className={cn('input-message__warning')}>
            Пропустить если только один вариант
          </span>
        </div>
        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            showErrorInline={false}
            name="group_product[name_size]"
            className="input"
            placeholder="Введите характеристику 2"
            messageInfo={{
              title: 'Харктеристика',
              text: 'Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.',
            }}
          />
          <span
            className={cn('add-item')}
            onClick={() => addCharacteristicField('characteristics_2')}
          >
            + Добавить вариант
          </span>

          <div className={cn('sub-item-container')}>
            {characteristicFields.characteristics_2.map((field, index) => (
              <FormTextField
                key={field.id}
                disabled={isDisabled}
                required={false}
                name={`group_product.group_product_sizes_attributes[${index}].name`}
                className="input"
                isRemoveField
                removeField={() =>
                  removeCharacteristicField('characteristics_2', index)
                }
              />
            ))}
          </div>

          <span className={cn('input-message__warning')}>
            Пропустить если только один вариант
          </span>
        </div>

        <div className={cn('form__select-wrapper')}>
          <FormSelectField
            disabled={isDisabled}
            required
            showErrorInline={false}
            isSearchable
            name="group_product[category_1]"
            placeholder="Категория"
            fieldProps={{ formatOnBlur: true }}
            options={categoriesOptions}
            onChangeCallback={setSelectCategory}
          />

          <FormSelectField
            disabled={isDisabled}
            required
            showErrorInline={false}
            isSearchable
            name="group_product[category_2]"
            placeholder="Подкатегория"
            fieldProps={{ formatOnBlur: true }}
            options={subCategoriesData2}
            isDisabled={!selectCategory ? true : false}
            onChangeCallback={setSelectCategory2}
          />
          <FormSelectField
            disabled={isDisabled}
            required
            showErrorInline={false}
            isSearchable
            name="group_product[category_3]"
            placeholder="Группа"
            fieldProps={{ formatOnBlur: true }}
            options={subCategoriesData3}
            isDisabled={!selectCategory2 ? true : false}
          />
        </div>
        <Button
          primary
          size="m"
          className=""
          type="submit"
          form="cluster-form"
          disabled={onSubmitLoading}
          load={onSubmitLoading}
        >
          Сохранить
        </Button>
      </div>

      <Product3DModelManagement
        setProductModalFile={setProductModalFile}
        productModelRef={productModelRef}
        showContextMenu3D={showContextMenu3D}
        setShowContextMenu3D={setShowContextMenu3D}
        type="edit"
        alias_3d={initialValues.group_product.alias_3d}
      />
    </FinalForm>
  );
};

export default ClusterForm;
