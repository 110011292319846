import React from 'react';

import { CatalogueSubCategoryItem } from './CatalogueSubCategoryItem';
import { CategoriesList } from '../types';

type CatalogueSubCategoriesListProps = Omit<
  CategoriesList,
  'onEnterCategory' | 'onLeaveCategory'
>;

const CatalogueSubCategoriesList: React.FC<CatalogueSubCategoriesListProps> = ({
  selectedCategory,
  categories,
  parentCategory,
  onClick,
}) => {
  const canShow = selectedCategory?.id === parentCategory?.id;
  return (
    <div
      className={`catalogue-sub-categories-list ${canShow ? 'd-block' : 'd-none'}`}
    >
      <div className="catalogue-sub-categories-list__items">
        {categories?.map((category) => (
          <CatalogueSubCategoryItem
            key={`${category.name}-${category.id}`}
            title={category.name}
            onClick={onClick(category, 'THIRD_CATEGORY')}
          />
        ))}
      </div>
    </div>
  );
};

export default CatalogueSubCategoriesList;
