import { apiSlice } from '@/services/apiSlice';
import { GetProductResponseData } from '../types/useProduct';

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query<GetProductResponseData, number>({
      query: (id) => `/products/${id}`,
    }),
  }),
});

export const { useGetProductQuery } = productApiSlice;
