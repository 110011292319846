import React from 'react';
import cn from 'classnames';

import './input.sass';

type InputProps = {
  placeholder: string;
  icon?: string;
  value?: string;
  button?: string;
};

const Input: React.FC<InputProps> = ({ placeholder, icon, value, button }) => {
  const classes = cn('theme-input', {
    'theme-input__icon': icon,
    'theme-input__link': button,
  });
  return (
    <div className={classes}>
      {icon && <img src={icon} />}
      <input type="text" placeholder={placeholder} value={value} />
      {button && <a href="#">{button}</a>}
    </div>
  );
};

export default Input;
