import { assign, get, has } from 'lodash-es';
import { BaseSyntheticEvent, useState } from 'react';
import { ErrorsMessage } from '@/types/forms';
import { useOnRegisterMutation } from '../services/authApi';
import { STEP_KIND } from '../types';

import {
  REGISTER_USER_TYPE,
  RegistrationFormData,
  REGISTRATION_STEPS,
} from '../types/register';
import { UseRegister } from '../types/useRegister';
import { serverFormatter } from '../utils';
import { useGetPageQuery } from '@/features/StaticPages/services/pageSlice';

const useRegister = (): UseRegister => {
  const [registerUserType, onChangeRegisterUsertype] =
    useState<REGISTER_USER_TYPE>(REGISTER_USER_TYPE.CLIENT);
  const [onRegister, { isLoading }] = useOnRegisterMutation();
  const [registedUserPhone, setRegistedUserPhone] = useState<string>('');

  const [canShowPolicy, setCanShowPolicy] = useState<boolean>(false);
  const [selectedPolicy, setSelectedPolicy] = useState<string>('politika');
  const initialValues = {
    role_kind: registerUserType,
    full_name: '',
    phone: '',
    password: '',
    password_confirmation: '',
    politika: false,
    oferta: false,
  };

  const [currentStep, setCurrentStep] = useState<REGISTRATION_STEPS>(
    REGISTRATION_STEPS.STEP_1,
  );

  const onChangeStep = (type: STEP_KIND) => () => {
    setCurrentStep((prev) => (type === STEP_KIND.NEXT ? prev + 1 : prev - 1));
  };

  const onSubmit = async (data: RegistrationFormData) => {
    try {
      const response = await onRegister(serverFormatter(data)).unwrap();
      if (response) {
        setCurrentStep(REGISTRATION_STEPS.STEP_2);
        setRegistedUserPhone(data.phone);
      }
    } catch (error) {
      const errors = get(error, 'data.errors') as unknown as ErrorsMessage;
      const newErrors = {};

      const hasNameError =
        has(errors, 'first_name') || has(errors, 'last_name');
      const nameError = hasNameError ? `Вы не указали: ФИО` : null;
      if (nameError) assign(newErrors, { full_name: nameError });

      return { ...errors, ...newErrors };
    }
  };

  const onClosePolicy = () => {
    setCanShowPolicy(false);
  };
  const onOpenPolicy = (policyType: string) => (event: BaseSyntheticEvent) => {
    event.preventDefault();
    setSelectedPolicy(policyType);
    setCanShowPolicy(true);
  };

  const { data: pageData, isLoading: isPageDataLoading } = useGetPageQuery({
    alias: selectedPolicy,
  });
  const policyData = pageData?.data;

  return [
    {
      isLoading,
      isPageDataLoading,
    },
    {
      currentStep,
      registerUserType,
      initialValues,
      registedUserPhone,
      canShowPolicy,
      policyData,
    },
    {
      onSubmit,
      onChangeRegisterUsertype,
      onChangeStep,
      onOpenPolicy,
      onClosePolicy,
    },
  ];
};
export default useRegister;
