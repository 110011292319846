// import { jwtDecode } from 'jwt-decode';

// import { AccountMenuItemType, UserJwtPayload } from '@/types/types';
import { AccountMenuItemType } from '@/types/types';

import Icon from '@/components/ui/Icon';
import homeIcon from '@/assets/icons/organization/home.svg';
import myItems from '@/assets/icons/organization/my_items.svg';
import pavilions from '@/assets/icons/organization/pavilions.svg';
import delivery from '@/assets/icons/organization/delivery.svg';
import prices from '@/assets/icons/organization/prices.svg';
import analysis from '@/assets/icons/organization/analysis.svg';
import chat from '@/assets/icons/organization/chat.svg';
import optimization from '@/assets/icons/organization/optimization.svg';
import sale from '@/assets/icons/organization/sale.svg';
import rating from '@/assets/icons/organization/rating.svg';
import help from '@/assets/icons/organization/help.svg';
import document from '@/assets/icons/organization/document.svg';

// Убрать, поменять
// const token = JSON.parse(localStorage.getItem('user') || '{}').token;
// let pavilionsUser: number[];
// if (token) {
//   pavilionsUser = jwtDecode<UserJwtPayload>(token).pavilion_user_ids;
// }

const accountMenuItems: AccountMenuItemType[] = [
  {
    title: 'Главная',
    href: '/account/',
    icon: <Icon src={homeIcon} alt="Arrow" />,
    isActive: true,
  },
  {
    title: 'Мои товары',
    href: '/account/clusters/',
    icon: <Icon src={myItems} alt="Arrow" />,
    isActive: true,
  },
  {
    title: 'Мои павильоны',
    href: '/account/pavilions',
    icon: <Icon src={pavilions} alt="Arrow" />,
    isActive: true,
  },
  {
    title: 'Поставки',
    href: '#',
    icon: <Icon src={delivery} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Цены и скидки',
    href: '#',
    icon: <Icon src={prices} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Аналитика',
    href: '#',
    icon: <Icon src={analysis} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Чат с покупателем',
    href: '#',
    icon: <Icon src={chat} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Продвижение',
    href: '#',
    icon: <Icon src={optimization} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Акции',
    href: '#',
    icon: <Icon src={sale} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Рейтинги',
    href: '#',
    icon: <Icon src={rating} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Тех поддержка',
    href: '#',
    icon: <Icon src={help} alt="Arrow" />,
    isActive: false,
  },
  {
    title: 'Мои документы',
    href: '/account/documents/',
    icon: <Icon src={document} alt="Arrow" />,
    isActive: true,
  },
];

export { accountMenuItems };
