import React, { createContext, useContext, useState, ReactNode } from 'react';

import { Is3DContextType } from '@/features/ModeSwitchButton3D/types/modeSwitchButton3D';

const Is3DContext = createContext<Is3DContextType>({
  is3D: false,
  setIs3D: () => {},
});
interface Is3DProviderProps {
  children: ReactNode;
}

export const Is3DProvider: React.FC<Is3DProviderProps> = ({ children }) => {
  const [is3D, setIs3D] = useState<boolean>(false);

  return (
    <Is3DContext.Provider value={{ is3D, setIs3D }}>
      {children}
    </Is3DContext.Provider>
  );
};

export const useIs3D = (): Is3DContextType => useContext(Is3DContext);
