import React from 'react';

import ChangePasswordForm from '../../forms/ChangePasswordForm';
import useChangePassword from '../../hooks/useChangePassword';
import { ChangePasswordProps } from '../../types/changePassword';

const ChangePassword: React.FC<ChangePasswordProps> = ({
  back,
  next,
  phone,
}) => {
  const [{ isLoading }, { initialValues }, { onSubmit }] = useChangePassword({
    phone,
    next,
  });

  return (
    <>
      <ChangePasswordForm
        isLoading={isLoading}
        className={'registration__form'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onGoback={back}
      />
      <button
        className="submit-btn btn-primary"
        type="submit"
        form="change-password-form"
      >
        Сохранить
      </button>
    </>
  );
};

export default ChangePassword;
