import {
  isArray,
  isDate,
  isEmpty,
  isNull,
  isObject,
  isString,
  isUndefined,
} from 'lodash-es';

const isEmptyValue = (value: any) =>
  isNull(value) ||
  isUndefined(value) ||
  ((isObject(value) || isArray(value) || isString(value)) &&
    isEmpty(value) &&
    !isDate(value));

const mustBePresent = (value?: any): null | string => {
  if (!value) {
    return 'Обязательно для заполнения';
  }
  if ((isString(value) && !value.trim()) || isEmptyValue(value)) {
    return 'Обязательно для заполнения';
  }
  return null;
};
export { mustBePresent };
