import React from 'react';
import ReactDOM from 'react-dom/client';
import Application from './Application';
import { Provider } from 'react-redux';
import store from './store/store';

import { GlobalProvider } from '@/contexts/GlobalContext';

import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <GlobalProvider>
      <Provider store={store}>
        <Application />
      </Provider>
    </GlobalProvider>
  </React.StrictMode>,
);
