import { useSelector } from 'react-redux';
import { useOnAddCartItemMutation } from '@/features/Cart/services/cartApi';
import { UseAddToCart } from '@/types/hooks/UseCartGlobal';
import { RootState } from '@/store/store';

const useAddToCart = (productId: number): UseAddToCart => {
  const { cart_products: cartProducts } = useSelector(
    (state: RootState) => state.cart,
  );

  const countProduct =
    cartProducts?.find((product) => product.product_id === productId)
      ?.count_products || 0;

  const [onAddCartItem, { isLoading }] = useOnAddCartItemMutation();

  const onChangeCountToCart = async (
    product_id: number,
    count_products: number,
  ) => {
    try {
      await onAddCartItem({
        product_id: product_id,
        count_products: count_products,
      }).unwrap();
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  return [
    {
      isLoading,
    },
    {
      countProduct,
    },
    {
      onChangeCountToCart,
    },
  ];
};
export default useAddToCart;
