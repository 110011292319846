import React from 'react';

import '@/components/Account/Products/products.sass';

const AccountSupport: React.FC = () => {
  return (
    <div className="container account-container">
      <div className="account-content">AccountSupport</div>
    </div>
  );
};

export default AccountSupport;
