import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import cn from 'classnames';

import { useGlobalContext } from '@/contexts/GlobalContext';
import { useIs3D } from '@/utils/Is3DContext';

import { ElementToggleProps } from '@/types/types';

import Search from '@/assets/icons/search/search.svg';
import { useNavigate } from 'react-router-dom';
import { APPLICATION_URLS } from '@/constants/urls';

type SearchFormProps = ElementToggleProps & {
  searchFormRef: React.RefObject<HTMLDivElement>;
  searchContainerRef: React.RefObject<HTMLDivElement>;
  showSearch: boolean;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  setShowSearch: Dispatch<SetStateAction<boolean>>;
  onBlurSearchForm: () => void;
};
const { product_search } = APPLICATION_URLS;
const SearchForm: React.FC<SearchFormProps> = ({
  searchFormRef,
  searchContainerRef,
  showSearch,
  searchText,
  setSearchText,
  setShowSearch,
  onBlurSearchForm,
}) => {
  const [{ myPavilion, launcher3d }] = useGlobalContext();
  const { is3D } = useIs3D();
  const navigate = useNavigate();

  useEffect(() => {
    if (myPavilion) {
      myPavilion.setTitle('Поиск');
    }
  }, [myPavilion]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        searchFormRef.current &&
        !searchFormRef.current.contains(e.target as Node) &&
        searchContainerRef.current &&
        !searchContainerRef.current.contains(e.target as Node)
      ) {
        onBlurSearchForm();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchFormRef, searchContainerRef, onBlurSearchForm]);

  useEffect(() => {
    if (is3D) onBlurSearchForm();
  }, [is3D]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (myPavilion) {
      myPavilion.setTitle(
        e.target.value.length < 26
          ? e.target.value
          : e.target.value.slice(0, 26),
      );
    }
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (
        searchInputRef.current &&
        searchInputRef.current.contains(event.target as Node)
      ) {
        if (event.key === 'Enter') {
          const searchValue = searchInputRef.current?.value;
          // if(String(searchValue)?.length > 3){
          const url = `${product_search}?search=${searchValue}`;
          navigate(url);
          setShowSearch(false);
          // }
        }
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [launcher3d]);

  return (
    <div
      className={cn('search-form-container', {
        'search-form-container__active': showSearch,
      })}
      ref={searchFormRef}
    >
      <img src={Search} alt="Search" onClick={() => setShowSearch(true)} />
      <input
        onChange={handleSearchChange}
        onFocus={() => setShowSearch(true)}
        placeholder="Поиск"
        className="search"
        type="text"
        value={searchText}
        ref={searchInputRef}
      />
    </div>
  );
};

export default SearchForm;
