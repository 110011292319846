import { get } from 'lodash-es';
import { useOnPasswordRecoveryMutation } from '../services/authApi';
import { ErrorsMessage } from '@/types/forms';
import { ChangePasswordFormData } from '../types/changePassword';
import { PASSWORD_RECOVERY_STEPS } from '../types/passwordRecovery';
import {
  UseChangePassword,
  UseChangePasswordProps,
} from '../types/useChangePassword';

const useChangePassword = ({
  phone,
  next,
}: UseChangePasswordProps): UseChangePassword => {
  const [onPasswordRecovery, { isLoading }] = useOnPasswordRecoveryMutation();

  const initialValues = {
    password: '',
    password_confirmation: '',
    phone: phone,
    step: PASSWORD_RECOVERY_STEPS.CHANGE_PASSWORD,
  };

  const onSubmit = async (data: ChangePasswordFormData) => {
    try {
      const response = await onPasswordRecovery({ user: data }).unwrap();
      if (response) {
        next();
      }
    } catch (error) {
      const errors = get(error, 'data.errors') as unknown as ErrorsMessage;
      return errors;
    }
  };

  return [
    {
      isLoading,
    },
    {
      initialValues,
    },
    {
      onSubmit,
    },
  ];
};
export default useChangePassword;
