import Button from '@/components/ui/Button/Button';

const OrderItemsListBlock = () => {
  return (
    <div className="user-cabinet__order_items_list_block">
      <div className="user-cabinet__order_items_list_block__title">
        Список товаров
        <span>3 позиции</span>
      </div>

      <div className="user-cabinet__order_items_list_block__list">
        <div className="user-cabinet__order_items_list_block__list__item">
          <div className="user-cabinet__order_items_list_block__list__item__info">
            <div className="user-cabinet__order_items_list_block__list__item__info__image-wrapper"></div>
            <div className="user-cabinet__order_items_list_block__list__item__info__details">
              <div className="user-cabinet__order_items_list_block__list__item__info__details__title">
                Свитер AVS
              </div>
              <div className="user-cabinet__order_items_list_block__list__item__info__details__properties">
                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Магазин</div>
                  Zara
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Бренд</div>
                  AVS
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Размер</div>
                  46 RUS(L)
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Цвет</div>
                  Светло коричневый
                </div>
              </div>
            </div>
          </div>
          <div className="user-cabinet__order_items_list_block__list__item__actions">
            <div className="price">
              <span className="old-price">2600 ₽</span>2240 ₽
            </div>
            <Button size="" className="order-action-button">
              Оценить товар
            </Button>
          </div>
        </div>
        <div className="user-cabinet__order_items_list_block__list__item">
          <div className="user-cabinet__order_items_list_block__list__item__info">
            <div className="user-cabinet__order_items_list_block__list__item__info__image-wrapper"></div>
            <div className="user-cabinet__order_items_list_block__list__item__info__details">
              <div className="user-cabinet__order_items_list_block__list__item__info__details__title">
                Свитер AVS
              </div>
              <div className="user-cabinet__order_items_list_block__list__item__info__details__properties">
                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Магазин</div>
                  Zara
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Бренд</div>
                  AVS
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Размер</div>
                  46 RUS(L)
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Цвет</div>
                  Светло коричневый
                </div>
              </div>
            </div>
          </div>
          <div className="user-cabinet__order_items_list_block__list__item__actions">
            <div className="price">
              <span className="old-price">2600 ₽</span>2240 ₽
            </div>
            <Button size="" className="order-action-button">
              Оценить товар
            </Button>
          </div>
        </div>
        <div className="user-cabinet__order_items_list_block__list__item">
          <div className="user-cabinet__order_items_list_block__list__item__info">
            <div className="user-cabinet__order_items_list_block__list__item__info__image-wrapper"></div>
            <div className="user-cabinet__order_items_list_block__list__item__info__details">
              <div className="user-cabinet__order_items_list_block__list__item__info__details__title">
                Свитер AVS
              </div>
              <div className="user-cabinet__order_items_list_block__list__item__info__details__properties">
                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Магазин</div>
                  Zara
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Бренд</div>
                  AVS
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Размер</div>
                  46 RUS(L)
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Цвет</div>
                  Светло коричневый
                </div>
              </div>
            </div>
          </div>
          <div className="user-cabinet__order_items_list_block__list__item__actions">
            <div className="price">
              <span className="old-price">2600 ₽</span>2240 ₽
            </div>
            <Button size="" className="order-action-button">
              Оценить товар
            </Button>
          </div>
        </div>{' '}
        <div className="user-cabinet__order_items_list_block__list__item">
          <div className="user-cabinet__order_items_list_block__list__item__info">
            <div className="user-cabinet__order_items_list_block__list__item__info__image-wrapper"></div>
            <div className="user-cabinet__order_items_list_block__list__item__info__details">
              <div className="user-cabinet__order_items_list_block__list__item__info__details__title">
                Свитер AVS
              </div>
              <div className="user-cabinet__order_items_list_block__list__item__info__details__properties">
                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Магазин</div>
                  Zara
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Бренд</div>
                  AVS
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Размер</div>
                  46 RUS(L)
                </div>

                <div className="user-cabinet__order_items_list_block__list__item__info__details__properties__item">
                  <div className="text-title">Цвет</div>
                  Светло коричневый
                </div>
              </div>
            </div>
          </div>
          <div className="user-cabinet__order_items_list_block__list__item__actions">
            <div className="price">
              <span className="old-price">2600 ₽</span>2240 ₽
            </div>
            <Button size="" className="order-action-button">
              Оценить товар
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderItemsListBlock;
