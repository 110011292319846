import { apiSlice } from '@/services/apiSlice';
import { GetProductsRecommendationsResponseData } from '../types/useProductsRecommendations';

export const productsRecommendationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductsRecommendations: builder.query<
      GetProductsRecommendationsResponseData,
      void
    >({
      query: () => '/user_product_recommendations',
    }),
  }),
});

export const { useGetProductsRecommendationsQuery } =
  productsRecommendationsApiSlice;
