import React, { useEffect } from 'react';
import cn from 'classnames';

import { useGlobalContext } from '@/contexts/GlobalContext';
import { useIs3D } from '@/utils/Is3DContext';

import { ElementToggleProps } from '@/types/types';

type CatalogueBtnProps = ElementToggleProps;

const CatalogueBtn: React.FC<CatalogueBtnProps> = ({
                                                     setShowCatalogue,
                                                     activeElement,
                                                   }) => {
  const [{ blockFooterActive }, { setBlockFooterActive }] = useGlobalContext();
  const { is3D } = useIs3D();

  useEffect(() => {
    if (is3D && setShowCatalogue) {
      setShowCatalogue(false);
    }
  }, [is3D]);

  return (
    <button
      className={cn('catalogue__btn btn-primary', {
        'catalogue-active': activeElement,
      })}
      onClick={() => {
        setShowCatalogue ? setShowCatalogue((prev) => !prev) : false;
        setBlockFooterActive(!blockFooterActive);
      }}
      aria-label="Каталог"
    >
      <div className={cn('burger-container')}>
        <span className={cn('burger-container__line')}></span>
        <span className={cn('burger-container__line')}></span>
        <span className={cn('burger-container__line')}></span>
      </div>
      <span className={cn('burger-container__text')}>Каталог</span>
    </button>
  );
};

export default CatalogueBtn;
