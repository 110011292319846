const extractInitials = (text: string) => {
  const words = text.trim().split(/\s+/);

  // В зависимости от количества слов в строке
  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase();
  } else if (words.length >= 2) {
    return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();
  } else {
    return '';
  }
};

export { extractInitials };
