import Cookies from 'js-cookie';

import { DOMAIN_API } from '@/constants/domains';
import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const createBaseQuery = () => {
  return fetchBaseQuery({
    baseUrl: `${DOMAIN_API}/api/v1`,
    prepareHeaders: (headers) => {
      const userString = localStorage.getItem('user');
      const userToken = userString ? JSON.parse(userString).token : null;

      const token = Cookies.get('accessToken') || userToken;
      if (token) {
        headers.set('X-JWT-Token', token);
      }
      return headers;
    },
  });
};

const baseQueryWithReAuth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any,
) => {
  const baseQuery = createBaseQuery();
  const result = await baseQuery(args, api, extraOptions);

  // let result = await createBaseQuery()(args, api, extraOptions);
  //
  // // Предположим, что сервер возвращает статус 401 для неавторизованных запросов
  // if (result.error && result.error.status === 401) {
  //   // Попытка обновления токена
  //   const refreshToken = Cookies.get('refreshToken');
  //   if (refreshToken) {
  //     // Здесь должна быть ваша логика для обновления токена
  //     const refreshed = await refreshAuthToken(refreshToken);
  //     if (refreshed) {
  //       // Сохранение нового токена
  //       Cookies.set('accessToken', refreshed.newToken);
  //
  //       // Повторный запрос с обновлённым токеном
  //       result = await createBaseQuery()(args, api, extraOptions);
  //     }
  //   }
  // }
  // if (refreshed) {
  //   Cookies.set('accessToken', refreshed.newToken);
  //   // Здесь обновляем состояние Redux
  //   api.dispatch(authActions.setAuthToken(refreshed.newToken));
  //   result = await createBaseQuery()(args, api, extraOptions);
  // }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'categories',
    'products',
    'users',
    'pages',
    'page',
    'Profile',
    'Carts',
    'Favorites',
  ],
  endpoints: () => ({}),
});
