import React from 'react';

import { ProductReviewsPanelProps } from '@/features/ProductCard/types';

// Icons
import Star from '@/assets/icons/star-outline.svg';
import Message from '@/assets/icons/message.svg';
import Arrow from '@/assets/icons/arrow.svg';

const ProductReviewsPanel: React.FC<ProductReviewsPanelProps> = ({
  productReviews,
}) => (
  <div className="product-reviews-panel">
    <div className="product-reviews-panel__item --rating">
      <img src={Star} alt="Rating" />
      {productReviews?.reviews_stars}
    </div>

    <div className="product-reviews-panel__item --feedbacks">
      <img src={Message} alt="Feedbacks" />
      {productReviews?.questions_length}
    </div>

    <img src={Arrow} alt="More" className="item" />
  </div>
);

export default ProductReviewsPanel;
