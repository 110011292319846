import React from 'react';

import '@/components/Cart/cart.sass';
import Cart from '@/features/Cart';

const CartPage: React.FC = () => {
  return <Cart />;
};

export default CartPage;
