import { BaseSyntheticEvent } from 'react';
import Dropzone from 'react-dropzone';

import Button from '@/components/ui/Button/Button';

import plusIcon from '@/assets/icons/plus-icon.svg';

export type DocumentsUploaderProps = {
  onChangeFiles: (filesData: File[]) => void;
};
const DocumentsUploader = ({ onChangeFiles }: DocumentsUploaderProps) => {
  return (
    <div className="organization__document_uploader" style={{ marginTop: 20 }}>
      <Dropzone onDropAccepted={onChangeFiles}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
              <Button
                icon={plusIcon}
                size={'m'}
                onClick={(event: BaseSyntheticEvent) => event.preventDefault()}
              />
            }
          </div>
        )}
      </Dropzone>
    </div>
  );
};
export default DocumentsUploader;
