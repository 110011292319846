import OptionButton from '@/components/ui/OptionButton';
import { RenderProps } from '@/types/forms';
import { ReactNode } from 'react';

import { Field as FinalFormField, FieldProps } from 'react-final-form';
import { Option } from '@/types/common';

export type FormOptionButtonFieldProps<T> = {
  disabled?: string[];
  name: string;
  render?: (props: RenderProps<T>) => ReactNode | undefined;
  fieldProps?: Partial<FieldProps<any, any>>;
  deliveryOptions: Option[];
};

const FormOptionButtonField = <T,>({
  name,
  disabled,
  fieldProps,
  deliveryOptions,
}: FormOptionButtonFieldProps<T>) => {
  return (
    <FinalFormField
      {...fieldProps}
      name={name}
      render={({ input }) => {
        return (
          <OptionButton
            options={deliveryOptions}
            active={input.value}
            onChange={input.onChange}
            hasDisabled={disabled}
          />
        );
      }}
    />
  );
};
export default FormOptionButtonField;
