import Button from '@/components/ui/Button/Button';
import { BaseSyntheticEvent } from 'react';

type OrdersBlockProps = {
  onClick: (id: number) => (event: BaseSyntheticEvent) => void;
};
const OrdersBlock = ({ onClick }: OrdersBlockProps) => {
  return (
    <div className="user-cabinet__orders">
      <div
        className="user-cabinet__orders__order-item page-block"
        onClick={onClick(1)}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span className="text-title">Заказ</span>
            №2505-253 от 26 декабря 2023
            <div className="order-status">Оплачено</div>
          </div>

          <div>
            <span className="text-title">Сумма заказа</span>
            <span className="price">11 796 ₽ </span>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <div className="text-title">Доставка по адресу</div>
            улица Пушкина-Колотушкина, дом 33/1, квартира 13
          </div>
          <div>
            <div className="text-title">Дата доставки</div>
            29 декабря 2023
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <span className="text-title">Товаров в зазаке</span>3 штуки
          </div>

          <Button size="m" className="order-action-button">
            Отменить заказ
          </Button>
        </div>
      </div>

      <div
        className="user-cabinet__orders__order-item page-block"
        onClick={onClick(2)}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span className="text-title">Заказ</span>
            №2505-253 от 26 декабря 2023
            <div className="order-status order-status__canceled">Отмененно</div>
          </div>

          <div>
            <span className="text-title">Сумма заказа</span>
            <span className="price">11 796 ₽ </span>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <div className="text-title">Доставка по адресу</div>
            улица Пушкина-Колотушкина, дом 33/1, квартира 13
          </div>
          <div>
            <div className="text-title">Дата доставки</div>
            29 декабря 2023
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <span className="text-title">Товаров в зазаке</span>3 штуки
          </div>

          <Button size="m" className="order-action-button">
            Отменить заказ
          </Button>
        </div>
      </div>
    </div>
  );
};
export default OrdersBlock;
