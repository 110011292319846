import { useEffect, useCallback } from 'react';
import deviceInfo from '@/utils/DeviceInfo';
import { useGlobalContext } from '@/contexts/GlobalContext';
import { useProduct } from '@/contexts/ProductContext';
import {
  useGetPavilionsQuery,
  useGetProductsModelsQuery,
} from '@/features/Global/services/pavilionBuilderLayoutSlice';

const useLauncherSetup = () => {
  const [
    { loadModels, loader3d, launcher3d, accessToken, shouldRefetch },
    {
      setModelsLoaded,
      setMyPavilion,
      setLauncher3dRenderer,
      setLoadModels,
      setShouldRefetch,
      setIs3dSceneReady,
    },
  ] = useGlobalContext();
  const { setSelectedProductKey } = useProduct();

  const {
    data: productsModelsData,
    isLoading: isLoadingProductsModels,
    refetch: refetchProductsModelsData,
  } = useGetProductsModelsQuery();

  const { data: pavilionsData, refetch: pavilionUserRefetch } =
    useGetPavilionsQuery();

  useEffect(() => {
    if (!isLoadingProductsModels && productsModelsData?.data && loader3d) {
      setModelsLoaded(true);
      for (const value of productsModelsData.data.product_models) {
        if (Object.keys(value).length > 2) {
          loader3d.listModel[value.alias] = [
            { path: value.model_url, distance: 0 },
            { path: value.model_low_url, distance: 10 },
            { path: value.model_3d_sprite, distance: 20 },
          ];
        } else {
          loader3d.listModel[value.alias] = value.model_url;
        }
      }
      setLoadModels(true);
    }
  }, [
    setIs3dSceneReady,
    productsModelsData,
    loader3d,
    setLoadModels,
    setShouldRefetch,
    isLoadingProductsModels,
  ]);

  useEffect(() => {
    if (shouldRefetch) {
      setIs3dSceneReady(false);
      refetchProductsModelsData();
      setShouldRefetch(false);
    }
  }, [shouldRefetch, refetchProductsModelsData, setShouldRefetch]);

  useEffect(() => {
    if (accessToken) {
      pavilionUserRefetch();
    }
  }, [accessToken, pavilionUserRefetch]);

  const setupLauncher = useCallback(async () => {
    try {
      const resMap = await fetch('/map.json'); // Заменить на получение с сервера
      if (!resMap.ok) throw new Error('Ошибка загрузки карты');
      const map = await resMap.json();

      const resPavilion = await fetch('/mapPavilion.json');
      if (!resPavilion.ok) throw new Error('Ошибка загрузки данных павильона');
      // const mapPavilion = await resPavilion.json();

      window.onbeforeunload = () => {
        launcher3d.dispose();
      };

      launcher3d.init({
        textureDetail:
          deviceInfo() === 'iPhone'
            ? 256
            : deviceInfo() === 'Android'
              ? 512
              : 1024,
        initStats: false, // Статистика
        initGui: false, // GUI контроллер
        btnPointerLock: document.querySelector('#pointer-lock'),
      });

      const handleReady = () => {
        setIs3dSceneReady(true);
        setMyPavilion(launcher3d.controller.myPavilion);

        launcher3d.controller.addEventListener('selected-product', (e: any) => {
          console.log('selected-product');
          console.log(e.product);
          setSelectedProductKey(e.product.data.product_id);
        });
      };

      launcher3d.addEventListener('ready', handleReady);

      if (pavilionsData && pavilionsData.data.pavilions) {
        launcher3d.start(map, pavilionsData.data.pavilions, {
          totalDataSize: 1000000000,
        });
      } else {
        console.error('No pavilions data available');
      }

      setLauncher3dRenderer(true);

      // Возвращаем функцию для очистки
      return () => {
        launcher3d.removeEventListener('ready', handleReady);
      };
    } catch (err) {
      console.error('Ошибка инициализации Launcher3D:', err);
    }
  }, [
    launcher3d,
    pavilionsData,
    setMyPavilion,
    setSelectedProductKey,
    setLauncher3dRenderer,
    setIs3dSceneReady,
  ]);

  useEffect(() => {
    if (loadModels && pavilionsData && launcher3d) {
      setupLauncher();
    }
  }, [loadModels, pavilionsData, launcher3d, setupLauncher]);

  return null;
};

export default useLauncherSetup;
