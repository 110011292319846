import { useEffect } from 'react';
import { useAppDispatch } from '@/hooks';

import { useGlobalContext } from '@/contexts/GlobalContext';

import { useGetProductsFavoritesQuery } from '@/features/ProductsFavorites/services/productsFavoritesApi';
import { setGlobalFavoriteStates } from '@/features/ProductsFavorites/states/favoriteSlice';
import { clearCartState } from '@/features/Cart/states/cartSlice';

const initFavorites = () => {
  const [{ accessToken }] = useGlobalContext();

  const { data: favoritesData, refetch } = useGetProductsFavoritesQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (accessToken) {
      refetch();
    } else {
      dispatch(clearCartState());
    }
  }, [accessToken, refetch]);

  useEffect(() => {
    if (favoritesData) {
      const count_total = favoritesData.data.stars.length;
      dispatch(
        setGlobalFavoriteStates({
          count_total,
        }),
      );
    }
  }, [favoritesData]);
};

export { initFavorites };
