import { useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { isFunction } from 'lodash-es';

import type { FormProps } from '@/types/forms';

const Form = <T,>({
  id,
  useEffectCb,
  validateOnBlur,
  destroyOnUnregister,
  keepDirtyOnReinitialize = true,
  className,
  initialValues,
  initialValuesEqual,
  onSubmit,
  children,
  ...props
}: FormProps<T>) => {
  return (
    <FinalForm
      {...props}
      validateOnBlur={validateOnBlur}
      destroyOnUnregister={destroyOnUnregister}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      initialValues={initialValues}
      initialValuesEqual={initialValuesEqual}
      onSubmit={onSubmit}
      render={(formProps) => {
        // console.log('formProps', formProps.values);
        useEffect(() => {
          useEffectCb?.(formProps);
        }, []);

        return (
          <form
            noValidate
            id={id}
            className={className}
            data-testid="form"
            onSubmit={formProps.handleSubmit}
          >
            {isFunction(children) ? children(formProps) : children}
          </form>
        );
      }}
    />
  );
};

export default Form;
