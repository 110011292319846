import React from 'react';

import cn from 'classnames';

import '@/components/Account/account.sass';

type HeaderFilterProps = {
  list: string[];
};

const HeaderFilter: React.FC<HeaderFilterProps> = ({ list }) => {
  return (
    <div className="filter-tabs">
      {list.map((item, i) => (
        <a
          key={i}
          href="#"
          className={cn('filter-tabs__item', { active: i === 0 })}
        >
          {item}
        </a>
      ))}
    </div>
  );
};

export default HeaderFilter;
