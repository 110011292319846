import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import './preloader.sass';

import Logo from '@/assets/icons/logo/logo.svg';
import Star1 from '@/assets/img/preloader/Star_1.svg';
import Star2 from '@/assets/img/preloader/Star_2.svg';
import Star3 from '@/assets/img/preloader/Star_3.svg';
import Star4 from '@/assets/img/preloader/Star_4.svg';
import Star5 from '@/assets/img/preloader/Star_5.svg';
import Star6 from '@/assets/img/preloader/Star_6.svg';

const Preloader: React.FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const [hide, setHide] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  // const handleDataLoaded = (e: { value: string }) => {
  const handleDataLoaded = (e: { value: string }) => {
    setIsDataLoaded(true);
    let newProgress = Number(e.value);

    if (isDataLoaded) {
      newProgress = newProgress / 2;
    } else {
      newProgress = 52 + newProgress / 2;
    }

    setProgress(newProgress);
  };

  const handleReady = () => {
    setProgress(100);
    setTimeout(() => {
      setHide(true);
    }, 500);
  };

  const preloaderInit = () => {
    if (window.Launcher3D) {
      const Launcher = window.Launcher3D;
      Launcher.addEventListener('datalLoaded', handleDataLoaded);
      Launcher.addEventListener('compiledMaterialProgress', handleDataLoaded);
      Launcher.addEventListener('ready', handleReady);

      return () => {
        Launcher.removeEventListener('datalLoaded', handleDataLoaded);
        Launcher.addEventListener('compiledMaterialProgress', handleDataLoaded);
        Launcher.removeEventListener('ready', handleReady);
      };
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.Launcher3D) {
        clearInterval(intervalId);
        preloaderInit();
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  if (hide) {
    return null;
  }

  return (
    <div className={cn('preloader-app')}>
      <div className={cn('preloader-app__logo-container')}>
        <img src={Logo} alt="UNEMAG - инновационный маркетплейс" />

        <span className={cn('loading-bar-container')}>
          <span
            className={cn('loading-bar')}
            style={{ width: `${progress}%` }}
          ></span>
        </span>
      </div>

      <img src={Star1} className={cn('preloader-app__star')} alt="Star"></img>
      <img src={Star2} className={cn('preloader-app__star')} alt="Star"></img>
      <img src={Star3} className={cn('preloader-app__star')} alt="Star"></img>
      <img src={Star4} className={cn('preloader-app__star')} alt="Star"></img>
      <img src={Star5} className={cn('preloader-app__star')} alt="Star"></img>
      <img src={Star6} className={cn('preloader-app__star')} alt="Star"></img>
    </div>
  );
};

export default Preloader;
