import React from 'react';

import '@/components/Account/Products/products.sass';

const AccountStock: React.FC = () => {
  return (
    <div className="container account-container">
      <div className="account-content">Chat</div>
    </div>
  );
};

export default AccountStock;
