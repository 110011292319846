import { useAppDispatch } from '@/hooks';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

import { setCurrentAuthModal } from '../states/authSlice';

import { AuthFormModalType } from '../types';
import { UseAuth } from '../types/useAuth';

const useAuth = (): UseAuth => {
  const dispatch = useAppDispatch();

  const currentAuthFormType = useSelector(
    (state: RootState) => state.auth.currentAuthFormType,
  );

  // Modals
  const onOpenPasswordRecoveryModal = () => {
    dispatch(setCurrentAuthModal(AuthFormModalType.PASSWORD_RECOVERY));
  };
  const onOpenRegistrationModal = () => {
    dispatch(setCurrentAuthModal(AuthFormModalType.REGISTRATION));
  };
  const onOpenLoginModal = () => {
    dispatch(setCurrentAuthModal(AuthFormModalType.LOGIN));
  };
  const onOpenRecoveryVerificationModal = () => {
    dispatch(setCurrentAuthModal(AuthFormModalType.RECOVERY_VERIFICATION));
  };
  const onOpenRegistrationVerificationModal = () => {
    dispatch(setCurrentAuthModal(AuthFormModalType.REGISTRATION_VERIFICATION));
  };
  const onCloseModal = () => {
    dispatch(setCurrentAuthModal(undefined));
  };

  return [
    {
      currentAuthFormType,
    },
    {
      onOpenPasswordRecoveryModal,
      onOpenRegistrationModal,
      onOpenLoginModal,
      onOpenRecoveryVerificationModal,
      onOpenRegistrationVerificationModal,
      onCloseModal,
    },
  ];
};
export default useAuth;
