import React, { useState } from 'react';
import cn from 'classnames';

// import { useGlobalContext } from '@/contexts/GlobalContext';
import { useIs3D } from '@/utils/Is3DContext';

import DimensionToggle from '@/utils/dimensionToggle';

import { ModeSwitchButton3DProps } from '@/types/ModeSwitchButton3D';

import './mode-switch-button-3d.sass';

const ModeSwitchButton3D: React.FC<ModeSwitchButton3DProps> = ({ variant }) => {
  const { is3D, setIs3D } = useIs3D();
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        setIs3D(!is3D);
        DimensionToggle({ is3D, setHovered });
      }}
      className={cn('mode-switch-button-3d')}
    >
      {variant === 'desktop' && (
        <div
          className={cn(
            'mode-switch-button-3d__desktop',
            `${is3D ? 'mode-switch-3d-active' : ''}`,
            `${hovered ? 'hovered' : ''}`,
          )}
        >
          <span className="mode-switch-button-3d-text">
            {is3D ? '2D' : '3D'}
          </span>
        </div>
      )}

      {variant === 'mobile' && (
        <div
          className={cn(
            'mode-switch-button-3d__bottom-navigate',
            `${is3D ? 'mode-switch-3d-active' : ''}`,
          )}
        >
          <span className={cn('backdrop-filter')}></span>
          <span className={cn('item-text')}>{is3D ? '2D' : '3D'}</span>
        </div>
      )}
    </div>
  );
};

export default ModeSwitchButton3D;
