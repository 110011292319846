import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';

import useClusterProductsManagement from '@/features/AccountRetailer/hooks/useClusterProductsManagement';
import ProductsListCards from '@/features/AccountRetailer/components/ProductsListCards';
import ProductForm from '@/features/AccountRetailer/components/ProductForm';
import { ShowNameAction } from '@/features/AccountRetailer/constants';

import AccountSidebar from '@/components/Account/AccountSidebar/AccountSidebar';
import HeaderFilterSearch from '@/components/Account/HeaderFilter/HeaderFilterSearch';
import HeaderFilter from '@/components/Account/HeaderFilter/HeaderFilter';
import FilterTabs from '@/components/Account/HeaderFilter/FilterTabs';
import Button from '@/components/ui/Button/Button';

import { accountMenuItems } from '@/data/constants';

import Modal from '@/layouts/Modal/Modal';

import '../retailer-products-management.sass';

import Plus from '@/assets/icons/plus.svg';
import Checkmark from '@/assets/icons/checkmark--outline__black.svg';

const AccountRetailerClustersManagement: React.FC = () => {
  const navigate = useNavigate();
  const { clusterId, productId } = useParams();

  const [
    { onSubmitLoading, isLoading },
    {
      productsData,
      initialValues,
      notification,
      showNameActionComponent,
      productAttributes,
      images,
    },
    {
      resetForm,
      setNotification,
      setShowNameActionComponent,
      onSubmit,
      handleDeleteItem,
      updateImages,
      setImages,
    },
  ] = useClusterProductsManagement(Number(clusterId), Number(productId));

  return (
    <div className="container account-container">
      <AccountSidebar menuItems={accountMenuItems} />

      {notification && (
        <>
          <Modal
            theme="secondary"
            onClose={() => {
              setNotification(null);
            }}
            overlay
          >
            {notification.type === 'error' && (
              <div
                className={cn(
                  'notification-window notification-window__white error',
                )}
              >
                <span className={cn('error-img__custom')}></span>
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
              </div>
            )}

            {notification.type === 'success' && (
              <div
                className={cn(
                  'notification-window notification-window__white error',
                )}
              >
                <img src={Checkmark} alt="Success" />
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
                <Button
                  onClick={() => {
                    setNotification(null);
                    navigate(`/account/clusters/${clusterId}/products`);
                    setShowNameActionComponent(ShowNameAction.View);
                  }}
                  size="m"
                  secondary
                >
                  Хорошо
                </Button>
              </div>
            )}

            {notification.type === 'remove' && (
              <>
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
                <div className={cn('group-buttons__modal')}>
                  <Button
                    onClick={() => {
                      setNotification(null);
                      setShowNameActionComponent(ShowNameAction.View);
                      navigate(`/account/clusters/${clusterId}/products`);
                      handleDeleteItem(Number(productId));
                      resetForm();
                    }}
                    size="m"
                    secondary
                  >
                    Да
                  </Button>
                  <Button
                    onClick={() => setNotification(null)}
                    size="m"
                    primary
                  >
                    Нет
                  </Button>
                </div>
              </>
            )}

            {notification.type === 'other' && (
              <>
                <span className={cn('modal-text')}>
                  {notification.messages}
                </span>
                <div className={cn('group-buttons__modal')}>
                  <Button
                    onClick={() => {
                      setNotification(null);
                      setShowNameActionComponent(ShowNameAction.View);
                      navigate(`/account/clusters/${clusterId}/products`);
                      resetForm();
                    }}
                    size="m"
                    secondary
                  >
                    Да
                  </Button>
                  <Button
                    onClick={() => setNotification(null)}
                    size="m"
                    primary
                  >
                    Нет
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </>
      )}

      <div className="account-container__card-list account-content">
        {showNameActionComponent === ShowNameAction.View && (
          <>
            <HeaderFilter>
              <div className="header-filter__content">
                <FilterTabs
                  list={[
                    productsData
                      ? productsData.title
                      : `Товарный кластер ${clusterId}`,
                  ]}
                />
                <div className="btns-group">
                  <Button
                    size="s"
                    primary
                    icon={Plus}
                    onClick={() => setShowNameActionComponent('new')}
                  >
                    Добавить продукт
                  </Button>
                </div>
              </div>
              <HeaderFilterSearch />
            </HeaderFilter>
            {productsData?.products && (
              <ProductsListCards
                items={productsData.products}
                isLoading={isLoading}
              />
            )}
          </>
        )}
        {(showNameActionComponent === ShowNameAction.New ||
          showNameActionComponent === ShowNameAction.Edit) && (
          <>
            <HeaderFilter>
              <div className="header-filter__content">
                {showNameActionComponent === ShowNameAction.New ? (
                  <>
                    <FilterTabs list={['Товар']} />
                    <div className="btns-group">
                      <Button
                        size="s"
                        secondary
                        border
                        onClick={() =>
                          setNotification({
                            messages: 'Вы точно хотите покинуть страницу?',
                            type: 'other',
                          })
                        }
                      >
                        Назад
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <FilterTabs list={[`${initialValues.product.name}`]} />
                    <div className="btns-group">
                      <Button
                        size="s"
                        secondary
                        border
                        onClick={() =>
                          setNotification({
                            messages: 'Вы действительно хотите удалить товар?',
                            type: 'remove',
                          })
                        }
                      >
                        Удалить
                      </Button>
                      <Button
                        size="s"
                        secondary
                        border
                        onClick={() =>
                          setNotification({
                            messages: 'Вы точно хотите покинуть страницу?',
                            type: 'other',
                          })
                        }
                      >
                        Назад
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </HeaderFilter>

            <div className={cn('cluster-product-management')}>
              <ProductForm
                showNameActionComponent={showNameActionComponent}
                onSubmitLoading={onSubmitLoading}
                isLoadingUpdate={isLoading}
                initialValues={initialValues}
                productAttributes={productAttributes}
                onSubmit={onSubmit}
                updateImages={updateImages}
                images={images}
                setImages={setImages}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountRetailerClustersManagement;
