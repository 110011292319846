import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import ProductDetailsProperty from '@/features/ProductCard/components/AuxiliaryComponents/ProductDetailsProperty';

import ShareDropdown from '@/components/ShareDropdown/ShareDropdown';

import { truncateText } from '../utils/textTransforms';

import { ProductDetailsPanelComponentProps } from '@/features/ProductCard/types';

import Star from '@/assets/icons/star-outline.svg';

const ProductDetailsPanelView: React.FC<ProductDetailsPanelComponentProps> = ({
  showShareDropdown,
  setShowShareDropdown,
  theme,
  productDetails,
  isMoreInfoShown,
  toggleMoreInfo,
  productShareUrl,
}) => {
  return (
    <div className={cn('product-details', theme)}>
      <div className={cn('product-details__code')}>
        Код товара: {productDetails?.article}
        <ShareDropdown
          url={productShareUrl}
          showShareDropdown={showShareDropdown}
          setShowShareDropdown={setShowShareDropdown}
        />
      </div>

      <div className={cn('product-details__title')}>
        {productDetails?.name}
        <div className={cn('rating')}>
          <img src={Star} alt="Star" />
          {productDetails?.reviews_stars}
        </div>
      </div>

      {!isMoreInfoShown && (
        <div className={cn('product-details__characteristics')}>
          {productDetails?.sizes && productDetails.sizes.length > 0 && (
            <ProductDetailsProperty
              nameCharacteristic="Размер"
              productCharacteristics={productDetails.sizes}
            />
          )}

          {productDetails?.colors && productDetails.colors.length > 0 && (
            <ProductDetailsProperty
              nameCharacteristic="Цвет"
              productCharacteristics={productDetails.colors}
            />
          )}
        </div>
      )}

      <div className={cn('product-details__description')}>
        <div
          className={cn('product-details__description-header details-title')}
        >
          О товаре
        </div>
        <div className={cn('product-details__description-text details-text')}>
          {!isMoreInfoShown
            ? truncateText(productDetails?.description || '', 90)
            : productDetails?.description}
        </div>
      </div>

      {isMoreInfoShown && (
        <div className={cn('product-details__retailer retailer')}>
          <div className={cn('retailer-title details-title')}>Продавец</div>
          <Link to="#" className={cn('retailer-content')}>
            <div className={cn('retailer-content__image')}></div>
            <div className={cn('retailer-content__info')}>
              <p className={cn('retailer-content__info name')}>
                Название продавца
              </p>
              <p className={cn('retailer-content__info pavilion-name')}>
                Павильон
              </p>
            </div>
            <span className={cn('retailer-content__goover btn__app')}>
              Перейти
            </span>
          </Link>
        </div>
      )}

      <div
        onClick={() => toggleMoreInfo()}
        className={cn('product-details__more')}
      >
        {!isMoreInfoShown ? '+ Больше о товаре' : 'Назад'}
      </div>
    </div>
  );
};

export default ProductDetailsPanelView;
