import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';

import FormError from '../components/FormError/ErrorMessage';
import { VerificationFormProps } from '../types/verification';

const VerificationForm: React.FC<VerificationFormProps> = ({
  className,
  initialValues,
  onSubmit,
}) => {
  return (
    <FinalForm
      id="verification-form"
      className={className}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <div className="auth-modal-content__inputs-block">
        <FormTextField
          name="verification_code"
          className="input"
          placeholder="Код"
        />
        <FormError />
      </div>
    </FinalForm>
  );
};
export default VerificationForm;
