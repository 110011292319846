import React, { useState } from 'react';
import cn from 'classnames';

import Auth from '@/features/Auth';
import CatalogueModal from '@/features/CatalogueModal';
import CatalogueBtn from '@/features/CatalogueModal/components/CatalogueBtn';
import ProductSearch from '@/features/ProductSearch';

import HeaderNav from '@/components/HeaderNav/HeaderNav';

import './header.sass';

import Logo from '@/layouts/Header/Logo';

const Header: React.FC = () => {
  const [showCatalogue, setShowCatalogue] = useState<boolean>(false);

  return (
    <>
      <header id="header" className={cn('header')}>
        <div className="header__wrapper container">
          <div className="backdrop-filter"></div>
          <Logo />
          <CatalogueBtn
            setShowCatalogue={setShowCatalogue}
            activeElement={showCatalogue}
          />
          <ProductSearch />
          <HeaderNav />
        </div>
      </header>
      {showCatalogue && <CatalogueModal setShowCatalogue={setShowCatalogue} />}
      <Auth />
    </>
  );
};

export default Header;
