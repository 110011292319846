import React from 'react';

import { CategoriesListItem } from '../types';
type CatalogueCategoryItemProps = Omit<CategoriesListItem, 'img' | 'text'>;

import arrowIcon from '@/assets/icons/catalogue/arrow_right.svg';

export const CatalogueCategoryItem: React.FC<CatalogueCategoryItemProps> = ({
  title,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      className={`catalogue-category-item ${
        isSelected ? 'list-item-selected' : 'list-item'
      }`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="catalogue-category-item__left">
        <span className="catalogue-category-item__title">{title}</span>
      </div>
      <div className="catalogue-category-item__arrow list-item-icon">
        <img src={arrowIcon} alt="" />
      </div>
    </div>
  );
};
