import { CreateOrderFormData, CreateOrderPayload } from '../types';

const serverFormatter = ({
  name,
  phone,
  address,
  notes,
}: CreateOrderFormData): CreateOrderPayload => {
  return {
    name: name,
    email: 'testmail@gmail.com',
    phone: phone,
    description: `${address} ${notes}`,
  };
};
export { serverFormatter };
