import { useAppDispatch } from '@/hooks';
import { CartProduct } from '@/types/entities/cart';
import { map } from 'lodash-es';
import { useMemo, useState } from 'react';
import {
  useGetCartQuery,
  useOnChangeCartItemMutation,
  useOnRemoveCartItemMutation,
} from '../services/cartApi';

import { setGlobalCartStates } from '../states/cartSlice';

import { UseCarts } from '../types/useCarts';

const useCart = (): UseCarts => {
  const [data, setData] = useState<CartProduct[] | undefined>();

  const { isLoading, data: cartData, refetch: catrRefetch } = useGetCartQuery();
  const dispatch = useAppDispatch();

  const [onChangeCartItem] = useOnChangeCartItemMutation();
  const [onRemoveCartItem] = useOnRemoveCartItemMutation();

  useMemo(() => {
    setData(cartData?.data.cart_products);
  }, [cartData]);

  const onChangeCart = async (id: number, count_products: number) => {
    try {
      const response = await onChangeCartItem({
        id,
        data: { count_products },
      }).unwrap();
      const { price, price_old } = response.data;
      setData(
        map(data, (item) =>
          item.id === id
            ? {
                ...item,
                count_products,
                price,
                price_old,
              }
            : item,
        ),
      );

      const {
        cart_products,
        count_total,
        delivery_price,
        price_total,
        price_with_delivery_total,
      } = response.data;

      dispatch(
        setGlobalCartStates({
          cart_products,
          count_total,
          price_total,
          delivery_price,
          price_with_delivery_total,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onIncrease = (cartItem: CartProduct) => () => {
    onChangeCart(cartItem.id, cartItem.count_products + 1);
  };
  const onDecrease = (cartItem: CartProduct) => () => {
    if (cartItem.count_products > 0)
      onChangeCart(cartItem.id, cartItem.count_products - 1);
  };

  const onRemove = (cartItem: CartProduct) => async () => {
    try {
      const response = await onRemoveCartItem({ id: cartItem.id }).unwrap();
      setData(data?.filter((dataItem) => dataItem.id !== cartItem.id));
      const {
        cart_products,
        count_total,
        delivery_price,
        price_total,
        price_with_delivery_total,
      } = response.data;
      dispatch(
        setGlobalCartStates({
          cart_products,
          count_total,
          price_total,
          delivery_price,
          price_with_delivery_total,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  return [
    {
      isLoading,
    },
    {
      data,
    },
    {
      onIncrease,
      onDecrease,
      onRemove,
      catrRefetch,
    },
  ];
};

export default useCart;
