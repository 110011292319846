import React from 'react';

import AccountMenuItem from '@/components/Account/AccountMenuItem/AccountMenuItem';

import { AccountMenuProps } from '@/types/menu';

import './account-menu.sass';

const AccountMenu: React.FC<AccountMenuProps> = ({ menuItems, arrow }) => {
  return (
    <div className="account-menu">
      {menuItems.map((item) => (
        <AccountMenuItem key={item.title} item={item} arrow={arrow} />
      ))}
    </div>
  );
};

export default AccountMenu;
