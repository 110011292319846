import React from 'react';
import cn from 'classnames';

import { initFavorites } from '@/utils/initFavorites';
import { useInitCart } from '@/utils/initCart';

import { useGlobalContext } from '@/contexts/GlobalContext';
import { useProduct } from '@/contexts/ProductContext';

import { useGetCategoriesQuery } from '@/features/CatalogueModal/services/categoriesApi';

import useAuthenticate from '@/hooks/useAuthenticate';

import useGuestInit from '@/features/Auth/hooks/useGuestInit';
import ProductCard from '@/features/ProductCard';
import Preloader from '@/features/Preloader/Preloader';
import ModeSwitchButton3D from '@/features/ModeSwitchButton3D';

import Modal from '@/layouts/Modal/Modal';
import Header from '@/layouts/Header/Header';
import Footer from '@/layouts/Footer/Footer';
import BottomNavigateBar from '@/layouts/BottomNavigateBar/BottomNavigateBar';

import '@/styles/global.sass';
import '@/styles/btns.sass';

import Logo from '@/assets/icons/logo/logo.svg';
import PointerLockCtrl from '@/assets/img/3d/PointerLockCtrl.svg';

type CommonLayoutProps = {
  children: React.ReactNode;
};

const CommonLayout = (props: CommonLayoutProps) => {
  const [{ userRole }] = useGlobalContext();
  const [, { getAuthUser }] = useAuthenticate();

  const { selectedProductKey, setSelectedProductKey } = useProduct();

  const isAccount =
    window.location.pathname.indexOf('account') !== -1 ||
    window.location.pathname.indexOf('user') !== -1;
  const contentClasses = cn('content', { 'account-page': isAccount });

  // Init Main
  getAuthUser();
  useGuestInit();
  useInitCart();
  initFavorites();

  useGetCategoriesQuery();

  return (
    <div className="App">
      {!isAccount && <Preloader />}

      {selectedProductKey && (
        <div className={cn('product-card__show-fixed')}>
          <span
            className={cn('overlay')}
            onClick={() => setSelectedProductKey(null)}
          ></span>
          <ProductCard />
        </div>
      )}

      {!isAccount && <span className={cn('overlay')}></span>}
      <div className={contentClasses}>
        {!isAccount && <Header />}
        <main className="main container">{props.children}</main>
        {!isAccount && <Footer />}
        {!isAccount && <BottomNavigateBar />}

        {isAccount && userRole === 'retailer' && (
          <Modal overlaySecondary classNames="account-blocker">
            <img src={Logo} alt="UNEMAG - инновационный маркетплейс" />
            <span className={cn('text-warning')}>
              Страницы личного кабинета партнёра не поддерживают мобильные
              устройства
            </span>
          </Modal>
        )}
      </div>

      {!isAccount && <span id="touchCtrl"></span>}

      {!isAccount && (
        <img id="pointer-lock" src={PointerLockCtrl} alt="PointerLockCtrl" />
      )}

      {!isAccount && <ModeSwitchButton3D variant="desktop" />}
    </div>
  );
};
export default CommonLayout;
