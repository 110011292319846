import React from 'react';
import cn from 'classnames';
import { ProductDetailsPanelComponentPropsEdit } from '@/features/ProductCard/types';

const ProductDetailsPanelEdit: React.FC<
  ProductDetailsPanelComponentPropsEdit
> = () => {
  return <div className={cn('specifications-wrapper')}>Редактирование</div>;
};

export default ProductDetailsPanelEdit;
