import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useProduct } from '@/contexts/ProductContext';

import useAddToCart from '@/hooks/useAddToCart';
import { useProductCardSmall } from '@/features/ProductCardSmall/hooks/useProductCardSmall';

import { ProductSelectedOptionsPanelProps } from '@/features/ProductCard/types';

import HeartOutline from '@/assets/icons/product/favorite.svg';
import Heart from '@/assets/icons/product/favorite--filled.svg';

const ProductSelectedOptionsPanel: React.FC<
  ProductSelectedOptionsPanelProps
> = ({ productOptionSelect, product_id, productRefetch }) => {
  const [{ isLoading }, { countProduct }, { onChangeCountToCart }] =
    useAddToCart(Number(product_id));
  const { setSelectedProductKey } = useProduct();

  const [, { toggleFavorite }] = useProductCardSmall();

  return (
    <div className={cn('product-select-option-panel')}>
      <div className={cn('product-select-option-panel__prices')}>
        <div
          className={cn(
            'product-select-option-panel__price',
            `${productOptionSelect?.price_old && productOptionSelect?.price_old.length > 0 ? '--discounted' : ''}`,
          )}
        >
          {productOptionSelect?.price}
        </div>
        {productOptionSelect?.price_old &&
          productOptionSelect?.price_old.length > 0 && (
            <div className={cn('product-select-option-panel__price --current')}>
              {productOptionSelect?.price_old}
            </div>
          )}
      </div>

      <div className={cn('product-select-option-panel__specifications')}>
        {productOptionSelect?.sizes &&
          productOptionSelect?.sizes?.length > 0 && (
            <div
              className={cn(
                'product-select-option-panel__specification --size',
              )}
            >
              {productOptionSelect?.sizes?.map((item) =>
                item.active ? item.name : null,
              )}
            </div>
          )}

        {productOptionSelect?.colors &&
          productOptionSelect?.colors.length > 0 && (
            <div
              className={cn(
                'product-select-option-panel__specification --color',
              )}
            >
              {productOptionSelect?.colors?.map((item) =>
                item.active ? item.name : null,
              )}
            </div>
          )}
      </div>

      <div className={cn('product-actions')}>
        {countProduct > 0 ? (
          <div className={cn('product-actions__cart')}>
            <Link
              to="/cart"
              className={cn('cart-link')}
              onClick={() => setSelectedProductKey(null)}
            >
              <h2>Товар в корзине</h2>
              <p>Перейти в корзину</p>
            </Link>

            <span
              onClick={() => onChangeCountToCart(product_id, -1)}
              className={cn(
                'product-actions__cart cart-items__subtract btn__app',
              )}
            ></span>
            <span
              className={cn('product-actions__cart cart-items__count')}
            >{`${countProduct} Шт.`}</span>
            <span
              onClick={() => onChangeCountToCart(product_id, 1)}
              className={cn('product-actions__cart cart-items__add btn__app')}
            ></span>
          </div>
        ) : (
          <button
            disabled={isLoading}
            className={cn('product-actions cart-items__add btn__app')}
            onClick={() => onChangeCountToCart(product_id, 1)}
          >
            Добавить в корзину
          </button>
        )}
        <span
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            toggleFavorite(
              e,
              product_id,
              productOptionSelect?.is_star || false,
            );
            productRefetch();
          }}
          className={cn('product-actions__favorite btn__app')}
        >
          {productOptionSelect?.is_star ? (
            <img src={Heart} alt="Favorite" />
          ) : (
            <img src={HeartOutline} alt="Favorite" />
          )}
        </span>
      </div>
    </div>
  );
};

export default ProductSelectedOptionsPanel;
