import { lazy, Suspense } from 'react';
import {
  APPLICATION_URLS,
  USER_CABINET_URLS,
  RETAILER_URLS,
} from '@/constants/urls';

import Spinner from '@/components/Spinner/Spinner';

const HomePage = lazy(
  () =>
    import(
      /* webpackChunkName: "home" */
      '../pages/Home'
    ),
);

import CatalogPage from '@/features/CatalogPage';
import ProductPage from '@/features/ProductCard';

import UserCabinetMain from '@/pages/user/UserCabinetMain';
import UserCabinetOrders from '@/pages/user/UserCabinetOrders';

import AccountStatistics from '@/pages/AccountStatistics';
import AccountRetailerClusters from '@/pages/retailer/AccountRetailerClusters';
import AccountRetailerProducts from '@/pages/retailer/AccountRetailerProducts';
import AccountPavilions from '@/pages/AccountPavilions';
import AccountPricesAndDiscounts from '@/pages/AccountPricesAndDiscounts';
import AccountSupplies from '@/pages/AccountSupplies';
import AccountChat from '@/pages/AccountChat';
import AccountPromotion from '@/pages/AccountPromotion';
import AccountStock from '@/pages/AccountStock';
import AccountRatings from '@/pages/AccountRatings';
import AccountSupport from '@/pages/AccountSupport';
import AccountDocuments from '@/pages/AccountDocuments';

import CartPage from '@/pages/CartPage';

import PavilionBuilderLayout from '@/features/PavilionBuilderLayout';

import ProductSearchPage from '@/pages/ProductSearchPage';

const StaticPages = lazy(
  () =>
    import(
      /* webpackChunkName: "staticPages" */
      '../pages/StaticPages'
    ),
);

const UserAgreementPage = lazy(
  () =>
    import(
      /* webpackChunkName: "userAgreement" */
      '../pages/UserAgreement'
    ),
);

const AccountStandardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "accountStandard" */
      '../pages/AccountStandard'
    ),
);

const FavoritesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "favorites" */
      '../pages/Favorites'
    ),
);

const APPLICATION_ROUTES = [
  {
    exact: true,
    path: APPLICATION_URLS.root,
    element: (
      <Suspense fallback={<Spinner />}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.catalog,
    element: (
      <Suspense fallback={<Spinner />}>
        <CatalogPage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.product,
    element: (
      <Suspense fallback={<Spinner />}>
        <ProductPage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.cart,
    element: (
      <Suspense fallback={<Spinner />}>
        <CartPage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.staticPages,
    element: (
      <Suspense fallback={<Spinner />}>
        <StaticPages />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.userAgreement,
    element: (
      <Suspense fallback={<Spinner />}>
        <UserAgreementPage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.me,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountStandardPage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.favorites,
    element: (
      <Suspense fallback={<Spinner />}>
        <FavoritesPage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.product_search,
    element: (
      <Suspense fallback={<Spinner />}>
        <ProductSearchPage />
      </Suspense>
    ),
  },
];

const USER_CABINET_ROUTES = [
  {
    path: USER_CABINET_URLS.cabinet,
    element: (
      <Suspense fallback={<Spinner />}>
        <UserCabinetMain />
      </Suspense>
    ),
  },
  {
    path: USER_CABINET_URLS.orders,
    element: (
      <Suspense fallback={<Spinner />}>
        <UserCabinetOrders />
      </Suspense>
    ),
  },
];

const AUTH_RETAILER_ROUTES = [
  {
    path: RETAILER_URLS.root,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountStatistics />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.clusters,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountRetailerClusters />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.cluster_edit,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountRetailerClusters />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.products,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountRetailerProducts />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.products_edit,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountRetailerProducts />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.pavilion_builder,
    element: (
      <Suspense fallback={<Spinner />}>
        <PavilionBuilderLayout />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.pavilions,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountPavilions />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.documents,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountDocuments />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.supplies,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountSupplies />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.prices_and_discounts,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountPricesAndDiscounts />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.chat,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountChat />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.promotion,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountPromotion />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.stock,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountStock />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.ratings,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountRatings />
      </Suspense>
    ),
  },
  {
    path: RETAILER_URLS.support,
    element: (
      <Suspense fallback={<Spinner />}>
        <AccountSupport />
      </Suspense>
    ),
  },
];

export { APPLICATION_ROUTES, USER_CABINET_ROUTES, AUTH_RETAILER_ROUTES };
