import useAuthenticate from '@/hooks/useAuthenticate';
import { User } from '@/types/entities/user';
import { FORM_ERROR } from 'final-form';
import { get } from 'lodash-es';
import { useOnLoginMutation } from '../services/authApi';
import { LoginFormData } from '../types/login';
import { UseLogin } from '../types/useLogin';

const useLogin = (): UseLogin => {
  const [onLogin, { isLoading }] = useOnLoginMutation();

  const [, { setAuthenticatedUser }] = useAuthenticate();

  const initialValues = {
    phone: '',
    password: '',
  };

  const onSubmit = async (data: LoginFormData) => {
    try {
      const user = await onLogin({
        user: { password: data.password, phone: data.phone },
      }).unwrap();
      if (user) {
        setAuthenticatedUser(user as User);
      }
    } catch (error) {
      return {
        [FORM_ERROR]: get(error, 'data.errors.message'),
        phone: 'Error',
        password: 'Error',
      };
    }
  };

  return [
    {
      isLoading,
    },
    {
      initialValues,
    },
    {
      onSubmit,
    },
  ];
};
export default useLogin;
