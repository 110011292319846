import React from 'react';
import Button from '@/components/ui/Button/Button';
import Input from '@/components/ui/Input/Input';

import ArrowsVertical from '@/assets/icons/arrows-vertical.svg';
import Search from '@/assets/icons/search.svg';

const HeaderFilterSearch: React.FC = () => {
  return (
    <div className="d-flex header-filter__search my-3">
      <Button icon={ArrowsVertical} size="m" secondary>
        По дате
      </Button>
      <Input placeholder="Название, описание" icon={Search} />
    </div>
  );
};

export default HeaderFilterSearch;
