import cn from 'classnames';

import Spinner from '@/components/Spinner/Spinner';
import CatalogueList from './components/CatalogueList';
import PavilionMap from '@/features/PavilionMap';
import CatalogueCategoriesList from './components/CatalogueCategoriesList';

import { useCatalogModal } from './hooks/useCatalogModal';
import { CatalogueModalProps } from './types/useCatalogModal';

import './catalogue-modal.sass';

import ListDot from '@/assets/icons/catalogue/list-i-dot.svg';
import MapLocation from '@/assets/icons/catalogue/map.svg';

const CatalogueModal = ({ setShowCatalogue }: CatalogueModalProps) => {
  const [
    { isLoading },
    {
      selectedMainCategory,
      selectedSecondaryCategory,
      mainCategories,
      secondCategories,
      selectPavilionCategories,
      typeCatalogueModal,
    },
    {
      onClickCategory,
      onEnterCategory,
      onLeaveCategory,
      setTypeCatalogueModal,
    },
  ] = useCatalogModal({ setShowCatalogue });

  return (
    <div className="catalogue-modal container">
      <div className={cn('catalogue-modal__select-types select-types')}>
        <span
          className={cn('select-types__item')}
          onClick={() => setTypeCatalogueModal('categories')}
        >
          <img src={ListDot} alt="Категории" /> Категории
        </span>
        <span
          className={cn('select-types__item')}
          onClick={() => setTypeCatalogueModal('map')}
        >
          <img src={MapLocation} alt="Карта" /> Карта
        </span>
        <span
          className={cn('item__highlight', {
            'item__highlight-right': typeCatalogueModal === 'map',
          })}
        ></span>
      </div>
      <div className={cn('catalogue-modal__inner')}>
        <div
          className={cn('catalogue-modal__categories-items', {
            'catalogue-modal__active': typeCatalogueModal === 'categories',
          })}
        >
          <div className="backdrop-filter"></div>
          <div className="catalogue-modal__row">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <div className={cn('catalogue-modal__column categories-main')}>
                  <CatalogueList
                    categories={mainCategories}
                    selectedCategory={selectedMainCategory}
                    onClick={onClickCategory}
                    onEnterCategory={onEnterCategory}
                    onLeaveCategory={onLeaveCategory}
                  />
                </div>
                <CatalogueCategoriesList
                  categories={secondCategories}
                  parentCategory={selectedMainCategory}
                  selectedCategory={selectedSecondaryCategory}
                  onClick={onClickCategory}
                  onEnterCategory={onEnterCategory}
                  onLeaveCategory={onLeaveCategory}
                />
              </>
            )}
          </div>
        </div>
        <PavilionMap
          typeAction="view"
          selectPavilionCategories={selectPavilionCategories}
          typeCatalogueModal={typeCatalogueModal}
          setShowCatalogue={setShowCatalogue}
          catalogStyle
        />
      </div>
    </div>
  );
};

export default CatalogueModal;
