import FormCheckBoxField from '@/components/Forms/check-box-field';
import FinalForm from '@/components/Forms/form';
import FormSelectField from '@/components/Forms/select-field';
import FormTextField from '@/components/Forms/text-field';

import { RootState } from '@/store/store';
import { phoneNumberMask } from '@/utils/numbers';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { createOrderFormProps } from '../../types/createOrderForm';

import FormOptionButtonField from '../OptionButttonField';

const CreateOrderForm = ({
  isLoading,
  paymentOptions,
  deliveryOptions,
  initialValues,
  onSubmit,
}: createOrderFormProps) => {
  const globalCartData = useSelector((state: RootState) => state.cart);

  const isDisabled = isLoading;
  return (
    <FinalForm
      id="checkout-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      className={'form-primary'}
    >
      <div className="cart-sidebar">
        <div className="cart-sidebar__data">
          <div className="cart-sidebar__backdrop-filter backdrop-filter"></div>
          <div className="cart-sidebar__data-body">
            <div className="cart-sidebar__section">
              <p className="cart-sidebar__heading">Информация получателя</p>
              <div className="cart-sidebar__inputs">
                <FormTextField
                  disabled={isDisabled}
                  required
                  showErrorInline={false}
                  name="name"
                  className="cart-sidebar__input input"
                  placeholder="Имя"
                />
                <FormTextField
                  disabled={isDisabled}
                  required
                  showErrorInline={false}
                  name="phone"
                  className="cart-sidebar__input input"
                  placeholder="Номер"
                  fieldProps={{ formatOnBlur: true, parse: phoneNumberMask }}
                />

                <FormOptionButtonField
                  name="delivery_option"
                  deliveryOptions={deliveryOptions}
                />

                <FormTextField
                  disabled={isDisabled}
                  required
                  showErrorInline={false}
                  name="address"
                  className="cart-sidebar__input input"
                  placeholder="Адрес"
                />

                <FormTextField
                  disabled={isDisabled}
                  showErrorInline={false}
                  name="notes"
                  className="cart-sidebar__input input"
                  placeholder="12345"
                />

                <p className="cart-sidebar__ghost-text">
                  Текст (дата доставки при выборе пункта)
                </p>
              </div>
            </div>

            <div className="cart-sidebar__data-block">
              <p className="cart-sidebar__heading">Способ оплаты</p>
              <FormSelectField
                disabled={isDisabled}
                required
                showErrorInline={false}
                isSearchable
                name="payment_type"
                placeholder="Выбрать"
                options={paymentOptions}
                fieldProps={{ formatOnBlur: true }}
              />
            </div>
          </div>
        </div>

        <div className="cart-sidebar__price">
          <div className="cart-sidebar__backdrop-filter backdrop-filter"></div>
          <div className="cart-sidebar__price-body">
            <div className="cart-sidebar__price-top">
              <div className="cart-sidebar__price-item">
                <span>{`Товары, ${globalCartData?.count_total}шт.`}</span>
                <span>{globalCartData?.price_total}</span>
              </div>

              <div className="cart-sidebar__price-item">
                <span>Доставка (включена в итого)</span>
                <span>{globalCartData?.delivery_price}</span>
              </div>

              <div className="cart-sidebar__price-item">
                <span>Итого</span>
                <span>{globalCartData?.price_with_delivery_total}</span>
              </div>
            </div>

            <FormSpy subscription={{ submitting: true, pristine: true }}>
              {({ submitting, pristine }) => {
                return (
                  <button
                    className="cart-sidebar__order-btn btn-primary"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Оплатить
                  </button>
                );
              }}
            </FormSpy>

            <FormCheckBoxField
              required
              showErrorInline={false}
              name="agreed_with_rules"
              className="cart-sidebar__checkbox-label"
              fieldProps={{ type: 'checkbox' }}
              endText={
                <span>
                  Соглашаюсь с правилами пользования торговой площадкой и
                  возврата
                </span>
              }
            />
          </div>
        </div>
      </div>
    </FinalForm>
  );
};
export default CreateOrderForm;
