import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import truncateString from '@/utils/truncateString';

import { useProductCardSmall } from '@/features/ProductCardSmall/hooks/useProductCardSmall';

import Button from '@/components/ui/Button/Button';

import { CartItemProps } from '@/features/Cart/types/useCarts';

import './cart-item.sass';

import deleteIcon from '@/assets/icons/cart/removeIcon.svg';
import primaryMinusButton from '@/assets/icons/cart/primaryMinusButton.svg';
import primaryPlusButton from '@/assets/icons/cart/primaryPlusButton.svg';

import favoriteOutline from '@/assets/icons/product/favorite.svg';
import favoriteFilled from '@/assets/icons/product/favorite--filled.svg';

const CartItem = ({
  cartItem,
  onIncrease,
  onDecrease,
  onRemove,
  catrRefetch,
}: CartItemProps) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, { toggleFavorite }] = useProductCardSmall();

  return (
    <div className="cart-item">
      <Link
        to={`/products/${cartItem.product_id}`}
        className={cn('cart-item__link')}
      >
        <div
          className="cart-item__img"
          style={{ backgroundImage: `url(${cartItem.image})` }}
        ></div>

        <div className="cart-item__title-block">
          <p className="cart-item__title">
            {truncateString(cartItem.name, 100)}
          </p>
          <div className="cart-item__title-block-bottom">
            <div className="cart-item__tags">
              {cartItem.size && cartItem.size !== '-' && (
                <div className="cart-item__tag">{cartItem.size}</div>
              )}
              {cartItem.color && cartItem.size !== '-' && (
                <div className="cart-item__tag">{cartItem.color}</div>
              )}
            </div>
          </div>
        </div>
      </Link>

      <div className="cart-item__actions item-actions">
        <div className="item-actions__count-block">
          <Button
            icon={primaryMinusButton}
            className="item-actions__count-decrease"
            onClick={onDecrease(cartItem)}
          />
          <span className="cart-item__count">
            {`${cartItem.count_products} Шт.`}
          </span>
          <Button
            icon={primaryPlusButton}
            className="item-actions__count-increase"
            onClick={onIncrease(cartItem)}
          />
        </div>

        <div className="cart-item__price-block">
          <div>
            <p className="cart-item__big-price">{cartItem.price} </p>
            <p className="cart-item__sml-price">{cartItem.price_old}</p>
          </div>

          <div className="cart-item__price-icons">
            <Button
              className="cart-item__price-delete-icon"
              icon={deleteIcon}
              size={'m'}
              onClick={onRemove(cartItem)}
            />
            <Button
              className="cart-item__price-heart"
              icon={cartItem.is_star ? favoriteFilled : favoriteOutline}
              size={'m'}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                toggleFavorite(e, cartItem.product_id, cartItem.is_star);
                catrRefetch();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
