import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';
import { phoneNumberMask } from '@/utils/numbers';

import { LoginFormProps } from '../types/login';
import FormError from '../components/FormError/ErrorMessage';

const LoginForm: React.FC<LoginFormProps> = ({
  className,
  initialValues,
  onSubmit,
}) => {
  return (
    <FinalForm
      id="login-form"
      className={className}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <div className="auth-modal-content__inputs-block">
        <FormTextField
          showErrorInline={false}
          name="phone"
          className="input"
          placeholder="Телефон"
          fieldProps={{ formatOnBlur: true, parse: phoneNumberMask }}
        />
        <FormTextField
          showErrorInline={false}
          name="password"
          className="input"
          placeholder="Пароль"
          fieldProps={{ type: 'password' }}
        />
        <FormError />
      </div>
    </FinalForm>
  );
};
export default LoginForm;
