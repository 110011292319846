const CHARACTERISTICS = 'CHARACTERISTICS';
const MORE_INFORMATION = 'MORE_INFORMATION';
enum PRODUCT_DETAILS_PANEL_MODE {
  VIEW = 'PRODUCT_DETAILS_PANEL_VIEW',
  EDIT = 'PRODUCT_DETAILS_PANEL_EDIT',
}

const SLIDER_SETTINGS = {
  arrows: true,
  adaptiveHeight: true,
  infinite: true,
  dots: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 1,
};

export {
  CHARACTERISTICS,
  MORE_INFORMATION,
  PRODUCT_DETAILS_PANEL_MODE,
  SLIDER_SETTINGS,
};
