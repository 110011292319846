import React, { useState, useEffect, ReactNode } from 'react';
import md5 from 'crypto-js/md5';
import cn from 'classnames';

import './protected-app.sass';

interface UserData {
  login: string;
  password: string;
}

interface ProtectedAppProps {
  children: ReactNode;
}

const ProtectedApp: React.FC<ProtectedAppProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData>({
    login: '',
    password: '',
  });

  // Заранее определенный MD5 хеш пароля
  const hashedPassword: string = '1c63129ae9db9c60c3e8aa94d3e00495';

  useEffect(() => {
    const userFront = sessionStorage.getItem('userFront');
    if (userFront) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    if (md5(userData.password).toString() === hashedPassword) {
      sessionStorage.setItem('userFront', 'true');
      setIsAuthenticated(true);
    } else {
      alert('Неверный пароль!');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  if (!isAuthenticated) {
    return (
      <div className={cn('protected-app')}>
        <span className={cn('overlay')}></span>
        <div className={cn('protected-app__form')}>
          <h1>Вход в систему</h1>
          <div className="input-wrapper">
            <input
              type="text"
              name="login"
              value={userData.login}
              onChange={handleInputChange}
              placeholder="Введите Логин"
            />
          </div>
          <div className="input-wrapper">
            <input
              type="password"
              name="password"
              value={userData.password}
              onChange={handleInputChange}
              placeholder="Введите пароль"
            />
          </div>
          <button onClick={handleLogin}>Войти</button>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default ProtectedApp;
