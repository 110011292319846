import React from 'react';
import ProductDetailsPanelView from './ProductDetailsPanelView';
import ProductDetailsPanelEdit from './ProductDetailsPanelEdit';

import { ProductDetailsPanelProps } from '@/features/ProductCard/types';
import { PRODUCT_DETAILS_PANEL_MODE } from '@/features/ProductCard/constants';

const ProductDetailsPanel: React.FC<ProductDetailsPanelProps> = ({
  showShareDropdown,
  setShowShareDropdown,
  mode,
  theme,
  productDetails,
  isMoreInfoShown,
  toggleMoreInfo,
  productShareUrl,
}) => {
  return (
    <>
      {mode === PRODUCT_DETAILS_PANEL_MODE.VIEW ? (
        <ProductDetailsPanelView
          showShareDropdown={showShareDropdown}
          setShowShareDropdown={setShowShareDropdown}
          theme={theme}
          productDetails={productDetails}
          isMoreInfoShown={isMoreInfoShown}
          toggleMoreInfo={toggleMoreInfo}
          productShareUrl={productShareUrl}
        />
      ) : (
        <ProductDetailsPanelEdit />
      )}
    </>
  );
};

export default ProductDetailsPanel;
