export interface Product {
  id: number;
  name: string;
  price_retail: string;
  price_wholesale: string;
  with_nominal: boolean;
  description: string;
  color?: string;
  count: number;
  material?: string;
  created_at: string;
  updated_at: string;
  image_urls: string[];
}

export interface ProductsState {
  data: Product[];
  isLoading: boolean;
  error: string | null;
}

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

interface FetchProductsRequestAction {
  type: typeof FETCH_PRODUCTS_REQUEST;
}

interface FetchProductsSuccessAction {
  type: typeof FETCH_PRODUCTS_SUCCESS;
  payload: ProductsState['data'];
}

interface FetchProductsFailureAction {
  type: typeof FETCH_PRODUCTS_FAILURE;
  payload: string;
}

export type ProductsActionTypes =
  | FetchProductsRequestAction
  | FetchProductsSuccessAction
  | FetchProductsFailureAction;
