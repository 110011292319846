const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const pluralizeReviews = (count: number) => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return `${count} оценок`;
  }
  switch (lastDigit) {
    case 1:
      return `${count} оценка`;
    case 2:
    case 3:
    case 4:
      return `${count} оценки`;
    default:
      return `${count} оценок`;
  }
};

export { truncateText, pluralizeReviews };
