import { apiSlice } from '@/services/apiSlice';
import {
  ToggleFavoriteResponse,
  ToggleFavoriteRequest,
} from '../types/useProductCardSmall';

export const productCardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    toggleFavoriteStatus: builder.mutation<
      ToggleFavoriteResponse,
      ToggleFavoriteRequest
    >({
      query: ({ productId }) => ({
        url: `/user_product_stars/checking?product_id=${productId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Favorites'],
    }),
  }),
});

export const { useToggleFavoriteStatusMutation } = productCardApiSlice;
