import { DimensionToggleProps } from '@/types/utils/dimensionToggle';

export const dimensionToggle = ({
  is3D,
  setHovered,
}: DimensionToggleProps): void => {
  document.querySelector('#pointer-lock')!.classList.toggle('show');

  const canvasZIndex = !is3D ? '1' : '-1';
  document.querySelectorAll('canvas').forEach((item) => {
    item.style.zIndex = canvasZIndex;
  });
  // const VRButton = document.querySelector('#VRButton') as HTMLElement;
  // VRButton.style.zIndex = newIs3D ? '1' : '-1';

  if (setHovered) {
    setHovered(false);
  }
};

export default dimensionToggle;
