import React, { createContext, useContext, useState } from 'react';

// Определение интерфейса для контекста
interface ProductContextType {
  selectedProductKey: string | null;
  setSelectedProductKey: (key: string | null) => void;
}

type ProductProviderProps = {
  children: React.ReactNode;
};

// Создание контекста с указанным интерфейсом
const ProductContext = createContext<ProductContextType | undefined>(undefined);

// Провайдер контекста
export const ProductProvider: React.FC<ProductProviderProps> = ({
  children,
}) => {
  const [selectedProductKey, setSelectedProductKey] = useState<string | null>(
    null,
  );

  return (
    <ProductContext.Provider
      value={{ selectedProductKey, setSelectedProductKey }}
    >
      {children}
    </ProductContext.Provider>
  );
};

// Хук для использования контекста
export const useProduct = (): ProductContextType => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider');
  }
  return context;
};
