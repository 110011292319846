import { Product } from '@/types/entities/product';
import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import truncateString from '@/utils/truncateString';

import { useProductCardSmall } from '@/features/ProductCardSmall/hooks/useProductCardSmall';

import { asNonNullable } from '@/utils/types';
import { ProductCardSmallProps } from './types';

import './product-card-small.sass';

import FavoriteOutline from '@/assets/icons/product/favorite-outline-red.svg';
import FavoriteFilled from '@/assets/icons/product/favorite--filled.svg';

const ProductCardSmall: React.FC<ProductCardSmallProps> = ({
  product,
  onProductClick,
}) => {
  const [productItem, setProductItem] = useState<Product>(
    asNonNullable(product),
  );
  const [{ isFavoriteHover }, { setIsFavoriteHover, toggleFavorite }] =
    useProductCardSmall();

  const handleFavoriteClick =
    (productId: number) => (event: React.MouseEvent<HTMLElement>) => {
      const updatedProductItem = {
        ...productItem,
        is_star: !productItem.is_star,
      };
      setProductItem(updatedProductItem);
      toggleFavorite(event, productId, !productItem.is_star);
    };
  return (
    <Link
      to={`/products/${product?.id}`}
      className="product-card-small card"
      onClick={onProductClick}
    >
      <div className="product-card-small__img-wrapper">
        <img
          className={cn('product-card-small__favorite')}
          src={
            productItem?.is_star || isFavoriteHover
              ? FavoriteFilled
              : FavoriteOutline
          }
          alt="Favorite"
          onClick={handleFavoriteClick(Number(product?.id))}
          onMouseEnter={
            !productItem?.is_star ? () => setIsFavoriteHover(true) : undefined
          }
          onMouseLeave={
            !productItem?.is_star ? () => setIsFavoriteHover(false) : undefined
          }
        />
        <img
          src={productItem?.image}
          alt={productItem?.name}
          className={cn('product-card-small__img-product')}
        />

        <span
          className={cn('product-card-small__discount', {
            'discount-none': !productItem?.discount,
          })}
        >
          {productItem?.discount || 0}
        </span>
      </div>
      <div className="product-card-small__text">
        <div className="product-card-small__prices">
          <span className="product-card-small__price">
            {productItem?.price}
          </span>
          <span className="product-card-small__price product-card-small__price-small">
            {productItem?.price_old}
          </span>
        </div>
        <span className="product-card-small__title">
          {truncateString(productItem?.name)}
        </span>
      </div>
    </Link>
  );
};

export default ProductCardSmall;
