import React from 'react';
import cn from 'classnames';

import { ListShelvesProps } from '@/features/PavilionBuilderLayout/types';
import Button from '@/components/ui/Button/Button';

const ListShelves: React.FC<ListShelvesProps> = ({
  selectShelvingId,
  shelves,
  setTypeManagement,
  setSelectPlace,
  setObjectList,
  setTypeAddItem,
  groupProductData,
  shelvingProducts,
}) => {
  const shelvesArray = Object.values(shelves || {});

  return (
    <div className={cn('shelves-list-container')}>
      <h2 className={cn('shelves-list-container__title')}>
        Стеллаж №{selectShelvingId}
      </h2>
      <p className={cn('shelves-list-container__description')}>Описание</p>

      <div className={cn('shelves-list-container__list-items')}>
        {shelvesArray.map((_shelve: any, index) => {
          const shelvingProduct = shelvingProducts.find(
            (product: any) => product.data.placeNumber === index + 1,
          );

          if (shelvingProduct) {
            return (
              <div
                key={index}
                className={cn('shelves-list-container__list-item')}
              >
                <div className={cn('list-item__content card-content')}>
                  <div className="img-wrapper">
                    <img
                      src={shelvingProduct.data.image}
                      alt={shelvingProduct.data.name}
                    />
                  </div>
                  <span className={cn('card-content__name')}>
                    {shelvingProduct.data.name}
                  </span>
                  <span className={cn('card-content__price')}>
                    <span>Цена:</span>
                    <span>{shelvingProduct.data.price || 0}</span>
                  </span>
                  <div className={cn('card-content__tools')}>
                    <span
                      className={cn('card-content__tools change')}
                      onClick={() => {
                        setTypeManagement?.('fill-goods');
                        setObjectList(groupProductData);
                        setSelectPlace(index + 1);
                        setTypeAddItem('Product');
                      }}
                    >
                      Изменить
                    </span>
                  </div>
                </div>
              </div>
            );
          } else {
            // Если продукт не найден, отображаем кнопку добавления
            return (
              <div
                key={index}
                className={cn('shelves-list-container__list-item')}
              >
                <span
                  className={cn('list-item__action')}
                  onClick={() => {
                    setTypeManagement?.('fill-goods');
                    setObjectList(groupProductData);
                    setSelectPlace(index + 1);
                    setTypeAddItem('Product');
                  }}
                >
                  <Button size="m" secondary>
                    Добавить товар
                  </Button>
                </span>
              </div>
            );
          }
        })}
      </div>

      <div className={cn('shelves-list-container__bottom-tools')}>
        <span
          className={cn('shelves-length')}
        >{`Доступно ячеек: ${shelvesArray?.length}`}</span>

        <div className="btns-tools">
          <Button onClick={() => setTypeManagement?.(null)} size="m" secondary>
            Закрыть
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ListShelves;
