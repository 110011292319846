import { apiSlice } from '@/services/apiSlice';
import { GetProductsFavoritesResponseData } from '../types/useProductsFavorites';

export const productsFavoritesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductsFavorites: builder.query<GetProductsFavoritesResponseData, void>(
      {
        query: () => '/user_product_stars',
        providesTags: ['Favorites'],
      },
    ),
  }),
});

export const { useGetProductsFavoritesQuery } = productsFavoritesApiSlice;
