import React from 'react';

import { phoneNumberMask } from '@/utils/numbers';

import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';
import FormError from '../components/FormError/ErrorMessage';
import FormCheckBoxField from '@/components/Forms/check-box-field';

import { REGISTER_USER_TYPE, RegistrationFormProps } from '../types/register';
import Button from '@/components/ui/Button/Button';

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  className,
  initialValues,
  registerUserType,
  onOpenPolicy,
  onSubmit,
}) => {
  const canShowOferta = registerUserType === REGISTER_USER_TYPE.RETAILER;
  return (
    <FinalForm
      id="registration-form"
      className={className}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <div className="auth-modal-content__inputs-block">
        <FormTextField name="full_name" className="input" placeholder="ФИО" />
        <FormTextField
          name="phone"
          className="input"
          placeholder="Телефон"
          fieldProps={{ formatOnBlur: true, parse: phoneNumberMask }}
        />
        <FormTextField
          name="password"
          className="input"
          placeholder="Пароль"
          fieldProps={{ type: 'password' }}
        />
        <FormTextField
          name="password_confirmation"
          className="input"
          placeholder="Пароль"
          fieldProps={{ type: 'password' }}
        />

        <FormCheckBoxField
          required
          showErrorInline={false}
          name="politika"
          className="registration-agreement"
          fieldProps={{ type: 'checkbox' }}
          endText={
            <span>
              Я согласен на&nbsp;
              <Button
                className="link-button"
                onClick={onOpenPolicy('politika')}
              >
                обработку персональных данных
              </Button>
            </span>
          }
        />

        {canShowOferta && (
          <FormCheckBoxField
            required
            showErrorInline={false}
            name="oferta"
            className="registration-agreement"
            fieldProps={{ type: 'checkbox' }}
            endText={
              <span>
                Я согласен с&nbsp;
                <Button
                  className="link-button"
                  onClick={onOpenPolicy('terms_of_use_politika')}
                >
                  условиями работы маркетплейса
                </Button>
              </span>
            }
          />
        )}

        <FormError />
      </div>
    </FinalForm>
  );
};
export default RegistrationForm;
