import { apiSlice } from '@/services/apiSlice';
import {
  GetProductResponseData,
  GetProductsInGroupProductResponseData,
} from '@/features/CatalogPage/types';

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductEdit: builder.query<GetProductResponseData, number>({
      query: (productId) => `/products/${productId}/edit`,
    }),
    getProductsData: builder.query<
      GetProductsInGroupProductResponseData,
      number
    >({
      query: (clusterId) => `/products/my_index?group_product_id=${clusterId}`,
    }),
    createProduct: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: '/products',
        method: 'POST',
        body: formData,
      }),
    }),
    updateProduct: builder.mutation<
      void,
      { productId: number; formData: FormData }
    >({
      query: ({ productId, formData }) => ({
        url: `/products/${productId}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteProduct: builder.mutation<void, number>({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteImageProduct: builder.mutation<void, number>({
      query: (id) => ({
        url: `/product_images/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetProductEditQuery,
  useGetProductsDataQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useDeleteImageProductMutation,
} = productApiSlice;
