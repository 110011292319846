import { useOnCreateOrderMutation } from '../services/cartApi';
import { CreateOrderFormData } from '../types';
import { UseCreateOrder } from '../types/useCreateOrder';
import { serverFormatter } from '../utils';

const useCreateOrder = (): UseCreateOrder => {
  const deliveryOptions = [
    {
      label: 'На дом',
      value: 'to-home',
    },
    {
      label: 'Пункт выдачи',
      value: 'pick-up-point',
    },
  ];

  const paymentOptions = [
    {
      label: 'Оплата наличными',
      value: 'cash',
    },
    {
      label: 'Банковской картой',
      value: 'bank-card',
    },
  ];

  const initialValues = {
    name: '',
    phone: '',
    delivery_option: { label: 'На дом', value: 'to-home' },
    address: '',
    notes: '',
    payment_type: undefined,
    usePoints: false,
    agreed_with_rules: false,
    promocode: '',
  };

  const [onCreateOrder, { isLoading }] = useOnCreateOrderMutation();

  const onSubmit = async (formData: CreateOrderFormData) => {
    try {
      const { data } = await onCreateOrder(serverFormatter(formData)).unwrap();
      const url = data.url_pay;
      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  };

  return [
    {
      isLoading,
    },
    {
      initialValues,
      deliveryOptions,
      paymentOptions,
    },
    {
      onSubmit,
    },
  ];
};
export default useCreateOrder;
