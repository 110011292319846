import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';

import { useGlobalContext } from '@/contexts/GlobalContext';

import usePavilionUsers from '@/features/PavilionBuilderLayout/hooks/usePavilionUsers';
import ListShelves from '@/features/PavilionBuilderLayout/components/ListShelves';

import Modal from '@/layouts/Modal/Modal';

import Button from '@/components/ui/Button/Button';
import ListItemsAdd from '@/components/ListItemsAdd/ListItemsAdd';
import AccountSidebar from '@/components/Account/AccountSidebar/AccountSidebar';

import { accountMenuItems } from '@/data/constants';

import { ManagementType } from '@/features/PavilionBuilderLayout/types';

import './pavilion-builder-layout.sass';

import Shelving_1 from '@/assets/shelving/shelving-1.png';
import Shelving_2 from '@/assets/shelving/shelving-2.png';

import FullView from '@/assets/icons/full-view.svg';
import Moving from '@/assets/icons/moving.svg';
import Rotate from '@/assets/icons/rotate.svg';
import TrashIcon from '@/assets/icons/trash-icon.svg';

const shelving = [
  {
    id: 1,
    name: 'Стеллаж №1',
    size: '25х15х10',
    characteristic: '26 ячеек',
    image: Shelving_1,
    alias: 'shelving20',
  },
  {
    id: 2,
    name: 'Стеллаж №2',
    size: '10х5х5',
    characteristic: '5 ячеек',
    image: Shelving_2,
    alias: 'shelving8',
  },
  {
    id: 3,
    name: 'Стеллаж №3',
    size: '25х15х10',
    characteristic: '26 ячеек',
    image: Shelving_1,
    alias: 'tableAvarage',
  },
  {
    id: 4,
    name: 'Стеллаж №4',
    size: '10х5х5',
    characteristic: '5 ячеек',
    image: Shelving_2,
    alias: 'shelvingCloth',
  },
];

const PavilionBuilderLayout: React.FC = () => {
  const [{ loadModels, pavilionBuilder }] = useGlobalContext();

  const [
    { isPavilionAvailable },
    {
      groupProductData,
      typeAddItem,
      showMessage,
      deleteConfirm,
      notificationMessage,
      shelves,
      objectList,
      currentObject,
      selectItemId,
      selectShelvingId,
      selectProductId,
      typeManagement,
      selectPlace,
    },
    { pavilionData, dataLocalesObject },
    {
      setIsPavilionAvailable,
      setTypeAddItem,
      defaultView,
      saveTemplatePavilion,
      savePavilion,
      setShowMessage,
      setDeleteConfirm,
      setShelves,
      setObjectList,
      setCurrentObject,
      setSelectItemId,
      setSelectShelvingId,
      setSelectProductId,
      setTypeManagement,
      setSelectPlace,
    },
  ] = usePavilionUsers();

  useEffect(() => {
    const mainContainer = document.querySelector('.main.container');

    const canvasScene = document.querySelector('canvas');
    if (canvasScene) {
      canvasScene.classList.add('pavilion-builder-layout__scene');
    }

    if (pavilionBuilder) {
      console.log(pavilionBuilder);
      pavilionBuilder.makePavilion({ model: 'Green', pavilionNumber: 1 });
      pavilionBuilder.render();

      if (mainContainer) {
        mainContainer.classList.remove('container');
        mainContainer.classList.add('block-position-fixed');
      }

      const canvasScene = document.querySelector('canvas');
      if (canvasScene) {
        canvasScene.classList.add('pavilion-builder-layout__scene');
      }

      pavilionBuilder.addEventListener('selected-shelving', (e: any) => {
        setSelectShelvingId(e.obj.object.id);
        setShelves(e.obj.place);
      });
    }

    return () => {
      if (mainContainer) {
        mainContainer.classList.add('container');
        mainContainer.classList.remove('block-position-fixed');
      }
      if (pavilionBuilder) {
        pavilionBuilder.dispose();
        pavilionBuilder.render(false);
      }
      if (canvasScene) {
        canvasScene.classList.remove('pavilion-builder-layout__scene');
      }
    };
  }, [pavilionBuilder]);

  useEffect(() => {
    const checkPavilion = () => {
      if (!(pavilionBuilder && pavilionBuilder.pavilion)) {
        return;
      }
      setIsPavilionAvailable(true);
    };

    const intervalId = setInterval(checkPavilion, 100);

    if (
      isPavilionAvailable &&
      pavilionBuilder &&
      pavilionBuilder.importing &&
      loadModels &&
      pavilionData
    ) {
      clearInterval(intervalId);
      pavilionBuilder.importing.loadJSON([pavilionData]);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isPavilionAvailable, pavilionBuilder, pavilionData, loadModels]);

  useEffect(() => {
    if (!pavilionBuilder) return;
    const handleSelectedShelving = (e: any) => {
      console.log(e.obj);
      setSelectShelvingId(e.obj.object.id);
      setShelves(e.obj.place);
      setCurrentObject(e.obj);
    };

    const handleDeselectObject = () => {
      setCurrentObject(null);
    };

    pavilionBuilder.addEventListener(
      'selected-shelving',
      handleSelectedShelving,
    );
    pavilionBuilder.addEventListener(
      'selected-product',
      handleSelectedShelving,
    );
    pavilionBuilder.addEventListener('deselect-object', handleDeselectObject);

    return () => {
      pavilionBuilder.removeEventListener(
        'selected-shelving',
        handleSelectedShelving,
      );
      pavilionBuilder.removeEventListener(
        'selected-product',
        handleSelectedShelving,
      );
      pavilionBuilder.removeEventListener(
        'deselect-object',
        handleDeselectObject,
      );
    };
  }, [pavilionBuilder]);

  useEffect(() => {
    if (currentObject === null) {
      setSelectItemId(null);
      setSelectProductId(null);
      setSelectShelvingId(null);
      setTypeManagement(null);
    }
  }, [currentObject]);

  const changeEventItem = useCallback(
    (typeEvent: string): void => {
      if (pavilionBuilder) {
        switch (typeEvent) {
          case 'translate':
            pavilionBuilder.objectControl.setMode('translate');
            break;
          case 'rotate':
            pavilionBuilder.objectControl.setMode('rotate');
            break;
          case 'fill-goods':
            setTypeManagement('fill-goods');
            break;
          case 'shelving-management':
            setTypeManagement('shelving-management');
            break;
          case 'delete':
            setDeleteConfirm(true);
            break;
          case 'defaultSet':
            if (pavilionBuilder.curObject?.defaultSet) {
              pavilionBuilder.curObject.defaultSet();
            }
            break;
          default:
            console.log('err');
            break;
        }
      } else {
        console.log('Builder не инициализирован.');
      }
    },
    [pavilionBuilder, selectItemId],
  );

  const addObject = useCallback(
    (objectType: string, name: string, additionalParams: object): void => {
      if (pavilionBuilder) {
        pavilionBuilder.makeObject(objectType, name, additionalParams);
      }
    },
    [pavilionBuilder],
  );

  const addItem = useCallback(
    (type: ManagementType, placeNumber?: number) => {
      if (pavilionBuilder && selectItemId && objectList) {
        const objectItem = objectList.find((item) => item.id === selectItemId);

        if (objectItem?.alias) {
          console.log('objectItem');
          console.log(objectItem);
          pavilionBuilder.makeObject(typeAddItem, objectItem.alias, {
            place: selectPlace,
            data: {
              id: objectItem.id,
              placeNumber: placeNumber,
              name: objectItem.name,
              image: objectItem.image,
              alias: objectItem.alias,
              product_id: objectItem.product_id,
            },
          });
        }

        setTypeManagement(type);
        setShowMessage(true);
        setSelectItemId(null);
        setObjectList(null);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      } else {
        console.log('Builder is not initialized yet');
      }
    },
    [pavilionBuilder, selectItemId],
  );

  const onRemoveItem = useCallback(() => {
    setTypeManagement('delete');
    if (pavilionBuilder) {
      pavilionBuilder.removeObject();
    }
    setTypeManagement(null);
    setSelectShelvingId(null);
    setDeleteConfirm(false);
  }, [pavilionBuilder, selectShelvingId]);

  const validateFile = (file: File) => {
    if (!file) {
      console.error('No file selected.');
      return false;
    }

    if (file.type !== 'application/json' && !file.name.endsWith('.json')) {
      console.error('The file is not a JSON file.');
      return false;
    }

    return true;
  };

  // const readFileAsJson = (file: File) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       try {
  //         // const json = JSON.parse(reader.result);
  //         // resolve(json);
  //       } catch (error) {
  //         reject(new Error('Failed to parse JSON.'));
  //       }
  //     };
  //     reader.onerror = () => reject(reader.error);
  //     reader.readAsText(file);
  //   });
  // };

  const triggerFileInputClick = () => {
    // document.getElementById('file-input').click();
  };

  const loadTemplatePavilion = async (e: any) => {
    const file = e.target.files[0];
    if (!validateFile(file)) {
      return;
    }
    if (!pavilionBuilder) {
      return;
    }

    try {
      // const jsonData = await readFileAsJson(file);
      // builder.importing.loadJSON(jsonData);
    } catch (error) {
      // console.error('Error loading template:', error.message);
    }
  };

  return (
    <div className="account-container pavilion-builder-layout block-position-fixed">
      <AccountSidebar menuItems={accountMenuItems} isHidden />
      <Button
        size="account-btn"
        className={cn('add-item__btn')}
        white
        onClick={() => {
          setTypeAddItem('Shelving');
          setObjectList(shelving);
        }}
      >
        <span className={cn('plus')}></span>
        Добавить оборудование
      </Button>

      <Button
        size="account-btn"
        className={cn('rollback-center__btn')}
        white
        onClick={defaultView}
      >
        Вернуться в центр павильона
      </Button>

      <div className={cn('pavilion-builder-layout__btns-tools-layout')}>
        <Button
          size="account-btn"
          white
          onClick={() => (pavilionData ? savePavilion() : null)}
        >
          Сохранить павильон
        </Button>
        <Button size="account-btn" white onClick={() => saveTemplatePavilion()}>
          Сохранить шаблон
        </Button>
        <Button size="account-btn" white onClick={triggerFileInputClick}>
          <input
            id={cn('file-input')}
            type="file"
            onChange={loadTemplatePavilion}
            style={{ display: 'none' }}
          />
          Загрузить шаблон
        </Button>
      </div>

      {objectList && (
        <ListItemsAdd
          typeManagement={typeManagement}
          title={
            typeManagement === 'fill-goods'
              ? 'Добавление товара'
              : 'Добавить оборудование в павильон'
          }
          searchPlaceholder={
            typeManagement === 'fill-goods'
              ? 'Поиск товара'
              : 'Поиск оборудования'
          }
          objectList={objectList}
          selectShelvingId={selectShelvingId}
          selectItemId={selectItemId}
          selectPlace={selectPlace}
          setSelectItemId={setSelectItemId}
          setObjectList={setObjectList}
          listType={typeManagement === 'fill-goods' ? 'column' : 'row'}
          addItem={addItem}
          addObject={addObject}
        />
      )}

      {selectShelvingId && (
        <div
          className={cn('tools-container block-white-tool', {
            transparent: typeManagement === 'shelving-management',
          })}
        >
          {currentObject && typeManagement !== 'shelving-management' && (
            <div className={cn('tools-container__window-select')}>
              <h3>
                {currentObject.data.name ||
                  `${dataLocalesObject['shelving']} №${selectShelvingId || selectProductId}`}
              </h3>
              <div className={cn('btns-tools-container')}>
                {currentObject.type.toLowerCase() === 'shelving' && (
                  <span onClick={() => changeEventItem('fill-goods')}>
                    Заполнить товарами
                  </span>
                )}

                <span onClick={() => changeEventItem('shelving-management')}>
                  Режим управления
                </span>
                <span
                  onClick={() => changeEventItem('delete')}
                  className={cn('delete-btn')}
                >
                  Удалить
                </span>
              </div>
            </div>
          )}

          {currentObject && typeManagement === 'shelving-management' && (
            <>
              <div className={cn('info-item-management block-white-tool')}>
                <h2>Режим управления</h2>
                <p>
                  {`${dataLocalesObject['product']} №${selectShelvingId || selectProductId}`}
                </p>
              </div>

              <div className={cn('btns-tools-container')}>
                <Button
                  size="account-btn"
                  white
                  onClick={() => pavilionBuilder?.curObject?.defaultSet?.()}
                >
                  <img src={FullView} alt="Base position" />
                  Поставить в базовую позицию
                </Button>
                <Button
                  size="account-btn"
                  white
                  onClick={() => changeEventItem('translate')}
                >
                  <img src={Moving} alt="Moving" />
                  Переместить
                </Button>
                <Button
                  size="account-btn"
                  white
                  onClick={() => changeEventItem('rotate')}
                >
                  <img src={Rotate} alt="Rotate" />
                  Повернуть
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      {typeManagement === 'fill-goods' && (
        <ListShelves
          selectShelvingId={selectShelvingId}
          shelves={shelves}
          addObject={addObject}
          setSelectPlace={setSelectPlace}
          setTypeManagement={setTypeManagement}
          setObjectList={setObjectList}
          setTypeAddItem={setTypeAddItem}
          groupProductData={groupProductData}
          shelvingProducts={
            currentObject && currentObject.product
              ? Object.values(currentObject.product)
              : []
          }
        />
      )}

      <span
        className={cn('message-info block-white-tool', {
          'message-info__show': showMessage,
        })}
      >
        {!notificationMessage &&
          typeAddItem === 'Shelving' &&
          `Стеллаж №${selectItemId} добавлен в пространство павильона`}
        {!notificationMessage &&
          typeAddItem === 'Product' &&
          `Товар №${selectItemId} добавлен в стеллаж №${selectShelvingId}`}
        {notificationMessage}
      </span>

      {deleteConfirm && (
        <>
          <span className={cn('overlay')}></span>
          <Modal theme="secondary">
            <img className={cn('trash-icon')} src={TrashIcon} alt="Delete" />
            <span className={cn('modal-text')}>
              {`Вы точно хотите удалить ${dataLocalesObject['product']} №${selectShelvingId || selectProductId}`}
            </span>
            <div className="group-buttons__modal">
              <Button size="m" primary onClick={() => onRemoveItem()}>
                Да
              </Button>
              <Button size="m" grey onClick={() => setDeleteConfirm(false)}>
                Нет
              </Button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default React.memo(PavilionBuilderLayout);
