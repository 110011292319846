import React from 'react';

import Button from '@/components/ui/Button/Button';

import '@/features/AccountRetailer/components/StatisticComponents/Main/ChartStats/chart-stats.sass';

import hexagon from '@/assets/icons/catalogue/hexagon.svg';
import hexagonDark from '@/assets/icons/catalogue/hexagon-dark.svg';
import chart from '@/assets/icons/account/chart.svg';

const ChartStats: React.FC = () => {
  return (
    <div className="account__chart-stats col-8">
      <div className="account__inner">
        <div className="chart-stats__top">
          <div className="chart-stats__buttons">
            <Button icon={hexagon} size="m" primary>
              Текст
            </Button>
            <Button icon={hexagonDark} size="m" secondary>
              Текст
            </Button>
            <Button icon={hexagonDark} size="m" secondary>
              Текст
            </Button>
          </div>
          <div className="chart-stats__times">
            <Button size="m" secondary darkBorder>
              1д
            </Button>
            <Button size="m" secondary>
              1д
            </Button>
            <Button size="m" secondary>
              1д
            </Button>
          </div>
        </div>

        <div className="chart-stats__content mt-3">
          <div className="chart-stats__info">
            <div className="row">
              <p className="account-text account-text-m bold-text mb-1">
                Сводка на сегодня
              </p>
            </div>
            <div className="row mt-3">
              <p className="grey-text account-text-m bold-text">К выводу</p>
              <p className="account-text account-text-l bold-text mt-1">
                656 338 руб.
              </p>
              <div>
                <span className="chart-stats__dark-label">
                  +23 шт. от вчера
                </span>
              </div>
            </div>
            <div className="row mt-3">
              <p className="grey-text account-text-m bold-text">К выводу</p>
              <p className="account-text account-text-l bold-text mt-1">
                656 338 руб.
              </p>
              <div>
                <span className="chart-stats__dark-label">
                  +23 шт. от вчера
                </span>
              </div>
            </div>
            <div className="row mt-3">
              {/*<p className="grey-text account-text-m bold-text">*/}
              {/*  Обновлено в 20:01*/}
              {/*</p>*/}
            </div>
          </div>
          <div className="chart-stats__image">
            <img src={chart} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartStats;
