import React, { ReactNode } from 'react';

import './common-wrapper.sass';

type CommonWrapperProps = {
  children: ReactNode;
};

const CommonWrapper: React.FC<CommonWrapperProps> = ({ children }) => {
  return (
    <div className="account__main-wrapper">
      <div className="account__inner">{children}</div>
    </div>
  );
};

export default CommonWrapper;
