import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { useGlobalContext } from '@/contexts/GlobalContext';

// Components
import BreadCrumbs from '@/components/Shop/BreadCrumbs/BreadCrumbs';
// import Filters from '@/components/Shop/Filters/Filters';
import ProductCardSmall from '@/features/ProductCardSmall';

import './catalogPage.sass';
import useProducts from './hooks/useCatalogPage';

const Shop: React.FC = () => {
  const [{ myPavilion }] = useGlobalContext();
  const [{ isLoading }, { products, count }] = useProducts();

  useEffect(() => {
    if (myPavilion && products && products.length > 1) {
      products.forEach((product, index) => {
        if (index < 20 && product.product_3d_alias) {
          myPavilion.addProduct(
            index,
            product.product_3d_alias.trim(),
            product,
          );
        }
      });
    }
  }, [myPavilion, products]);

  return (
    <div className="container">
      <div id="shop__page">
        <div className="backdrop-filter"></div>
        <BreadCrumbs />

        {/* <div className="title__shop">
          <div className="name">
            Футболка
            <div className="number">345</div>
          </div>

          <div className="btn__app">Текст</div>
        </div>

        <Filters /> */}
      </div>

      <div className="product__list-container">
        {isLoading ? (
          <div className="spinner rotate" />
        ) : count === 0 ? (
          <div className="warning-message">
            {' '}
            Не нашли товары по этой категории...
          </div>
        ) : (
          <Scrollbars>
            <div className="products-list-container">
              {products?.map((product) => (
                <ProductCardSmall
                  key={`${product.name}${product.id}`}
                  product={product}
                />
              ))}
            </div>
          </Scrollbars>
        )}
      </div>
    </div>
  );
};

export default Shop;
