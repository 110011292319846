import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import truncateString from '@/utils/truncateString';

import Modal from '@/layouts/Modal/Modal';
import UploadFile from '@/features/AccountRetailer/components/UploadFile';

import Spinner from '@/components/Spinner/Spinner';

import { ClustersListCardsProps } from '@/features/AccountRetailer/types/productsList';

import ArrowItem from '@/assets/icons/arrow.svg';

const ClustersListCards: React.FC<ClustersListCardsProps> = ({
  items,
  fileInputRef,
  showUploadFile,
  setShowUploadFile,
  handleFileInputClick,
  handleFileChange,
  handleDrop,
  handleDragOver,
  isLoading,
}) => {
  return (
    <div className="list-cards">
      {isLoading && <Spinner />}
      {!isLoading && items.length > 0 ? (
        items.map((item) => (
          <div
            key={`${item.id}-${item.name}`}
            className={cn('list-cards__card')}
          >
            {!item.alias && (
              <span className={cn('card__not-3d-model')}>Нет 3D модели</span>
            )}
            <Link to={`${item.id}/edit`} className="img-wrapper">
              <span>Редактировать</span>
              {item.image && <img src={item.image} alt="Image" />}
            </Link>
            <div className="name">
              {truncateString(item.name, 30) || 'Без названия'}
            </div>{' '}
            <Link
              to={`${item.id}/products`}
              className={cn('list-cards__card-items')}
            >
              {`${item.count_products} товаров перейти`}
              <img src={ArrowItem} alt="Arrow item" />
            </Link>
          </div>
        ))
      ) : (
        <>
          {Array.from({ length: 18 }, (_, index) => (
            <div key={index} className="list-cards__card-placeholder"></div>
          ))}
        </>
      )}

      {!isLoading && items.length === 0 && (
        <span className={cn('list-cards__message-empty')}>
          У вас нет товарных кластеров
        </span>
      )}

      {showUploadFile && (
        <Modal
          headerTitle="Загрузить файл"
          onClose={() => setShowUploadFile(false)}
          theme="secondary"
          size="fit"
        >
          <UploadFile
            fileInputRef={fileInputRef}
            handleFileInputClick={handleFileInputClick}
            handleFileChange={handleFileChange}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
          />
        </Modal>
      )}
    </div>
  );
};

export default ClustersListCards;
