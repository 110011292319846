import React, { useEffect, useState, useCallback, useRef, FC } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

import { useDeleteImageProductMutation } from '@/features/AccountRetailer/services/retailerProductsManagementApi';

import {
  ImageThumbnailProps,
  PhotoUploadFormProps,
} from '@/features/AccountRetailer/types/PhotoUploadForm';

// Уникальные типы для react-dnd
const ItemType = {
  PHOTO: 'photo',
};

import '@/styles/uhotoUploadForm.sass';

// Компонент для каждой миниатюры изображения
const ImageThumbnail: FC<ImageThumbnailProps> = ({
  imagesLength,
  image,
  index,
  moveImage,
  makeMain,
  deleteImage,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const [, drag] = useDrag({
    type: ItemType.PHOTO,
    item: { id: image.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType.PHOTO,
    hover(item: { id: number; index: number }) {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  // Функция для открытия/закрытия контекстного меню
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Обработчик клика вне меню для его закрытия
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (showMenu && !target.closest('.context-menu')) {
      setShowMenu(false);
    }
  };

  // Подключаем обработчик к событию клика по документу
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showMenu]);

  const imageThumbnailRef = useRef<HTMLDivElement>(null);
  drag(drop(imageThumbnailRef));

  return (
    <div ref={imageThumbnailRef} className="image-thumbnail">
      <img src={image.url} onClick={toggleMenu} alt="preview" />
      {showMenu && (
        <div className="context-menu">
          <ul>
            {imagesLength > 1 && (
              <li onClick={() => makeMain(index)}>Сделать титульным</li>
            )}
            <li onClick={() => deleteImage(index, Number(image.id))}>
              Удалить
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

// Основной компонент для формы загрузки фото
const PhotoUploadForm: React.FC<PhotoUploadFormProps> = ({
  updateImages,
  images,
  setImages,
  showNameActionComponent,
}) => {
  const [deleteImageProduct] = useDeleteImageProductMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const fileArray = Array.from(event.target.files)
          .map((file) => ({
            id: uuidv4(),
            url: URL.createObjectURL(file),
            isMain: false, // По умолчанию не титульная
            file,
          }))
          .filter((image) => image.file instanceof File);

        if (images.length === 0 && fileArray.length > 0) {
          fileArray[0].isMain = true;
        }
        const updatedImages = [...images, ...fileArray];
        setImages(updatedImages);
        updateImages(updatedImages);
      }
    },
    [images, updateImages],
  );

  const moveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragImage = images[dragIndex];
      const newImages = [...images];
      newImages.splice(dragIndex, 1);
      newImages.splice(hoverIndex, 0, dragImage);
      setImages(newImages);
    },
    [images],
  );

  const makeMain = useCallback(
    (index: number) => {
      const newImages = images.map((img, idx) => ({
        ...img,
        isMain: idx === index,
      }));
      setImages(newImages);
      updateImages(newImages);
    },
    [images, setImages, updateImages],
  );

  const deleteImage = useCallback(
    async (index: number, imageId: number) => {
      const newImages = images.filter((_, idx) => idx !== index);
      if (images[index].isMain && newImages.length > 0) {
        newImages[0].isMain = true;
      }

      if (showNameActionComponent === 'new') {
        setImages(newImages);
        updateImages(newImages);
      }

      if (showNameActionComponent === 'edit') {
        try {
          await deleteImageProduct(imageId).unwrap();
          setImages(newImages);
          updateImages(newImages);
        } catch (err) {
          console.log(err);
        }
      }
    },
    [images, updateImages, deleteImageProduct],
  );

  // Добавьте функцию для обработки файлов, перетащенных в область загрузки
  // const handleDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const fileArray = acceptedFiles.map((file) => ({
  //       id: uuidv4(),
  //       url: URL.createObjectURL(file),
  //       isMain: false,
  //     }));
  //
  //     // Проверяем, есть ли уже титульная картинка
  //     const isThereMainImage = images.some((img) => img.isMain);
  //     if (!isThereMainImage && fileArray.length > 0) {
  //       fileArray[0].isMain = true;
  //     }
  //
  //     const updatedImages = [...images, ...fileArray];
  //     setImages(updatedImages);
  //     updateImages(updatedImages);
  //   },
  //   [images, updateImages],
  // );

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={cn('dnd-provider__top-bar')}>
        <span className={cn('form__input-wrapper-title')}>Фото</span>
        <div className="file-upload-container" onClick={openFileDialog}>
          + Добавить фото
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            name="product[images_attributes]"
          />
        </div>
      </div>

      <div className="image-upload-wrapper">
        {images.length <= 0 &&
          Array.from({ length: 8 }).map((_, index) => (
            <div
              key={index}
              className={cn('image-upload-wrapper__image-wrapper')}
            ></div>
          ))}

        {images.map((image, index) => (
          <div
            key={image.id}
            className={cn('image-upload-wrapper__image-wrapper')}
          >
            <ImageThumbnail
              imagesLength={images.length}
              image={image}
              index={index}
              moveImage={moveImage}
              makeMain={makeMain}
              deleteImage={deleteImage}
            />
          </div>
        ))}
      </div>
    </DndProvider>
  );
};

export default PhotoUploadForm;
