import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import FilterTabs from '@/components/Account/HeaderFilter/FilterTabs';
import Button from '@/components/ui/Button/Button';
import Modal from '@/layouts/Modal/Modal';

import ProfileForm from './forms/profileForm';
import DocumentsSlider from './components/documentsSlider';

import useProfile from './hooks/useProfile';
import Spinner from '@/components/Spinner/Spinner';
import DocumentsUploader from './components/documentUploader/documentUploader';

import Checkmark from '@/assets/icons/checkmark--outline__black.svg';

import './organizaion-card.sass';

type ProfileProps = {
  onClose?: () => void;
};

const Profile: React.FC<ProfileProps> = () => {
  const [
    {
      isLoading,
      isLoadingUpdate,
      isShowSuccessMessage,
      confirmationRemoveDocument,
    },
    {
      initialValues,
      counterpartyOptions,
      organizationOptions,
      documents,
      filesToUpload,
    },
    {
      setConfirmationRemoveDocument,
      onSubmit,
      onChangeFiles,
      onRemoveDocument,
      setIsShowSuccessMessage,
    },
  ] = useProfile();

  const navigate = useNavigate();

  const onModalClick = () => {
    navigate('/account');
  };

  return (
    <>
      {isShowSuccessMessage && (
        <Modal onClose={() => setIsShowSuccessMessage(false)} theme="secondary">
          <div
            className={cn(
              'notification-window notification-window__white success',
            )}
          >
            <img src={Checkmark} alt="Success" />
            <p>Ваша информация на проверке</p>
            <Button
              primary
              size="m"
              className="justify-content-center"
              type="submit"
              form="profile-form"
              onClick={() => onModalClick()}
            >
              Перейти в личный кабинет
            </Button>
          </div>
        </Modal>
      )}
      <div className="organization-modal">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-baseline">
              <FilterTabs list={['Документы организации']} />
            </div>

            <ProfileForm
              isLoadingUpdate={isLoadingUpdate}
              initialValues={initialValues}
              counterpartyOptions={counterpartyOptions}
              organizationOptions={organizationOptions}
              onSubmit={onSubmit}
            />
            <div className="organization__document_wrapper">
              <DocumentsUploader onChangeFiles={onChangeFiles} />
              <DocumentsSlider
                documents={documents}
                onRemoveDocument={onRemoveDocument}
                confirmationRemoveDocument={confirmationRemoveDocument}
                setConfirmationRemoveDocument={setConfirmationRemoveDocument}
                filesToUpload={filesToUpload}
              />
            </div>
            <div className="col-12 mt-3 justify-content-end d-flex">
              <Button
                primary
                size="m"
                className=""
                type="submit"
                form="profile-form"
              >
                Сохранить
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Profile;
