import { replace } from 'lodash-es';

const replaceUrlParam = (
  url: string,
  replacement: number | string,
  pattern = ':id',
): string => replace(url, pattern, String(replacement));

const queryStringToObject = (query: string): Record<string, string> =>
  Object.fromEntries(new URLSearchParams(query));

export { replaceUrlParam, queryStringToObject };
