import { DOMAIN_API } from '@/constants/domains';

type OtherUrls = 'logout';
type AuthorizationUrls = 'login';

type ApplicationUrls =
  | 'root'
  | 'staticPages'
  | 'error'
  | 'catalog'
  | 'product'
  | 'cart'
  | 'userAgreement'
  | 'me'
  | 'favorites'
  | 'product_search';

type UserCabinetUrls = 'cabinet' | 'orders' | 'order';

type RetailerUrls =
  | 'root'
  | 'clusters'
  | 'cluster_edit'
  | 'products'
  | 'products_edit'
  | 'pavilions'
  | 'pavilion_builder'
  | 'supplies'
  | 'prices_and_discounts'
  | 'analytics'
  | 'chat'
  | 'promotion'
  | 'stock'
  | 'ratings'
  | 'support'
  | 'documents';

const APPLICATION_URLS: Record<ApplicationUrls, string> = {
  root: '/',
  staticPages: '/:alias',
  error: '/error/:code',
  catalog: '/shop/:id',
  product_search: '/catalog',
  product: '/products/:id',
  cart: '/cart',
  userAgreement: '/userAgreement',
  me: '/me',
  favorites: '/favorites',
};

const USER_CABINET_URLS: Record<UserCabinetUrls, string> = {
  cabinet: '/user',
  orders: '/user/orders',
  order: '/user/:orderId',
};

const RETAILER_URLS: Record<RetailerUrls, string> = {
  root: '/account',
  clusters: '/account/clusters/',
  cluster_edit: '/account/clusters/:clusterId/edit',
  products: '/account/clusters/:clusterId/products',
  products_edit: '/account/clusters/:clusterId/products/:productId/edit',
  pavilions: '/account/pavilions',
  pavilion_builder: '/account/pavilions/:id',
  supplies: '/account/supplies',
  prices_and_discounts: '/account/prices_and_discounts',
  analytics: '/account/analytics',
  chat: '/account/chat',
  promotion: '/account/promotion',
  stock: '/account/stock',
  ratings: '/account/ratings',
  support: '/account/support',
  documents: '/account/documents',
};

const AUTHORIZATION_URLS: Record<AuthorizationUrls, string> = {
  login: '/login',
};

const OTHER_URLS: Record<OtherUrls, string> = {
  logout: `${DOMAIN_API}/oauth/logout?redirect=/`,
};

export {
  APPLICATION_URLS,
  USER_CABINET_URLS,
  RETAILER_URLS,
  AUTHORIZATION_URLS,
  OTHER_URLS,
};
