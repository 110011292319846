import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import truncateString from '@/utils/truncateString';

import { ProductsListCardsProps } from '@/features/AccountRetailer/types/productsList';

import Spinner from '@/components/Spinner/Spinner';

const ProductsListCards: React.FC<ProductsListCardsProps> = ({
  isLoading,
  items,
}) => {
  return (
    <div className="list-cards products__list-cards">
      {isLoading && <Spinner />}
      {!isLoading && items.length > 0 ? (
        items.map((item) => (
          <Link
            key={`${item.id}-${item.name}`}
            to={`${item.id}/edit`}
            className="list-cards__card"
          >
            <div className={cn('img-wrapper')}>
              <span>Редактировать</span>
              <img src={item.image} alt="Image" />
            </div>
            <div className="name">
              {truncateString(item.name, 40) || 'Без названия'}
            </div>
          </Link>
        ))
      ) : (
        <>
          {Array.from({ length: 18 }, (_, index) => (
            <div key={index} className="list-cards__card-placeholder"></div>
          ))}
        </>
      )}

      {!isLoading && items.length === 0 && (
        <span className={cn('list-cards__message-empty')}>
          В данном кластере пока нет никаких товаров!
        </span>
      )}
    </div>
  );
};

export default ProductsListCards;
