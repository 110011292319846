import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FavoriteStates } from '../types';

const initialState: FavoriteStates = {
  count_total: 0,
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    setGlobalFavoriteStates: (state, action: PayloadAction<FavoriteStates>) => {
      state.count_total = action.payload.count_total;
    },
  },
});

export const { setGlobalFavoriteStates } = favoriteSlice.actions;
export default favoriteSlice.reducer;
