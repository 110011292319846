import {
  OrderState,
  OrderActionTypes,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
} from '../../types/orderTypes';

const initialState: OrderState = {
  data: null,
  isLoading: false,
  error: null,
};

export const orderReducer = (
  state = initialState,
  action: OrderActionTypes,
): OrderState => {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null, // Clear any existing errors
      };
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload, // Set the error received from the action
      };
    default:
      return state;
  }
};
