import { useEffect } from 'react';
import { useGlobalContext } from '@/contexts/GlobalContext';
import { useGetCartQuery } from '@/features/Cart/services/cartApi';
import {
  setGlobalCartStates,
  clearCartState,
} from '@/features/Cart/states/cartSlice';
import { useAppDispatch } from '@/hooks';

const useInitCart = () => {
  const [{ accessToken }] = useGlobalContext();
  const dispatch = useAppDispatch();

  const {
    data: cartData,
    isFetching,
    isError,
    refetch,
  } = useGetCartQuery(undefined, {
    skip: !accessToken,
  });

  useEffect(() => {
    if (accessToken) {
      refetch();
    } else {
      dispatch(clearCartState());
    }
  }, [accessToken, refetch]);

  useEffect(() => {
    if (cartData && !isFetching && !isError) {
      const {
        cart_products,
        count_total,
        delivery_price,
        price_total,
        price_with_delivery_total,
      } = cartData.data;
      dispatch(
        setGlobalCartStates({
          cart_products,
          count_total,
          delivery_price,
          price_total,
          price_with_delivery_total,
        }),
      );
    }
  }, [cartData, isFetching, isError, dispatch, accessToken]);
};

export { useInitCart };
