import UAParser from 'ua-parser-js';

const DeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  // Детальная информация о браузере, устройстве и ОС
  const deviceInfo = result.device;
  const osInfo = result.os;

  // Определение типа устройства
  let deviceType;
  if (deviceInfo.model && deviceInfo.model.includes('iPhone')) {
    deviceType = 'iPhone';
  } else if (osInfo.name === 'Android') {
    deviceType = 'Android';
  } else {
    deviceType = 'ПК или другое устройство';
  }

  return deviceType;
};

export default DeviceInfo;
