import { useEffect, useState } from 'react';
import { get } from 'lodash-es';
import Slider from 'react-slick';

import Modal from '@/layouts/Modal/Modal';

import { SLIDER_SETTINGS } from '../../constants';

import { DocumentSliderProps } from '../../types/documentSlider';

import Button from '@/components/ui/Button/Button';

import trashIcon from '@/assets/icons/trash-icon.svg';

const DocumentsSlider: React.FC<DocumentSliderProps> = ({
  documents,
  onRemoveDocument,
  confirmationRemoveDocument,
  setConfirmationRemoveDocument,
  filesToUpload,
}) => {
  const [uploadPreviews, setUploadPreviews] = useState<string[]>([]);

  useEffect(() => {
    // Очистка созданных URL, чтобы избежать утечек памяти
    return () => {
      uploadPreviews.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [uploadPreviews]);

  useEffect(() => {
    const newPreviews = filesToUpload.map((file) => URL.createObjectURL(file));
    setUploadPreviews(newPreviews);

    return () => {
      newPreviews.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [filesToUpload]);

  return (
    <>
      {confirmationRemoveDocument && (
        <Modal
          onClose={() => setConfirmationRemoveDocument(null)}
          theme="secondary"
          headerTitle="Вы действительно хотите удалить документ?"
        >
          <div className="group-buttons__modal">
            <Button
              size="m"
              primary
              onClick={onRemoveDocument(confirmationRemoveDocument)}
            >
              Да
            </Button>
            <Button
              size="m"
              grey
              onClick={() => setConfirmationRemoveDocument(null)}
            >
              Нет
            </Button>
          </div>
        </Modal>
      )}

      {documents.length === 0 && (
        <Slider {...SLIDER_SETTINGS} className="mt-4">
          {uploadPreviews.map((document, index) => (
            <div className="organization__document" key={document}>
              <div style={{ backgroundColor: '#FFFFFF' }}>
                <img src={document} alt="Document preview" />
                <span>{filesToUpload[index].name}</span>
              </div>
            </div>
          ))}
        </Slider>
      )}

      {documents.length > 0 && (
        <Slider {...SLIDER_SETTINGS} className="mt-4">
          {documents.map((document) => (
            <div className="organization__document" key={document.id}>
              <div
                style={{
                  backgroundImage: `url(${get(document, 'image')})`,
                  backgroundColor: '#FFFFFF',
                }}
              >
                <Button
                  icon={trashIcon}
                  size={'m'}
                  onClick={() => setConfirmationRemoveDocument(document.id)}
                />
                <span>{document.name}</span>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </>
  );
};
export default DocumentsSlider;
