import { useEffect, useState } from 'react';
import { FormApi } from 'final-form';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { get } from 'lodash-es';

import {
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductsDataQuery,
  useDeleteProductMutation,
  useGetProductEditQuery,
} from '@/features/AccountRetailer/services/retailerProductsManagementApi';

import { useGetClusterQuery } from '@/features/AccountRetailer/services/retailerClustersManagementApi';

import { ErrorsMessage } from '@/types/forms';

import { Notification } from '@/features/AccountRetailer/types';

import {
  ClusterProductFormData,
  ClusterProductWrapper,
  ProductAttributes,
  UseClusterProductsManagement,
} from '@/features/AccountRetailer/types/useClustersProductsManagement';

import {
  Image,
  UpdateImages,
} from '@/features/AccountRetailer/types/PhotoUploadForm';

const useClusterProductsManagement = (
  clusterId: number | null,
  productId: number | null,
): UseClusterProductsManagement => {
  const [initialValues, setInitialValues] = useState<ClusterProductWrapper>({
    product: {
      article: null,
      name: null,
      description: null,
      count_products: null,
      price: null,
      price_old: null,
      discount: null,
      group_product_colors_attributes: null,
      group_product_sizes_attributes: null,
      product_images_attributes: null,
      images: [],
      video: null,
    },
  });

  const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false);
  const [images, setImages] = useState<Image[]>([]);

  const updateImages: UpdateImages = (newImages) => {
    setImages(newImages);
  };

  const [productAttributes, setProductAttributes] = useState<ProductAttributes>(
    {
      colors: [],
      sizes: [],
    },
  );

  const [notification, setNotification] = useState<Notification | null>(null);

  const { data: clusterData } = useGetClusterQuery(clusterId ?? skipToken, {
    skip: clusterId == null,
  });

  const {
    data: productsData,
    isLoading,
    refetch: productsDataRefetch,
  } = useGetProductsDataQuery(clusterId ?? skipToken);

  const { data: productData, refetch: productDataRefetch } =
    useGetProductEditQuery(productId ?? skipToken);

  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();

  const [showNameActionComponent, setShowNameActionComponent] = useState<
    'new' | 'edit' | 'view'
  >('view');

  const handleDeleteItem = async (id: number) => {
    try {
      await deleteProduct(id).unwrap();
      productsDataRefetch();
    } catch (error) {
      setNotification({
        messages: 'Что-то пошло не так, повторите попытку позже!',
        type: 'error',
      });
    }
  };

  const resetForm = () => {
    setInitialValues({
      product: {
        article: null,
        name: null,
        description: null,
        count_products: null,
        price: null,
        price_old: null,
        discount: null,
        group_product_colors_attributes: null,
        group_product_sizes_attributes: null,
        product_images_attributes: null,
        images: [],
        video: null,
      },
    });
    setImages([]);
  };

  const onSubmit = async (data: ClusterProductFormData, form: FormApi) => {
    setOnSubmitLoading(true);
    try {
      const formData = new FormData();

      // Добавление текстовых полей
      formData.append('product[article]', data.product.article || '');
      formData.append('product[name]', data.product.name || '');
      formData.append('product[description]', data.product.description || '');
      formData.append(
        'product[count_products]',
        data.product.count_products?.toString() || '0',
      );
      formData.append('product[price]', data.product.price || '');
      data.product.price_old &&
        formData.append('product[price_old]', data.product.price_old);
      // formData.append('product[discount]', data.product.discount || '');
      formData.append('product[video]', data.product.video || '');

      if (data.product.group_product_colors_attributes) {
        formData.append(
          'product[group_product_color_id]',
          data.product.group_product_colors_attributes.id.toString(),
        );
      }
      if (data.product.group_product_sizes_attributes) {
        formData.append(
          'product[group_product_size_id]',
          data.product.group_product_sizes_attributes.id.toString(),
        );
      }

      // Добавление идентификатора группы продуктов
      if (clusterId) {
        formData.append('product[group_product_ids][]', String(clusterId));
      }

      images.forEach((image: Image, index) => {
        if (image.file) {
          console.log(image);
          formData.append(
            `product[product_images_attributes][${index}][image]`,
            image.file,
          );
          formData.append(
            `product[product_images_attributes][${index}][is_main]`,
            String(image.isMain),
          );
        }
      });

      if (productId) {
        // Обновление товара
        await updateProduct({ productId, formData }).unwrap();
        setNotification({
          messages: 'Товар успешно обновлен!',
          type: 'success',
        });
        productDataRefetch();
      } else {
        // Создание нового товара
        await createProduct(formData).unwrap();
        setNotification({
          messages: 'Товар успешно создан!',
          type: 'success',
        });
        form.reset();
      }
    } catch (error) {
      console.error('Ошибка при отправке формы:', error);
      const errors = get(error, 'data.errors') as unknown as ErrorsMessage;
      setNotification({
        messages: `Ошибка: ${errors}`,
        type: 'error',
      });
    }
    productsDataRefetch();
    resetForm();
    setOnSubmitLoading(false);
  };

  useEffect(() => {
    if (clusterData) {
      const colors =
        clusterData.data.group_product_colors?.map((color) => ({
          id: color.id,
          label: color.name,
          value: color.id,
        })) || [];

      const sizes =
        clusterData.data.group_product_sizes?.map((size) => ({
          id: size.id,
          label: size.name,
          value: size.id,
        })) || [];

      setProductAttributes({ colors, sizes });
    }
  }, [clusterData]);

  useEffect(() => {
    if (productId) {
      setShowNameActionComponent('edit');
    }
  }, [productId, setShowNameActionComponent]);

  useEffect(() => {
    if (productData && productId) {
      if (productData?.data?.images) {
        const formattedImages: Image[] = productData.data.images.map(
          (image) => ({
            id: image.id,
            url: image.url,
            isMain: image.isMain,
          }),
        );
        setImages(formattedImages);
      }
      setInitialValues({
        product: {
          name: productData.data.name,
          article: productData.data.article,
          description: productData.data.description,
          count_products: productData.data.count_products,
          price: productData.data.price,
          price_old: productData.data.price_old,
          discount: productData.data.discount,
          group_product_colors_attributes:
            productData.data.group_product_colors_attributes,
          group_product_sizes_attributes:
            productData.data.group_product_sizes_attributes,
          images: productData.data.images || null,
          product_images_attributes: null,
          video: productData.data.video,
        },
      });
    }
  }, [productData, productId]);

  return [
    {
      onSubmitLoading,
      isLoading,
    },
    {
      productsData: productsData?.data || null,
      initialValues,
      notification,
      showNameActionComponent,
      productAttributes,
      images,
    },
    {
      resetForm,
      setNotification,
      setShowNameActionComponent,
      onSubmit,
      handleDeleteItem,
      updateImages,
      setImages,
    },
  ];
};

export default useClusterProductsManagement;
