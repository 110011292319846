import { RegisterUserPayload, RegistrationFormData } from '../types/register';

const serverFormatter = ({
  politika,
  oferta,
  ...props
}: RegistrationFormData): RegisterUserPayload => {
  return {
    user: {
      ...props,
      politika: politika ? 'on' : 'off',
      oferta: oferta ? 'on' : 'off',
    },
  };
};
export { serverFormatter };
