import { useGetStatisticQuery } from '@/features/AccountRetailer/services/statisticApi';

import { UseStatistic } from '@/features/AccountRetailer/types/useStatistic';

const useClusterProductsManagement = (): UseStatistic => {
  // const [notification, setNotification] = useState<Notification | null>(null);

  const { data: statisticData, isLoading: isLoadingStatistic } =
    useGetStatisticQuery();

  return [
    { isLoadingStatistic },
    { statisticData: statisticData ? statisticData?.data : null },
  ];
};

export default useClusterProductsManagement;
