import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Modal from '@/layouts/Modal/Modal';
import Button from '@/components/ui/Button/Button';

import observeElementSize from '@/utils/observeElementSize';

import { usePavilionMap } from '@/features/PavilionMap/hooks/usePavilionMap';

import SearchIcon from '@/assets/img/pavilionsRoof/search_icon.jpg';

declare global {
  interface Window {
    MiniMap: any;
    MiniMapControl: any;
  }
}

import { BOOKING_PAVILION_STEPS } from '@/features/PavilionMap/constants';

import { PopupState } from '@/features/PavilionMap/types/usePavilionMap';
import { PavilionMapProps } from '@/features/PavilionMap/types';

import Checkmark from '@/assets/icons/checkmark--outline__black.svg';

import './pavilion-map.sass';

const PavilionMap: React.FC<PavilionMapProps> = ({
  selectPavilionCategories,
  typeCatalogueModal,
  setShowCatalogue,
  catalogStyle,
  typeAction,
}) => {
  const [
    ,
    {
      responsePavilionUser,
      miniMapController,
      isMyPavilion,
      mapMallContainerRef,
      mapMallRef,
      pavilionData,
      popup,
      mapMall,
      miniMapData,
      currentStep,
      pavilionNumber,
    },
    {
      setMiniMapController,
      setIsMyPavilion,
      teleport,
      onSubmit,
      setCurrentStep,
      setPopup,
      setMapMall,
      setPavilionData,
      setPavilionNumber,
    },
  ] = usePavilionMap();

  const PopupWindow: React.FC<PopupState> = ({ x, y, visible }) => {
    const style: React.CSSProperties = {
      position: 'absolute',
      left: `${x}px`,
      top: `${y}px`,
      visibility: visible ? 'visible' : 'hidden',
    };

    if (typeAction === 'view') {
      return (
        <div className={cn('map-mall__popup')} style={style}>
          <h3>{pavilionData?.name}</h3>
          <p>{pavilionData?.description}</p>
          <span
            onClick={() =>
              setShowCatalogue ? teleport(setShowCatalogue)() : null
            }
            className="submit-btn btn-teleport"
          >
            Телепорт
          </span>
        </div>
      );
    } else {
      return (
        <div className={cn('map-mall__popup')} style={style}>
          <h3>{pavilionData?.name}</h3>
          <p>{pavilionData?.description}</p>
          <span
            onClick={() =>
              setShowCatalogue ? teleport(setShowCatalogue)() : null
            }
            className="submit-btn btn-teleport"
          >
            Телепорт
          </span>
        </div>
      );
    }
  };

  const clearMapResources = () => {
    if (miniMapController) {
      miniMapController.clearEvents();
    }

    if (mapMall) {
      mapMall.destroy();
      setMapMall(null);
    }

    setMiniMapController(null);
  };

  const initMap = () => {
    clearMapResources();
    if (window.MiniMap && mapMallContainerRef.current) {
      let width, height;
      if (
        window.innerWidth <= 1023 &&
        mapMallContainerRef.current.parentElement
      ) {
        const { offsetWidth, offsetHeight } =
          mapMallContainerRef.current.parentElement;
        width = offsetWidth;
        height = offsetHeight;
      } else {
        const { offsetWidth, offsetHeight } = mapMallContainerRef.current;
        width = offsetWidth;
        height = offsetHeight;
      }

      // Initialize map if width and height are determined
      if (width && height) {
        const map = new window.MiniMap('#map-mall', width, height);
        setMapMall(map);
        // Load map and then set up controller
        map.load('MiniMap').then(() => {
          const miniMapController = new window.MiniMapControl(map);
          setMiniMapController(miniMapController);


          miniMapData.forEach((pavilion) =>
            miniMapController.init(pavilion.pavilionID, {
              roof:
                pavilion.status === 'closed'
                  ? pavilion.image
                  : pavilion.pavilionID ===
                      '50AA08A0-DA55-11EE-BF95-7D3DAC5501E7'
                    ? SearchIcon
                    : null,
              lock:
                pavilion.status === 'closed' &&
                typeAction === 'edit' &&
                !pavilion.my_pavilion,
              my: pavilion.status === 'active',
            }),
          );

          miniMapController.setColor({
            selected: '#06fc33',
            locked: '#f00',
            my: '#ffd600',
          });

          miniMapController.addEventListener('selected-object', (e: any) => {
            console.log(e);
            const pavilion = miniMapData.find(
              (item) => item.pavilionID === e.uuid,
            );

            if (pavilion) {
              miniMapController.select([e.uuid]);
              setPavilionNumber(pavilion.position);
              setPavilionData({
                id: Number(pavilion.id),
                pavilionID: e.uuid,
                name: pavilion.name || '',
                description: pavilion.description || '',
              });

              if (mapMallRef.current) {
                const rect = mapMallRef.current.getBoundingClientRect();
                const x = e.event.clientX - rect.left;
                const y = e.event.clientY - rect.top;
                setPopup({ visible: true, x, y });
              }

              if (pavilion.status === 'active') {
                setIsMyPavilion(true);
              } else if (
                pavilion.pavilionID !==
                  '50AA08A0-DA55-11EE-BF95-7D3DAC5501E7' &&
                pavilion.pavilionID !== '7887CE5E-AEA9-4D8C-83CC-EC3D51D1F391'
              ) {
                typeAction === 'edit' && setCurrentStep('prompt');
              }
            }
          });
        });
        map.render();
      }
    }
  };

  useEffect(() => {
    if (mapMall && selectPavilionCategories && miniMapController) {
      miniMapController.select(selectPavilionCategories);
    }
  }, [selectPavilionCategories, miniMapController]);

  useEffect(() => {
    if (mapMallRef.current) {
      const observer = observeElementSize(mapMallRef.current, (size) => {
        console.log('Element size changed:', size);

        // Обновление размеров canvas
        if (mapMall) {
          mapMall.update(size.width, size.height);
        }
      });

      // Очистка наблюдателя при размонтировании компонента
      return () => observer();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.MiniMap && mapMallRef.current && miniMapData.length > 0) {
        clearInterval(intervalId);
        initMap();
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [miniMapData]);

  return (
    <div
      ref={mapMallContainerRef}
      id="map-mall-container"
      className={cn('pavilion-map-container', {
        'catalogue-modal__active': typeCatalogueModal === 'map',
        'catalog-style': catalogStyle,
      })}
    >
      <div className={cn('backdrop-filter')}></div>

      <canvas ref={mapMallRef} id="map-mall" className={cn('map-mall')} />

      {popup.visible && typeAction === 'view' && (
        <PopupWindow x={popup.x} y={popup.y} visible={popup.visible} />
      )}

      {!isMyPavilion && currentStep === BOOKING_PAVILION_STEPS.STEP_PROMPT && (
        <Modal
          headerTitle={`Вы действительно хотите заказать павильон №${pavilionNumber}`}
          onClose={() => {
            setCurrentStep(null);
            miniMapController.select([]);
          }}
          theme="secondary"
        >
          <div className={cn('notification-window')}>
            <div className={cn('group-buttons')}>
              <Button
                onClick={() =>
                  pavilionData
                    ? onSubmit({ pavilion_id: pavilionData?.id })
                    : null
                }
                primary
                size="m"
              >
                Да
              </Button>
              <Button
                onClick={() => {
                  setCurrentStep(null);
                  miniMapController.select([]);
                }}
                secondary
                size="m"
              >
                Нет
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {!isMyPavilion && currentStep === BOOKING_PAVILION_STEPS.STEP_SUCCESS && (
        <Modal
          onClose={() => {
            setCurrentStep(null);
            miniMapController.select([]);
          }}
          theme="secondary"
        >
          <div
            className={cn(
              'notification-window notification-window__white success',
            )}
          >
            <img src={Checkmark} alt="Success" />
            <p>Павильон заказан. Мы свяжемся с вами</p>
          </div>
        </Modal>
      )}

      {!isMyPavilion && currentStep === BOOKING_PAVILION_STEPS.STEP_ERROR && (
        <Modal
          onClose={() => {
            setCurrentStep(null);
            miniMapController.select([]);
          }}
          theme="secondary"
        >
          <div
            className={cn(
              'notification-window notification-window__white error',
            )}
          >
            <span className={cn('error-img__custom')}></span>
            <p>{responsePavilionUser}</p>
          </div>
        </Modal>
      )}

      {isMyPavilion && typeAction === 'edit' && (
        <Modal onClose={() => setIsMyPavilion(false)} theme="secondary">
          <div
            className={cn(
              'notification-window notification-window__white success',
            )}
          >
            {pavilionData && (
              <>
                <h2>{pavilionData.name}</h2>
                <p>{pavilionData.description}</p>
                <Link to={`/account/pavilions/${pavilionData.id}`}>
                  <Button secondary size="m">
                    Перейти
                  </Button>
                </Link>
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PavilionMap;

// // PavilionMap.tsx
// import React, { useContext, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import cn from 'classnames';
//
// import Modal from '@/layouts/Modal/Modal';
// import Button from '@/components/ui/Button/Button';
//
// import { MapContext } from './MapContext';
//
// import SearchIcon from '@/assets/img/pavilionsRoof/search_icon.jpg';
// import Checkmark from '@/assets/icons/checkmark--outline__black.svg';
//
// import { BOOKING_PAVILION_STEPS } from '@/features/PavilionMap/constants';
// import { PavilionMapProps } from '@/features/PavilionMap/types';
// import { PopupState, PavilionData } from '@/features/PavilionMap/types';
//
// import './pavilion-map.sass';
//
// const PavilionMap: React.FC<PavilionMapProps> = ({
//   selectPavilionCategories,
//   typeCatalogueModal,
//   setShowCatalogue,
//   catalogStyle,
//   typeAction,
// }) => {
//   const {
//     mapMall,
//     miniMapController,
//     mapMallContainerRef,
//     mapMallRef,
//     miniMapData,
//     popup,
//     setPopup,
//     pavilionData,
//     setPavilionData,
//     pavilionNumber,
//     setPavilionNumber,
//     isMyPavilion,
//     setIsMyPavilion,
//     currentStep,
//     setCurrentStep,
//     initMap,
//   } = useContext(MapContext);
//
//   const PopupWindow: React.FC<PopupState> = ({ x, y, visible }) => {
//     const style: React.CSSProperties = {
//       position: 'absolute',
//       left: `${x}px`,
//       top: `${y}px`,
//       visibility: visible ? 'visible' : 'hidden',
//     };
//
//     return (
//       <div className={cn('map-mall__popup')} style={style}>
//         <h3>{pavilionData?.name}</h3>
//         <p>{pavilionData?.description}</p>
//         <span
//           onClick={() =>
//             setShowCatalogue ? teleport(setShowCatalogue)() : null
//           }
//           className="submit-btn btn-teleport"
//         >
//           Телепорт
//         </span>
//       </div>
//     );
//   };
//
//   useEffect(() => {
//     if (mapMall && selectPavilionCategories && miniMapController) {
//       miniMapController.select(selectPavilionCategories);
//     }
//   }, [selectPavilionCategories, miniMapController]);
//
//   useEffect(() => {
//     if (mapMallRef.current) {
//       const observer = observeElementSize(mapMallRef.current, (size) => {
//         if (mapMall) {
//           mapMall.update(size.width, size.height);
//         }
//       });
//
//       return () => observer();
//     }
//   }, []);
//
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (window.MiniMap && mapMallRef.current && miniMapData.length > 0) {
//         clearInterval(intervalId);
//         initMap();
//       }
//     }, 100);
//     return () => clearInterval(intervalId);
//   }, [miniMapData]);
//
//   return (
//     <div
//       ref={mapMallContainerRef}
//       id="map-mall-container"
//       className={cn('pavilion-map-container', {
//         'catalogue-modal__active': typeCatalogueModal === 'map',
//         'catalog-style': catalogStyle,
//       })}
//     >
//       <div className={cn('backdrop-filter')}></div>
//       <canvas ref={mapMallRef} id="map-mall" className={cn('map-mall')} />
//       {popup.visible && typeAction === 'view' && (
//         <PopupWindow x={popup.x} y={popup.y} visible={popup.visible} />
//       )}
//       {!isMyPavilion && currentStep === BOOKING_PAVILION_STEPS.STEP_PROMPT && (
//         <Modal
//           headerTitle={`Вы действительно хотите заказать павильон №${pavilionNumber}`}
//           onClose={() => {
//             setCurrentStep(null);
//             miniMapController.select([]);
//           }}
//           theme="secondary"
//         >
//           <div className={cn('notification-window')}>
//             <div className={cn('group-buttons')}>
//               <Button
//                 onClick={() =>
//                   pavilionData
//                     ? onSubmit({ pavilion_id: pavilionData?.id })
//                     : null
//                 }
//                 primary
//                 size="m"
//               >
//                 Да
//               </Button>
//               <Button
//                 onClick={() => {
//                   setCurrentStep(null);
//                   miniMapController.select([]);
//                 }}
//                 secondary
//                 size="m"
//               >
//                 Нет
//               </Button>
//             </div>
//           </div>
//         </Modal>
//       )}
//       {!isMyPavilion && currentStep === BOOKING_PAVILION_STEPS.STEP_SUCCESS && (
//         <Modal
//           onClose={() => {
//             setCurrentStep(null);
//             miniMapController.select([]);
//           }}
//           theme="secondary"
//         >
//           <div
//             className={cn(
//               'notification-window notification-window__white success',
//             )}
//           >
//             <img src={Checkmark} alt="Success" />
//             <p>Павильон заказан. Мы свяжемся с вами</p>
//           </div>
//         </Modal>
//       )}
//       {!isMyPavilion && currentStep === BOOKING_PAVILION_STEPS.STEP_ERROR && (
//         <Modal
//           onClose={() => {
//             setCurrentStep(null);
//             miniMapController.select([]);
//           }}
//           theme="secondary"
//         >
//           <div
//             className={cn(
//               'notification-window notification-window__white error',
//             )}
//           >
//             <span className={cn('error-img__custom')}></span>
//             <p>{responsePavilionUser}</p>
//           </div>
//         </Modal>
//       )}
//       {isMyPavilion && typeAction === 'edit' && (
//         <Modal onClose={() => setIsMyPavilion(false)} theme="secondary">
//           <div
//             className={cn(
//               'notification-window notification-window__white success',
//             )}
//           >
//             {pavilionData && (
//               <>
//                 <h2>{pavilionData.name}</h2>
//                 <p>{pavilionData.description}</p>
//                 <Link to={`/account/pavilions/${pavilionData.id}`}>
//                   <Button secondary size="m">
//                     Перейти
//                   </Button>
//                 </Link>
//               </>
//             )}
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };
//
// export default PavilionMap;
