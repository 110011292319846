import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProductQuery } from '../services/productSlice';
import { UseProductCard } from '../types/useProduct';

const useProductCard = (selectedProductKey: number): UseProductCard => {
  const [showShareDropdown, setShowShareDropdown] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: productData, refetch: productRefetch } =
    useGetProductQuery(selectedProductKey);

  const [isMoreInfoShown, setIsMoreInfoShown] = useState<boolean>(false);
  const [isRating, setIsRating] = useState<boolean>(true);

  const productViewData = productData
    ? (() => {
        const { discount, images, alias_model_3d } = productData.data;
        return {
          discount,
          images,
          alias_model_3d,
        };
      })()
    : {};

  const productDetails = productData
    ? (() => {
        const {
          id,
          name,
          article,
          description,
          reviews_stars,
          retailer,
          characteristics,
          sizes,
          colors,
        } = productData.data;
        return {
          id,
          name,
          article,
          description,
          reviews_stars,
          retailer,
          characteristics,
          sizes,
          colors,
        };
      })()
    : {};

  const productOptionSelect = productData
    ? (() => {
        const { price, price_old, sizes, colors, is_star } = productData.data;
        return { price, price_old, sizes, colors, is_star };
      })()
    : {};

  const productFeedback = productData
    ? (() => {
        const { reviews_stars, reviews, questions } = productData.data;
        return { reviews_stars, reviews, questions };
      })()
    : {};

  const productReviews = productData
    ? (() => {
        const { reviews_stars, questions } = productData.data;
        const questions_length = questions?.length || 0;
        return { reviews_stars, questions_length };
      })()
    : {};

  const toggleMoreInfo = () => {
    setIsMoreInfoShown(!isMoreInfoShown);
  };

  const toggleFeedback = (param: boolean) => {
    setIsRating(param);
  };

  const handleBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate('/');
    }
  };

  return [
    {
      showShareDropdown,
      isMoreInfoShown,
      isRating,
      productViewData,
      productDetails,
      productOptionSelect,
      productFeedback,
      productReviews,
    },
    {
      setShowShareDropdown,
      toggleMoreInfo,
      toggleFeedback,
      handleBack,
      productRefetch,
    },
  ];
};

export default useProductCard;
