import React from 'react';

import Modal from '@/layouts/Modal/Modal';

import useLogin from '@/features/Auth/hooks/useLogin';
import LoginForm from '@/features/Auth/forms/LoginForm';

import { LoginModalProps } from '../../types/login';

const Login: React.FC<LoginModalProps> = ({
  onClickRegistration,
  onClickPasswordRecovery,
  onCloseModal,
}) => {
  const [{ isLoading }, { initialValues }, { onSubmit }] = useLogin();

  return (
    <Modal onClose={onCloseModal} headerTitle="Вход">
      <div className="auth-modal-content">
        <LoginForm
          isLoading={isLoading}
          className="auth-modal-content__form form-primary"
          initialValues={initialValues}
          onSubmit={onSubmit}
        />

        <div>
          <span
            className="link auth-modal-content__forget-password"
            onClick={onClickPasswordRecovery}
          >
            Забыли пароль?
          </span>
          <button
            className="auth-modal-content__submit-btn submit-btn btn-primary"
            type="submit"
            form="login-form"
          >
            Войти
          </button>
        </div>

        <p className="auth-modal-content__offer">
          Нет аккаунта?
          <span
            className="auth-modal-content__login-link link"
            onClick={onClickRegistration}
          >
            Зарегистрироваться
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default Login;
