import OrderDetails from './components/OrderDetails';
import OrderItemsListBlock from './components/OrderItemsListBlock';
import OrdersBlock from './components/Orders';
import useUserCabinOrders from './hooks/useUserCabinOrders';

import './user_orders.sass';

const UserOrders = () => {
  const [{ canShowSelectedOrder }, { onClickOrder }] = useUserCabinOrders();
  return (
    <>
      {canShowSelectedOrder ? (
        <>
          <OrderDetails />
          <OrderItemsListBlock />
        </>
      ) : (
        <OrdersBlock onClick={onClickOrder} />
      )}
    </>
  );
};
export default UserOrders;
