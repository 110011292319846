import React from 'react';

const AccountPricesAndDiscounts: React.FC = () => {
  return (
    <div className="container account-container">
      <div className="account-content">AccountPricesAndDiscounts</div>
    </div>
  );
};

export default AccountPricesAndDiscounts;
