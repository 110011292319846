import Cookies from 'js-cookie';

import { useGlobalContext } from '@/contexts/GlobalContext';

import { useOnLogoutMutation } from '../services/authApi';

const useLogout = () => {
  const [, { setAccessToken }] = useGlobalContext();
  const [onLogout, { isLoading }] = useOnLogoutMutation();

  const logout = async () => {
    try {
      await onLogout().unwrap();
      localStorage.removeItem('user');
      Cookies.remove('accessToken');
      setAccessToken(undefined);
    } catch (error) {
      console.error('Ошибка при выходе', error);
    }
  };

  return {
    logout,
    isLoading,
  };
};

export default useLogout;
