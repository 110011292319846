import { useState } from 'react';
import { UseUserCabinOrders } from '../types/useUserCabinOrders';

const useUserCabinOrders = (): UseUserCabinOrders => {
  const [selectedOrder, setSelectedOrder] = useState<null | number>(null);
  const canShowSelectedOrder = !!selectedOrder;

  const onClickOrder = (id: number) => () => {
    setSelectedOrder(id);
  };
  return [
    {
      canShowSelectedOrder,
    },
    {
      onClickOrder,
    },
  ];
};
export default useUserCabinOrders;
