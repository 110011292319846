import { apiSlice } from '@/services/apiSlice';
import {
  GetClusterResponseData,
  PostClusterResponseData,
} from '../types/useItemsManagement';

export const clusterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCluster: builder.query<GetClusterResponseData, number>({
      query: (id) => `/group_products/${id}`,
    }),
    createCluster: builder.mutation<PostClusterResponseData, FormData>({
      query: (formData) => ({
        url: `/group_products`,
        method: 'POST',
        body: formData,
      }),
    }),
    updateCluster: builder.mutation<void, { id: number; formData: FormData }>({
      query: ({ id, formData }) => ({
        url: `/group_products/${id}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    createProductModel: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: `/product_models`,
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetClusterQuery,
  useCreateClusterMutation,
  useUpdateClusterMutation,
  useCreateProductModelMutation,
} = clusterApiSlice;
