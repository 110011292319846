import React from 'react';

// Styles
import './breadCrumbs.sass';

const BreadCrumbs: React.FC = () => {
  return (
    <div className="bread__crumbs">
      <div className="name__crumbs">Главная</div>
      <span className="dot__crumbs"></span>
      <div className="name__crumbs">Каталог</div>
    </div>
  );
};

export default BreadCrumbs;
