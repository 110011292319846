import React from 'react';
import cn from 'classnames';

import useClusterProductsManagement from '@/features/AccountRetailer/hooks/useStatistic';

import Spinner from '@/components/Spinner/Spinner';
import CommonWrapper from '@/features/AccountRetailer/components/StatisticComponents/CommonWrapper/CommonWrapper';
import CommonStats from '@/features/AccountRetailer/components/StatisticComponents/CommonStats/CommonStats';
import ChartStats from '@/features/AccountRetailer/components/StatisticComponents/Main/ChartStats/ChartStats';
import Review from '@/features/AccountRetailer/components/StatisticComponents/Main/Review/Review';
import Issue from '@/features/AccountRetailer/components/StatisticComponents/Main/Issue/Issue';

import '@/features/AccountRetailer/account-main.sass';

const AccountMain: React.FC = () => {
  const [{ isLoadingStatistic }, { statisticData }] =
    useClusterProductsManagement();

  return (
    <div className="account-main container">
      <div className="account-main__statistic">
        <CommonStats
          ordersSum={statisticData?.orders_sum}
          isLoadingStatistic={isLoadingStatistic}
        />
        <ChartStats />
      </div>
      <div className="account-main__user-actions">
        <CommonWrapper>
          <div className="reviews">
            <div className="account-border-line pb-3">
              <p className="account-text account-text-l bold-text mb-1">
                Отзывы пользователей
              </p>
              {/*<p className="grey-text account-text-m bold-text">*/}
              {/*  Обновлено в 20:01*/}
              {/*</p>*/}
            </div>
            <div className="account__main-reviews-list list-items pt-3 pb-2">
              {isLoadingStatistic && <Spinner />}

              {!isLoadingStatistic &&
                statisticData &&
                statisticData?.reviews.length > 0 && (
                  <>
                    {statisticData?.reviews.map((review) => (
                      <Review key={review.id} data={review} />
                    ))}
                  </>
                )}
              {!isLoadingStatistic &&
                statisticData &&
                statisticData?.reviews.length === 0 && (
                  <span className={cn('empty-block__text')}>
                    Отзывов пока нет
                  </span>
                )}
            </div>
            {/*<Button*/}
            {/*  className="w-100 center"*/}
            {/*  size="m"*/}
            {/*  darkBorder*/}
            {/*  secondary*/}
            {/*  icon={hexagonDark}*/}
            {/*>*/}
            {/*  Все отзывы*/}
            {/*</Button>*/}
          </div>
        </CommonWrapper>
        <CommonWrapper>
          <div className="questions">
            <div className="account-border-line pb-3">
              <p className="account-text account-text-l bold-text mb-1">
                Вопросы пользователей
              </p>
              {/*<p className="grey-text account-text-m bold-text">*/}
              {/*  Обновлено в 20:01*/}
              {/*</p>*/}
            </div>
            <div className="questions-list list-items">
              {isLoadingStatistic && <Spinner />}

              {!isLoadingStatistic &&
                statisticData &&
                statisticData?.questions.length > 0 && (
                  <>
                    {statisticData?.questions.map((question) => (
                      <Issue
                        key={question.name}
                        title={question.name}
                        description={question.description}
                      />
                    ))}
                  </>
                )}
              {!isLoadingStatistic &&
                statisticData &&
                statisticData?.questions.length === 0 && (
                  <span className={cn('empty-block__text')}>
                    Вопросов пока нет
                  </span>
                )}
            </div>
          </div>
        </CommonWrapper>
        <CommonWrapper>
          <div className="questions">
            <div className="account-border-line pb-3">
              <p className="account-text account-text-l bold-text mb-1">
                Заявления
              </p>
              {/*<p className="grey-text account-text-m bold-text">*/}
              {/*  Обновлено в 20:01*/}
              {/*</p>*/}
            </div>
            <div className="questions-list list-items">
              {isLoadingStatistic && <Spinner />}

              {!isLoadingStatistic &&
                statisticData &&
                statisticData?.user_requests.length > 0 && (
                  <>
                    {statisticData?.user_requests.map((userRequests) => (
                      <Issue
                        key={userRequests.created_at}
                        title={`№ ${userRequests.id}`}
                        description={userRequests.created_at}
                        status={userRequests.status}
                      />
                    ))}
                  </>
                )}
              {!isLoadingStatistic &&
                statisticData &&
                statisticData?.user_requests.length === 0 && (
                  <span className={cn('empty-block__text')}>
                    Заявлений пока нет
                  </span>
                )}
            </div>
          </div>
        </CommonWrapper>
      </div>
    </div>
  );
};

export default AccountMain;
