import { apiSlice } from '@/services/apiSlice';
import { GetProductSearchResponseData } from '../types/useProductSearch';

export const productSearchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postProductSearch: builder.mutation<GetProductSearchResponseData, string>({
      query: (text) => ({
        url: '/products/search',
        method: 'POST',
        body: { text },
      }),
    }),
  }),
});

export const { usePostProductSearchMutation } = productSearchApiSlice;
