import { apiSlice } from '@/services/apiSlice';
import {
  GetPageResponseData,
  GetPageListResponseData,
  PageQueryParams,
} from '../types';

export const pageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPage: builder.query<GetPageResponseData, PageQueryParams>({
      query: (arg) => {
        const { alias } = arg;
        return {
          url: '/pages/show_by_alias',
          params: { alias },
        };
      },
    }),
    getPageList: builder.query<GetPageListResponseData, void>({
      query: () => '/pages',
    }),
  }),
});

export const { useGetPageQuery, useGetPageListQuery } = pageApiSlice;
