import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import Spinner from '@/components/Spinner/Spinner';

import { useGlobalContext } from '@/contexts/GlobalContext';

import { Product3DModelManagementProps } from '@/types/managenent3d';

import BackgroundAdd3d from '@/assets/background/background-add-3d.png';

const Product3DModelManagement: React.FC<Product3DModelManagementProps> = ({
  setProductModalFile,
  productModelRef,
  showContextMenu3D,
  setShowContextMenu3D,
  type,
  alias_3d,
}) => {
  const [
    { loadModels, modelsLoaded, viewer3d, loader3d },
    { setIsCreateCanvas },
  ] = useGlobalContext();

  const productViewer3dRef = useRef<HTMLDivElement>(null);
  const viewerWrapper = useRef<HTMLDivElement>(null);

  const [isModelLoading, setModelLoading] = useState<boolean>(false);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);

  useEffect(() => {
    if (viewer3d) viewer3d.clear();
    return () => {
      if (viewer3d) {
        viewer3d.clear();
        setIsCreateCanvas(false);
        console.log('setIsCreateCanvas');
      }
    };
  }, [viewer3d]);

  useEffect(() => {
    const initializeViewer = () => {
      if (viewerWrapper.current && viewer3d && loader3d && modelsLoaded) {
        viewerWrapper.current.appendChild(viewer3d.getDomElement());
        if (productViewer3dRef.current) {
          viewer3d.update(
            productViewer3dRef.current.getBoundingClientRect().width,
            500,
          );
          if (alias_3d) {
            viewer3d.show(alias_3d);
            setShowCanvas(true);
          }
          viewer3d.render();
          setIsCreateCanvas(true);
        }
      }
    };

    const timeoutId = setTimeout(() => {
      initializeViewer();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [viewerWrapper.current, viewer3d, loader3d, alias_3d, modelsLoaded]);

  // useEffect(() => {
  //   if (
  //     viewer3d &&
  //     loader3d &&
  //     alias_3d &&
  //     loadModels &&
  //     productViewer3dRef.current
  //   ) {
  //     viewer3d.clear();
  //     viewer3d.show(alias_3d);
  //     viewer3d.render();
  //     setShowCanvas(true);
  //     setIsCreateCanvas(true);
  //   }
  // }, [viewer3d, alias_3d, loader3d, loadModels]);

  const handleLoadModel = () => {
    setTimeout(() => {
      setModelLoading(false);
    }, 3000);
  };

  const handleFileSelect = (file: File) => {
    const allowedExtensions = /(\.glb)$/i;

    const reader = new FileReader();

    if (file.size / 1024 <= 2048 && allowedExtensions.exec(file.name)) {
      reader.onload = function (e) {
        if (viewer3d) viewer3d.clear();
        if (e.target) {
          viewer3d.showFromBuffer(e.target.result);
        }
      };

      if (file) {
        reader.readAsArrayBuffer(file);
        setProductModalFile(file);
      }
      setShowCanvas(true);
    } else {
      alert('Error - maximum upload file size for avatar 2 МБ');
      setShowCanvas(false);
      return;
    }
    setModelLoading(true);
    handleLoadModel();
  };

  const handleOpenFileSelect = () => {
    productModelRef && productModelRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      handleFileSelect(event.target.files[0]);
      // if (viewer3d) viewer3d.clear();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFileSelect(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div
      className={cn('product-3d-model-management mt-3', {
        'file-selected': showCanvas,
      })}
      ref={productViewer3dRef}
    >
      <div ref={viewerWrapper} className={cn('viewer-wrapper')}></div>
      {type === 'edit' && (
        <span
          className="menu-app__item change-3d-model"
          onClick={handleOpenFileSelect}
        >
          Изменить модель
        </span>
      )}
      <img
        src={BackgroundAdd3d}
        alt="Background Add 3d"
        className={cn('background-add-3d')}
      />
      <div
        className="product-3d-model-management__add-3d-model"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => setShowContextMenu3D(!showContextMenu3D)}
      >
        {type === 'edit' && (
          <div
            className={cn('product-3d-model-management__menu-select menu-app', {
              'menu-show': showContextMenu3D,
            })}
          >
            <input
              ref={productModelRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <span className="menu-app__item" onClick={handleOpenFileSelect}>
              Загрузить модель
            </span>
            <span className="menu-app__item">Заказать модель</span>
          </div>
        )}
      </div>
      {isModelLoading && (
        <div className="product-3d-model-management__loading">
          <Spinner />
        </div>
      )}
      {type === 'edit' && !loadModels && (
        <div className="product-3d-model-management__loading">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Product3DModelManagement;
