import React from 'react';
import cn from 'classnames';

import { pluralizeReviews } from '../utils/textTransforms';

import { ProductFeedbackPanelProps } from '@/features/ProductCard/types';

// Icons
import Star from '@/assets/icons/star-outline.svg';
import Message from '@/assets/icons/product/message-outline.svg';
import Query from '@/assets/icons/product/query.svg';

const ProductFeedbackPanel: React.FC<ProductFeedbackPanelProps> = ({
  productFeedback,
  isRating,
  toggleFeedback,
}) => (
  <div className="product-feedback-panel">
    <div className={cn('product-feedback-panel__titles')}>
      <div
        onClick={() => toggleFeedback(true)}
        className={cn(
          'product-feedback-panel__title',
          `${isRating ? '--active' : ''}`,
        )}
      >
        Отзывы
        <span className={cn('product-feedback-panel__title-rating')}>
          <img src={Star} alt="Rating" />
          {productFeedback?.reviews_stars}
        </span>
      </div>
      <div
        onClick={() => toggleFeedback(false)}
        className={cn(
          'product-feedback-panel__title',
          `${!isRating ? '--active' : ''}`,
        )}
      >
        Вопросы
      </div>
    </div>
    <div className={cn('product-feedback-panel__contents')}>
      {isRating && (
        <div className={cn('product-feedback-panel__content')}>
          {!productFeedback?.reviews || productFeedback.reviews.length <= 0 ? (
            <div className={cn('product-feedback-panel__content--empty')}>
              <img src={Message} alt="Reviews" />
              <h3>Здесь скоро будут отзывы</h3>
            </div>
          ) : (
            <div className={cn('product-feedback-panel__content--items')}>
              <p
                className={cn('product-feedback-panel__content-reviews-length')}
              >
                {pluralizeReviews(productFeedback?.reviews.length || 0)}
              </p>
              {productFeedback?.reviews?.map((item) => (
                <div
                  key={item.name}
                  className={cn('product-feedback-panel__content--item')}
                >
                  <div
                    className={cn(
                      'product-feedback-panel__content--item--info-user',
                    )}
                  >
                    <img className={cn('user-avatar')} src="#" alt="User" />
                    <div
                      className={cn(
                        'product-feedback-panel__content--item--info',
                      )}
                    >
                      <p>Покупатель</p>
                      <p>10 января</p>
                    </div>
                    <p
                      className={cn(
                        'product-feedback-panel__content--item--rating',
                      )}
                    >
                      <img src={Star} alt="Rating" />
                      {item.stars}
                    </p>
                  </div>
                  <div
                    className={cn(
                      'product-feedback-panel__content--item--question-text',
                    )}
                  >
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {!isRating && (
        <div className={cn('product-feedback-panel__content')}>
          {!productFeedback?.questions ||
          productFeedback.questions.length <= 0 ? (
            <div className={cn('product-feedback-panel__content--empty')}>
              <img src={Query} alt="Reviews" />
              <h3>Здесь скоро будут вопросы</h3>
            </div>
          ) : (
            <div className={cn('product-feedback-panel__content--items')}>
              {productFeedback?.questions?.map((item) => (
                <div
                  key={item.name}
                  className={cn('product-feedback-panel__content--item')}
                >
                  <div
                    className={cn(
                      'product-feedback-panel__content--item--info-user',
                    )}
                  >
                    <img className={cn('user-avatar')} src="#" alt="User" />
                    <div
                      className={cn(
                        'product-feedback-panel__content--item--info',
                      )}
                    >
                      <p>Покупатель</p>
                      <p>10 января</p>
                    </div>
                  </div>
                  <div
                    className={cn(
                      'product-feedback-panel__content--item--question-text',
                    )}
                  >
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);

export default ProductFeedbackPanel;
