import React, { useEffect, useState } from 'react';

import VerificationForm from '../../forms/VerificationForm';
import { VerificationProps } from '../../types/verification';
import useVerification from '../../hooks/useVerification';

const Verification: React.FC<VerificationProps> = ({
  next,
  phone,
  verificationLink,
}) => {
  const [timeSend, setTimeSend] = useState<number>(60);

  useEffect(() => {
    let interval: number | undefined;

    if (timeSend > 0) {
      interval = window.setInterval(() => {
        setTimeSend((prevTimeSend) => prevTimeSend - 1);
      }, 1000);
    } else if (timeSend === 0) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timeSend]);

  const handleResendClick = (): void => {
    setTimeSend(60);
  };

  const [{ initialValues }, { onSubmit }] = useVerification({
    phone,
    verificationType: verificationLink,
    next,
  });
  return (
    <>
      <VerificationForm
        isLoading={false}
        className={'verification-form form-primary'}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />

      <p className="sub-text__2">
        На ваш телефон был отправлен уникальный код подтверждения. Введите этот
        код в поле выше
      </p>
      <button
        className="submit-btn btn-primary"
        type="submit"
        form="verification-form"
      >
        Подтвердить
      </button>

      <span className="send-code">
        Код не пришел?
        <span className="link">
          {timeSend > 0 ? (
            `0:${timeSend < 10 ? `0${timeSend}` : timeSend}`
          ) : (
            <span onClick={handleResendClick}>Отправить ещё раз</span>
          )}
        </span>
      </span>
    </>
  );
};

export default Verification;
