import { apiSlice } from '@/services/apiSlice';
import { GetStatisticResponse } from '../types/useStatistic';

export const statisticApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStatistic: builder.query<GetStatisticResponse, void>({
      query: () => `/products/statistic`,
    }),
  }),
});

export const { useGetStatisticQuery } = statisticApiSlice;
