import React, { useState } from 'react';
import cn from 'classnames';

import AccountMenu from '@/components/Account/AccountMenu/AccountMenu';

import { AccountMenuItemType } from '@/types/types';

import './account-sidebar.sass';

import Arrow from '@/assets/icons/account/menu-arrow.svg';
import Logo from '@/assets/icons/logo/logo.svg';
import Icon from '@/components/ui/Icon';

interface AccountSidebarProps {
  menuItems: AccountMenuItemType[];
  className?: string;
  dark?: boolean;
  light?: boolean;
  isHidden?: boolean;
}

const AccountSidebar: React.FC<AccountSidebarProps> = ({
  className = '',
  dark,
  light,
  menuItems,
  isHidden = false,
}) => {
  const [isHiddenMenu, setIsHiddenMenu] = useState(false);

  const classes = cn(className, 'account-sidebar', {
    dark,
    light,
    'menu-hidden': isHiddenMenu,
  });

  const toggleShowMenu = (): void => {
    setIsHiddenMenu(!isHiddenMenu);
  };

  return (
    <div className={classes}>
      {isHidden && (
        <div
          className={cn('account-sidebar__btn-show-menu')}
          onClick={() => toggleShowMenu()}
        >
          <span className={cn('btn-show__burger')}>
            <span className={cn('btn-show__burger-line line-1')}></span>
            <span className={cn('btn-show__burger-line line-2')}></span>
            <span className={cn('btn-show__burger-line line-3')}></span>
          </span>
          Свернуть меню
        </div>
      )}

      <div className="account__inner">
        <a href="/" className={cn('logo')}>
          <Icon src={Logo} alt="UNEMAG - инновационный маркетплейс" />
        </a>
        <AccountMenu menuItems={menuItems} arrow={Arrow} />
      </div>
    </div>
  );
};

export default AccountSidebar;
