import { apiSlice } from '@/services/apiSlice';
import {
  DocumentDeletePayload,
  DocumentDeleteResponse,
  GetProfileResponseData,
  UpdateProfilePayload,
  UpdateProfileResponseData,
} from '../types';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfille: builder.query<GetProfileResponseData, void>({
      query: () => {
        return {
          url: '/profiles/details',
        };
      },
    }),
    onUpdateProfile: builder.mutation<
      UpdateProfileResponseData,
      UpdateProfilePayload
    >({
      query: ({ id, data }) => {
        return {
          url: `/profiles/${id}`,
          method: 'PUT',
          body: data,
          formData: true,
        };
      },
    }),
    onDeleteDocument: builder.mutation<
      DocumentDeleteResponse,
      DocumentDeletePayload
    >({
      query: ({ profile_id, document_id }) => ({
        url: `/profiles/${profile_id}/destroy_document/${document_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetProfilleQuery,
  useOnUpdateProfileMutation,
  useOnDeleteDocumentMutation,
} = authApiSlice;
