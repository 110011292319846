import React from 'react';

import './cart-sidebar.sass';
import CreateOrderForm from '../forms/createOrderForm';
import useCreateOrder from '../../hooks/useCreateOrder';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

const CartSidebar: React.FC = () => {
  const [
    { isLoading },
    { initialValues, deliveryOptions, paymentOptions },
    { onSubmit },
  ] = useCreateOrder();

  const globalCartData = useSelector((state: RootState) => state.cart);

  return globalCartData.count_total > 0 ? (
    <CreateOrderForm
      isLoading={isLoading}
      paymentOptions={paymentOptions}
      deliveryOptions={deliveryOptions}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  ) : (
    <div className="cart-sidebar">
      <div className="cart-sidebar__data">
        <div className="cart-sidebar__backdrop-filter backdrop-filter"></div>
        <div className="cart-sidebar__data-body">
          <div className="cart-sidebar__data-body__no-items-warning">
            <div className="cart-sidebar__data-body__no-items-warning-text">
              В корзине нет товаров{' '}
            </div>
            <p>Добавьте товары, чтобы оформить заказ</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSidebar;
