import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { CatalogueCategoryItem } from '@/features/CatalogueModal/components/CatalogueCategoryItem';
import CatalogueSubCategoriesList from './CatalogueSubCategoriesList';
import { CategoriesList } from '../types';

type CatalogueCategoriesListProps = CategoriesList;

const CatalogueCategoriesList: React.FC<CatalogueCategoriesListProps> = ({
  categories,
  parentCategory,
  onClick,
  selectedCategory,
  onEnterCategory,
  onLeaveCategory,
}) => {
  return (
    <div className="catalogue-categories-list">
      <h3 className="catalogue-categories-list__heading">
        {parentCategory?.name}
      </h3>
      <Scrollbars>
        <div className="catalogue-categories-list__items">
          {categories?.map((category) => (
            <div key={category.id}>
              <CatalogueCategoryItem
                isSelected={selectedCategory?.id === category.id}
                key={`${category.name}-${category.id}`}
                title={category.name}
                onClick={onClick(category, 'SECOND_CATEGORY')}
                onMouseEnter={onEnterCategory(category, 'SECOND_CATEGORY')}
                onMouseLeave={onLeaveCategory}
              />
              <CatalogueSubCategoriesList
                parentCategory={category}
                selectedCategory={selectedCategory}
                categories={category.subcats}
                onClick={onClick}
              />
            </div>
          ))}
        </div>
      </Scrollbars>
    </div>
  );
};

export default CatalogueCategoriesList;
