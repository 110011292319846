import React from 'react';
import cn from 'classnames';

import { MessageInfoPopupProps } from '@/components/MessageInfoPopup/types';

import InfoIcon from '@/assets/icons/information.svg';

import './message-info-popup.sass';

const MessageInfoPopup: React.FC<MessageInfoPopupProps> = ({ message }) => {
  return (
    <div className={cn('message-info-popup')}>
      <img src={InfoIcon} alt="Message info popup" />
      <div className={cn('message-info__container')}>
        {message.title && <h2>{message.title}</h2>}
        <p className={cn('message-info-popup__text')}>{message.text}</p>
        <span className="triangle"></span>
      </div>
    </div>
  );
};

export default MessageInfoPopup;
