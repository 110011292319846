import React from 'react';
import cn from 'classnames';

import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';
import FormSelectField from '@/components/Forms/select-field';
import Button from '@/components/ui/Button/Button';

import { ClusterProductFormProps } from '@/features/AccountRetailer/types/useClustersProductsManagement';

import PhotoUploadForm from '@/features/AccountRetailer/components/PhotoUploadForm';

const ProductForm: React.FC<ClusterProductFormProps> = ({
  showNameActionComponent,
  onSubmitLoading,
  isLoadingUpdate,
  initialValues,
  productAttributes,
  onSubmit,
  updateImages,
  images,
  setImages,
}) => {
  const isDisabled = isLoadingUpdate;

  return (
    <FinalForm
      id="cluster-form"
      className={'form-secondary'}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <div className="account__inner mt-3">
        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="product[article]"
            className="input"
            placeholder="Артикул"
            bottomMessageInfo="Обязательно к заполнению"
          />
        </div>
        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="product[name]"
            className="input"
            placeholder="Введите название товара"
            bottomMessageInfo="Обязательно к заполнению"
          />
        </div>
        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="product[description]"
            className="input"
            placeholder="Введите описание"
          />
        </div>
        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            showErrorInline={false}
            name="product[count_products]"
            className="input"
            placeholder="Введите количество"
          />
        </div>
        <div>
          <div className={cn('form__input-wrapper input-row-2')}>
            <FormTextField
              disabled={isDisabled}
              required
              showErrorInline={false}
              name="product[price]"
              className="input"
              placeholder="Цена"
            />
            <FormTextField
              disabled={isDisabled}
              showErrorInline={false}
              name="product[price_old]"
              className="input"
              placeholder="Новая цена"
              bottomMessageInfo="Пропустить если нет скидки"
            />
          </div>
        </div>

        <div className={cn('form__input-wrapper --up')}>
          <FormTextField
            disabled={isDisabled}
            showErrorInline={false}
            name="product[discount]"
            className="input"
            placeholder="Процент скидки с учётом новой цены:"
          />
        </div>
        <div className={cn('form__input-wrapper --column')}>
          <span className={cn('form__input-wrapper-title')}>
            Характеристики
          </span>
          <div className={cn('form__select-wrapper input-row-2')}>
            <FormSelectField
              disabled={isDisabled}
              required
              showErrorInline={false}
              isSearchable
              name="product[group_product_colors_attributes]"
              placeholder="Цвет"
              fieldProps={{ formatOnBlur: true }}
              options={productAttributes.colors}
            />
            <FormSelectField
              disabled={isDisabled}
              showErrorInline={false}
              isSearchable
              name="product[group_product_sizes_attributes]"
              placeholder="Размер"
              fieldProps={{ formatOnBlur: true }}
              options={productAttributes.sizes}
            />
          </div>
        </div>

        <div className={cn('form__input-wrapper --column')}>
          <PhotoUploadForm
            updateImages={updateImages}
            images={images}
            setImages={setImages}
            showNameActionComponent={showNameActionComponent}
          />
        </div>

        <div className={cn('form__input-wrapper --column')}>
          <span className={cn('form__input-wrapper-title')}>Видео</span>
          <FormTextField
            disabled={isDisabled}
            showErrorInline={false}
            name="product[video]"
            className="input"
            placeholder="Вставьте ссылку на видео"
          />
        </div>

        <Button
          primary
          size="m"
          className=""
          type="submit"
          form="cluster-form"
          disabled={onSubmitLoading}
          load={onSubmitLoading}
        >
          Сохранить
        </Button>
      </div>
    </FinalForm>
  );
};

export default ProductForm;
