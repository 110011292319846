import { Option } from '@/types/common';
import { includes } from 'lodash-es';
import { BaseSyntheticEvent } from 'react';
import Button from '../Button/Button';
import { useStyles } from './styles';

type OptionButtonProps = {
  options: Option[];
  active: Option;
  hasDisabled?: string[];
  onChange: (current: Option) => void;
};

const OptionButton = ({
  options,
  active,
  onChange,
  hasDisabled,
}: OptionButtonProps) => {
  const classes = useStyles();
  const onClick = (item: Option) => (event: BaseSyntheticEvent) => {
    event.preventDefault();
    onChange(item);
  };
  return (
    <div style={classes.root}>
      {options.map((item) => (
        <Button
          key={item.value}
          style={
            active.value === item.value
              ? { ...classes.button, ...classes.active }
              : classes.button
          }
          size={'m'}
          onClick={onClick(item)}
          disabled={includes(hasDisabled, item.value)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};
export default OptionButton;
