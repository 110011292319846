import { apiSlice } from '@/services/apiSlice';
import { GetPavilionsResponseData } from '@/features/Global/types/usePavilions';
import { GetProductsModelsResponseData } from '@/types/GlobalContext';

export const pavilionUsersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPavilions: builder.query<GetPavilionsResponseData, void>({
      query: () => `/pavilions/index_by_3d`,
    }),
    getProductsModels: builder.query<GetProductsModelsResponseData, void>({
      query: () => `/product_models`,
    }),
  }),
});

export const { useGetPavilionsQuery, useGetProductsModelsQuery } =
  pavilionUsersApiSlice;
