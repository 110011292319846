import React from 'react';

const AccountRatings: React.FC = () => {
  return (
    <div className="container account-container">
      <div className="account-content">AccountRatings</div>
    </div>
  );
};

export default AccountRatings;
