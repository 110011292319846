import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '@/store/store';
import cn from 'classnames';

import DimensionToggle from '@/utils/dimensionToggle';
import { useIs3D } from '@/utils/Is3DContext';

import { useAppDispatch } from '@/hooks';
import useAuthenticate from '@/hooks/useAuthenticate';

import ModeSwitchButton3D from '@/features/ModeSwitchButton3D';
import { setCurrentAuthModal } from '@/features/Auth/states/authSlice';
import { AuthFormModalType } from '@/features/Auth/types';

import CountItems from '@/components/CountItems/CountItems';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu';

import './bottom-navigate-bar.sass';

import heartIcon from '@/assets/icons/product/favorite.svg';
import cartIcon from '@/assets/icons/header/cart.svg';
import userIcon from '@/assets/icons/header/user.svg';

const BottomNavigateBar: React.FC = () => {
  const { setIs3D } = useIs3D();
  const [{ isAuthenticated }, { getAuthUser }] = useAuthenticate();

  const user = getAuthUser();
  const dispatch = useAppDispatch();

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { count_total: cartCountTotal } = useSelector(
    (state: RootState) => state.cart,
  );

  const { count_total: favoriteCount } = useSelector(
    (state: RootState) => state.favorite,
  );

  const onOpenLoginModal = () => {
    dispatch(setCurrentAuthModal(AuthFormModalType.LOGIN));
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className={cn('bottom-navigate-bar')}>
      <span className={cn('backdrop-filter')}></span>

      <Link to="/favorites" className={'bottom-navigate-bar__item'}>
        <div className={cn('icon-wrapper')}>
          <img src={heartIcon} alt="Favorites" />
          {favoriteCount > 0 && <CountItems number={favoriteCount} />}
        </div>
        <span className={'bottom-navigate-bar__item item-text'}>Избранное</span>
      </Link>
      <Link to="/cart" className={'bottom-navigate-bar__item'}>
        <div className={cn('icon-wrapper')}>
          <img src={cartIcon} alt="Cart" />
          {cartCountTotal > 0 && <CountItems number={cartCountTotal} />}
        </div>
        <span className={'bottom-navigate-bar__item item-text'}>Корзина</span>
      </Link>

      {isAuthenticated ? (
        <span
          onClick={toggleMenu}
          className={cn('bottom-navigate-bar__item item-account__auth')}
        >
          {user?.full_name[0]}
          <ProfileMenu showMenu={showMenu} isMobile />
        </span>
      ) : (
        <div
          className={cn('bottom-navigate-bar__item')}
          onClick={() => {
            onOpenLoginModal();
            setIs3D(false);
            DimensionToggle({ is3D: true });
          }}
        >
          <div className={cn('icon-wrapper')}>
            <img src={userIcon} alt="Пользователь" />
          </div>
          <span className={'bottom-navigate-bar__item item-text'}>Аккаунт</span>
        </div>
      )}
      <ModeSwitchButton3D variant="mobile" />
    </div>
  );
};

export default BottomNavigateBar;
