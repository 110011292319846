import { get } from 'lodash-es';
import { useOnVerifyMutation } from '../services/authApi';
import {
  UseVerification,
  UseVerificationProps,
} from '../types/useVerification';

import { VerificationFormData } from '../types/verification';

const useVerification = ({
  phone,
  verificationType,
  next,
}: UseVerificationProps): UseVerification => {
  const [onVerify] = useOnVerifyMutation();

  const initialValues = {
    verification_code: '',
    phone: phone,
  };

  const onSubmit = async (data: VerificationFormData) => {
    try {
      const verifyResponse = await onVerify({
        url: verificationType,
        body: {
          user: {
            phone: data.phone,
            verification_code: data.verification_code,
            step: 'verify_code',
          },
        },
      }).unwrap();
      if (verifyResponse) next();
    } catch (error) {
      return {
        verification_code:
          get(error, 'data.message') ||
          get(error, 'data.errors.verification_code'),
      };
    }
  };

  return [
    {
      initialValues,
    },
    {
      onSubmit,
    },
  ];
};
export default useVerification;
