import React from 'react';
import cn from 'classnames';
import Button from '@/components/ui/Button/Button';

import { ListItemsAddProps } from '@/types/types';

import './list-items-add.sass';

import Search from '@/assets/icons/search.svg';

const ListItemsAdd: React.FC<ListItemsAddProps> = ({
  typeManagement,
  title,
  searchPlaceholder,
  objectList,
  selectItemId,
  selectPlace,
  setSelectItemId,
  setObjectList,
  listType,
  addItem,
}) => {
  return (
    <>
      <span className={cn('overlay')}></span>

      <div className={cn('list-items-add')}>
        <h2>{title}</h2>
        <div className={cn('list-items-add__filter-container')}>
          <div className="list-items-add__search-container">
            <div className="input-wrapper">
              <img src={Search} alt="Search" />
              <input type="text" placeholder={searchPlaceholder} />
            </div>
            <Button size="m" primary>
              Поиск
            </Button>
          </div>
        </div>

        <div className={cn('list-items-add__items-container', listType)}>
          {objectList && objectList.length > 0 ? (
            objectList.map(
              (item) =>
                item.alias &&
                item.product_id && (
                  <div
                    key={`${item.name}-${item.id}`}
                    className={cn('item', {
                      'item-select': item.id === selectItemId,
                    })}
                    onClick={() => {
                      setSelectItemId(item.id);
                    }}
                  >
                    <img src={item.image} alt={item.name} />
                    <h2 className="item-name">{item.name}</h2>
                    {item.characteristic && (
                      <div className={cn('item-name__characteristics')}>
                        <p>{item.characteristic}</p>
                        <p>{item.size}</p>
                      </div>
                    )}
                    {item.price && (
                      <div className={cn('item-price')}>
                        <span className={cn('item-price__title')}>Цена</span>
                        <span className={cn('item-price__title')}>
                          {item.price} ₽
                        </span>
                      </div>
                    )}
                  </div>
                ),
            )
          ) : (
            <span>Элементов не найдено</span>
          )}
        </div>

        <div className="list-items-add__tools-btns">
          <Button onClick={() => setObjectList?.(null)} size="m" secondary>
            Закрыть
          </Button>
          <Button
            onClick={() => {
              addItem(typeManagement, Number(selectPlace));
            }}
            size="m"
            primary
          >
            Добавить
          </Button>
        </div>
      </div>
    </>
  );
};

export default ListItemsAdd;
