import { VerificationError } from './verification';

export enum PASSWORD_RECOVERY_STEPS {
  SEND_PHONE = 'send_phone',
  VERIFY_CODE = 'verify_code',
  CHANGE_PASSWORD = 'change_password',
  SUCCEESS = 'success',
}

export type PasswordRecoveryFormData = {
  password: string;
  password_confirmation: string;
  phone: string;
  step: PASSWORD_RECOVERY_STEPS;
  verification_code: string;
};

export type PasswordRecoveryFormProps = {
  isLoading: boolean;
  className: string;
  initialValues: PasswordRecoveryFormData;
  onSubmit: (data: PasswordRecoveryFormData) => Promise<any>;
};

export type PasswordRecoveryPayload = { user: PasswordRecoveryFormData };

export type PasswordRecoveryResponseData =
  | { success: boolean }
  | VerificationError;
