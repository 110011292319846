import type { CheckBoxProps } from '@/types/forms';
import { createValidationFunction } from '@/utils/validations';
import { useMemo } from 'react';
import { Field as FinalFormField } from 'react-final-form';

const FormCheckBoxField = <T,>({
  showErrorInline = true,
  required = false,
  name,
  fieldProps,
  className,
  endText,
  validate,
  ...props
}: CheckBoxProps<T>) => {
  const validationFunction = useMemo(
    () => createValidationFunction(required, validate),
    [required, validate],
  );
  return (
    <FinalFormField
      {...fieldProps}
      name={name}
      validate={validationFunction}
      render={({ input, meta }) => {
        return (
          <div
            className={`input-wrapper ${className} ${(meta.error || meta.submitError) && meta.touched ? 'input-wrapper__error' : ''}`}
          >
            <label className="custom-checkbox">
              <input type="checkbox" {...input} {...props} />
              <span className="checkmark"></span>
              {showErrorInline &&
                (meta.error || meta.submitError) &&
                meta.touched && (
                  <div className="error-message__form">
                    {meta.error || meta.submitError}
                  </div>
                )}
              {endText}
            </label>
          </div>
        );
      }}
    />
  );
};
export default FormCheckBoxField;
