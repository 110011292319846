import { OrganizationCardFormProps } from '../types';
import FinalForm from '@/components/Forms/form';
import FormTextField from '@/components/Forms/text-field';
import FormSelectField from '@/components/Forms/select-field';

const OrganizationCardForm: React.FC<OrganizationCardFormProps> = ({
  isLoadingUpdate,
  initialValues,
  counterpartyOptions,
  organizationOptions,
  onSubmit,
}) => {
  const isDisabled = isLoadingUpdate;
  return (
    <FinalForm
      id="profile-form"
      className={'form-secondary'}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <div className="account__inner mt-3">
        <div className="col-6">
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="inn"
            className="input"
            placeholder="ИНН"
          />
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="legal_company_name"
            className="input"
            placeholder="Юр. название компании"
          />
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="name_user_will_see"
            className="input"
            placeholder="Название которое увидят пользователи"
          />

          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="okved"
            className="input"
            placeholder="ОКВЕД"
          />

          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="date_of_registration"
            fieldProps={{ type: 'date' }}
            className="input"
            placeholder="Дата регистрации"
          />

          <FormSelectField
            disabled={isDisabled}
            required
            showErrorInline={false}
            isSearchable
            name="type_of_organization"
            placeholder="Тип организации"
            options={organizationOptions}
            fieldProps={{ formatOnBlur: true }}
          />
        </div>

        <div className="col-6">
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="lkp"
            className="input"
            placeholder="ЛКП"
          />
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="account_number"
            className="input"
            placeholder="Номер счёта"
          />
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="bik"
            className="input"
            placeholder="БИК"
          />
          <FormTextField
            disabled={isDisabled}
            required
            showErrorInline={false}
            name="name_of_bank"
            className="input"
            placeholder="Наименование банка"
          />

          <FormSelectField
            disabled={isDisabled}
            required
            showErrorInline={false}
            isSearchable
            name="type_of_counterparty"
            placeholder="Вид контрагента"
            options={counterpartyOptions}
            fieldProps={{ formatOnBlur: true }}
          />
        </div>
      </div>
    </FinalForm>
  );
};
export default OrganizationCardForm;
