import useAuth from './hooks/useAuth';

import Login from './components/Login/Login';
import Registration from './components/Registration/Registration';
import PasswordRecovery from './components/PasswordRecovery/PasswordRecovery';

import { AuthFormModalType } from './types';
import cn from 'classnames';

const Auth = () => {
  const [
    { currentAuthFormType },
    {
      onOpenRegistrationModal,
      onOpenPasswordRecoveryModal,
      onCloseModal,
      onOpenLoginModal,
    },
  ] = useAuth();

  return (
    <>
      {currentAuthFormType === AuthFormModalType.LOGIN && (
        <div className="auth">
          <Login
            onClickRegistration={onOpenRegistrationModal}
            onClickPasswordRecovery={onOpenPasswordRecoveryModal}
            onCloseModal={onCloseModal}
          />
        </div>
      )}
      {currentAuthFormType === AuthFormModalType.REGISTRATION && (
        <div className={cn('auth', 'registration')}>
          <Registration
            onClickLogin={onOpenLoginModal}
            onCloseModal={onCloseModal}
          />
        </div>
      )}
      {currentAuthFormType === AuthFormModalType.PASSWORD_RECOVERY && (
        <div className={cn('auth', 'password-recovery')}>
          <PasswordRecovery
            onClickLogin={onOpenLoginModal}
            onCloseModal={onCloseModal}
          />
        </div>
      )}
    </>
  );
};
export default Auth;
