import React, { ReactNode } from 'react';

import './header-filter.sass';

type HeaderFilterProps = {
  children: ReactNode;
};

const HeaderFilter: React.FC<HeaderFilterProps> = ({ children }) => {
  return <div className="row">{children}</div>;
};

export default HeaderFilter;
