export interface OrderDetails {
  order_id: number;
  message: string;
  status: string;
}

export interface OrderState {
  data: OrderDetails | null;
  isLoading: boolean;
  error: string | null;
}

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export interface CreateOrderRequestAction {
  type: typeof CREATE_ORDER_REQUEST;
}

export interface CreateOrderSuccessAction {
  type: typeof CREATE_ORDER_SUCCESS;
  payload: OrderDetails;
}

export interface CreateOrderFailureAction {
  type: typeof CREATE_ORDER_FAILURE;
  payload: string;
}

export type OrderActionTypes =
  | CreateOrderRequestAction
  | CreateOrderSuccessAction
  | CreateOrderFailureAction;
