import React from 'react';

import { accountMenuItems } from '@/data/constants';
import AccountSidebar from '@/components/Account/AccountSidebar/AccountSidebar';
import OrganizationCard from '@/features/Profile';

const AccountDocuments: React.FC = () => {
  return (
    <div className="container account-container">
      <AccountSidebar menuItems={accountMenuItems} />

      <div className="account-content">
        <OrganizationCard onClose={() => {}} />
      </div>
    </div>
  );
};

export default AccountDocuments;
